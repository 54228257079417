import { Button, Form, Input, Select } from "antd";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "../../../graphql/mutation/Usuarios";

export const CreateUsuario = () => {
  const { profiles, messageApi, setDrawerContent, setUsers } =
    useContext(GlobalContext);
  const [form] = Form.useForm();

  const [showPassword, setShowPassword] = useState(false);

  const [newUserResolver, { data, loading, error }] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      if (data?.newUserResolver?._id) {
        messageApi.success("Usuario agregado correctamente");

        setUsers((prevState) => {
          return [...prevState, data.newUserResolver];
        });

        setDrawerContent({ visible: false, type: "", item: {} });
      } else {
        messageApi.error("Ocurrió un error al agregar un usuario");
      }
    },
    onError: (error) => {
      messageApi.error("Ocurrió un error al agregar un usuario");
    },
  });

  const onFinish = (v) => {
    v.name = v.name?.trim() || "";
    v.username = v.username?.trim() || "";
    v.password = v.password?.trim() || "";
    v.email = v.email?.trim() || "";

    if (v.name.length < 3)
      return messageApi.info("El nombre debe incluir al menos 3 caracteres");
    if (v.password.length < 8)
      return messageApi.info(
        "La contraseña debe incluir al menos 8 caracteres"
      );
    if (v.username.length < 4)
      return messageApi.info(
        "En nombre de usuario debe incluir al menos 4 caracteres"
      );

    if (!v.profile) {
      return messageApi.info("Es necesario que seleccione un rol");
    }

    let obj = {
      name: v.name,
      username: v.username,
      password: v.password,
      email: v.email,
    };

    newUserResolver({ variables: { userInput: obj, permissionId: v.profile } });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="createUser"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item
          name="name"
          label="Nombre"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Mail">
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          label="Nombre de usuario"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Contraseña"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input.Password visibilityToggle />
        </Form.Item>
        <Form.Item
          name="profile"
          label="Rol"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Select
            options={profiles?.map((profile) => {
              return { value: profile._id, label: profile.name };
            })}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Seleccione un rol"
          />
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() =>
              setDrawerContent({ visible: false, item: {}, type: "" })
            }
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
