import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 12,
  },
  titleContainer: {
    flexDirection: "column",
    gap: 4,
  },
  reportTitle: {
    color: "#252525",
    // letterSpacing: 4,
    fontSize: 25,
  },
  subtitle: {
    fontSize: 12,
  },
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 12,
    gap: 4,
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontWeight: 700,
  },
});

export const Title = ({ data, showSubtitle = true, showTodayDate = false }) => {
  const getDate = () => {
    if (showTodayDate) return dayjs().format("DD/MM/YYYY")  
    if (!data?.date) return "";

    let fecha = dayjs(data.date, "x")?.format("DD/MM/YYYY");
    return fecha;
  };

  return (
    <View style={styles.headerWrapper}>
      <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>CANADEZ SRL</Text>
        {showSubtitle && (
          <Text style={styles.subtitle}>
            Blvd. Marcelo T. de Alvear 1260, Villa María.
          </Text>
        )}
      </View>
      <View style={styles.invoiceNoContainer}>
        <Text>Fecha:</Text>
        <Text style={styles.invoiceDate}>{getDate()}</Text>
      </View>
    </View>
  );
};
