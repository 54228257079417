export const isAvailable = (seccion, item, permisos) => {
  let flag = true;

  if (!permisos) {
    return false;
  }

  if (permisos[seccion]) {
    if (!permisos[seccion].includes(item)) {
      flag = false;
    }
  } else {
    flag = false;
  }

  return flag;
};
