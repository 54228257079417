import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { BlobLoader } from "../ui/loader/BlobLoader";
import { handleUser } from "../../utils/handleUser";

export const StudentRouter = ({ children, seccion }) => {
  const { user, setUser } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);

  const checkIsStudent = () => {
    if (user?.type?.toLowerCase() !== "student") {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (user?._id) {
      setIsLoading(false);
    } else {
      setUser(handleUser());
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) return <BlobLoader />;

  if (!user?._id || user === null) {
    return <Navigate to="/login" />;
  }

  if (!checkIsStudent()) {
    return <Navigate to="/" />;
  }

  return children;
};
