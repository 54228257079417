export const addTurn = (schedule) => {
  let array = [...schedule];

  array.push({
    name: "",
    schedule: [
      {
        day: "",
        startTime: "",
        endTime: "",
      },
    ],
  });

  return array;
};

export const addTurnDay = (schedule, turnIndex) => {
  let cronograma = [...schedule];

  let turno = { ...cronograma[turnIndex] };

  let horarios = [...turno.schedule];

  horarios.push({
    day: "",
    startTime: "",
    endTime: "",
  });

  turno.schedule = horarios;

  cronograma[turnIndex] = turno;

  return cronograma;
};

export const handleTurnName = (value, schedule, turnIndex) => {
  let cronograma = [...schedule];

  let turno = { ...cronograma[turnIndex] };
  turno.name = value;
  cronograma[turnIndex] = turno;

  return cronograma;
};

export const handleTurnClassroom = (value, schedule, turnIndex) => {
  let cronograma = [...schedule];

  let turno = {...cronograma[turnIndex]};
  turno.classroomId = value;

  cronograma[turnIndex] = turno;

  return cronograma
}

export const handleTurnContent = (
  value,
  schedule,
  type,
  turnIndex,
  dayIndex
) => {
  let cronograma = [...schedule];

  let turno = { ...cronograma[turnIndex] };

  let horarios = [...turno.schedule];
  let dia = { ...horarios[dayIndex] };

  dia[type] = value;
  horarios[dayIndex] = dia;
  turno.schedule = horarios;
  cronograma[turnIndex] = turno;

  return cronograma;
};

export const deleteTurn = (schedule, turnIndex) => {
  let array = [...schedule];

  array.splice(turnIndex, 1);

  return array;
};

export const deleteTurnDay = (schedule, turnIndex, dayIndex) => {
  let cronograma = [...schedule];

  let turno = { ...cronograma[turnIndex] };

  let horarios = [...turno.schedule];

  horarios.splice(dayIndex, 1);

  turno.schedule = horarios;

  cronograma[turnIndex] = turno;

  return cronograma;
};