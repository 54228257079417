export const mapConfig = (obj) => {
  try {
    obj = { ...obj };

    let data = {};

    let keys = Object.keys(obj);

    keys.forEach((element) => {
      if (element !== "__typename") {
        if (element === "locations") {
          data[element] = orderGeneric(obj[element], "nombre");
        } else {
          data[element] = orderGeneric(obj[element], "name");
        }
      }
    });

    return data;
  } catch (error) {
    return obj;
  }
};

export const orderGeneric = (data, name) => {
  let array = [...data];

  array = array.sort((a, b) => {
    const nameA = a[name]?.toLowerCase() || "";
    const nameB = b[name]?.toLowerCase() || "";

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return array;
};
