import { useMutation, useQuery } from "@apollo/client";
import { Button, Radio, Table } from "antd";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { DELETE_STUDENT } from "../../../graphql/mutation/Alumnos";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import "./alumnos.css";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { GET_STUDENTS_FEES } from "../../../graphql/query/Alumnos";
import { columns } from "./utils";

export const Alumnos = () => {
  const {
    setDrawerContent,
    alumnos,
    setAlumnos,
    messageApi,
    loadingGlobal,
    cursos,
    cursosPoll,
    user,
    config,
  } = useContext(GlobalContext);

  const [estado, setEstado] = useState("ACTIVE");

  const {
    data: studentsFees,
    loading,
    error,
  } = useQuery(GET_STUDENTS_FEES, {
    fetchPolicy: "network-only",
  });

  const [
    deleteStudentResolver,
    { loading: loadingDelete, error: errorDelete, data: dataDelete },
  ] = useMutation(DELETE_STUDENT);

  const handleAddAlumno = () => {
    setDrawerContent({
      visible: true,
      type: "Agregar Alumno",
      item: {},
    });
  };

  const getAlumnosByStatus = () => {
    if (alumnos?.length > 0 && estado) {
      return alumnos.filter((element) => element.status === estado);
    }

    return [];
  };

  return (
    <div className="modulo-wrapper" id="lista-alumnos">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Alumnos</p>
        <Radio.Group
          onChange={(v) => setEstado(v.target.value)}
          buttonStyle="solid"
          value={estado}
          className="span-mid-width"
        >
          <Radio.Button value="ACTIVE">ACTIVOS</Radio.Button>
          <Radio.Button value="INACTIVE">INACTIVOS</Radio.Button>
        </Radio.Group>
        {isAvailable(
          "students",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <Button
            style={{ backgroundColor: "var(--complementary-color)" }}
            onClick={() => handleAddAlumno()}
          >
            Nuevo alumno
          </Button>
        )}
      </div>
      <div className="modulo-content">
        {loadingGlobal.alumnos ? (
          <BlobLoader />
        ) : (
          <Table
            className="table-wrapper"
            columns={columns(
              setDrawerContent,
              deleteStudentResolver,
              setAlumnos,
              cursosPoll,
              studentsFees
            )}
            dataSource={getAlumnosByStatus()}
            rowKey={"_id"}
            size="small"
            pagination={{
              pageSize: 10,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} alumnos`,
              showSizeChanger: false,
            }}
          />
        )}
      </div>
    </div>
  );
};
