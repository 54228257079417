import { Button, Drawer, Popover, Tooltip } from "antd";
import "./mainView.css";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FiLogOut, FiSettings, FiUsers } from "react-icons/fi";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { GoHome, GoMortarBoard } from "react-icons/go";
import { BiBook, BiDollar } from "react-icons/bi";
import { TfiStatsUp } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { CreateAlumno } from "../../ui/forms/CreateAlumno";
import { CreateInstructor } from "../../ui/forms/CreateInstructor";
import { CreateCurso } from "../../ui/forms/CreateCurso";
import { UpdateAlumno } from "../../ui/forms/UpdateAlumno";
import { UpdateInstructor } from "../../ui/forms/UpdateInstructor";
import { UpdateCurso } from "../../ui/forms/UpdateCurso";
import { ConformacionCurso } from "../../ui/forms/ConformacionCurso";
import { useEffect } from "react";
import { HorariosCurso } from "../../ui/forms/HorariosCurso";
import { CreateMove } from "../../ui/forms/CreateMove";
import { UpdateMove } from "../../ui/forms/UpdateMove";
import { UpdateRol } from "../../ui/forms/UpdateRol";
import { CreateRol } from "../../ui/forms/CreateRol";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { GestionarCursosAlumno } from "../../ui/forms/gestionarCursosAlumnos/GestionarCursosAlumno";
import { CreateUsuario } from "../../ui/forms/CreateUsuario";
import { GestionarCajas } from "../../ui/forms/GestionarCajas";
import { appReload } from "../../../utils/appReload";
import { UpdateUsuario } from "../../ui/forms/UpdateUsuario";

const ROUTES = [
  "alumnos",
  "instructores",
  "cursos",
  "finanzas",
  "estadisticas",
  "usuarios",
  "configuracion",
  "transferencias",
];

export const MainView = ({ children }) => {
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  const { drawerContent, setDrawerContent, user } = useContext(GlobalContext);

  const handleActiveModule = (module) => {
    let pathname = window.location.pathname;

    if (module === "inicio") {
      if (!ROUTES.some((element) => pathname?.includes(element))) {
        return "menu-item selected";
      } else {
        return "menu-item";
      }
    } else {
      if (pathname.includes(module)) {
        return "menu-item selected";
      } else {
        return "menu-item";
      }
    }

    // let hash = window.location.hash;

    // const hashArray = hash.split("/");

    // if (module === "inicio") {
    //   if (!ROUTES.some((element) => hashArray[1]?.includes(element))) {
    //     return "menu-item selected";
    //   } else {
    //     return "menu-item";
    //   }
    // } else {
    //   if (hashArray[1] === module) {
    //     return "menu-item selected";
    //   } else {
    //     return "menu-item";
    //   }
    // }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    appReload();
  };

  const onDrawerClose = () => {
    setDrawerContent({
      visible: false,
      type: "",
      item: {},
    });
  };

  const handleDrawer = () => {
    switch (drawerContent.type) {
      case "Agregar Alumno":
        return <CreateAlumno />;
      case "Editar Alumno":
        return <UpdateAlumno />;
      case "Ver Alumno":
        return <UpdateAlumno edit={false} />;
      case "Agregar Instructor":
        return <CreateInstructor />;
      case "Editar Instructor":
        return <UpdateInstructor />;
      case "Ver Instructor":
        return <UpdateInstructor edit={false} />;
      case "Agregar Curso":
        return <CreateCurso />;
      case "Editar Curso":
        return <UpdateCurso />;
      case "Ver Curso":
        return <UpdateCurso edit={false} />;
      case "Conformacion Curso":
        return <ConformacionCurso />;
      case "Horarios Curso":
        return <HorariosCurso />;
      case "Agregar Movimiento":
        return <CreateMove />;
      case "Ver Movimiento":
        return <UpdateMove edit={false} />;
      case "Editar Movimiento":
        return <UpdateMove />;
      case "Editar Movimiento desde cierre":
        return <UpdateMove modulo="cajas" />;
      case "Ver Usuario":
        return <UpdateUsuario edit={false} />;
      case "Editar Usuario":
        return <UpdateUsuario />;
      case "Agregar Rol":
        return <CreateRol />;
      case "Ver Rol":
        return <UpdateRol edit={false} />;
      case "Editar Rol":
        return <UpdateRol />;
      case "Gestionar Cursos Alumno":
        return <GestionarCursosAlumno />;
      case "Crear Usuario":
        return <CreateUsuario />;
      case "Gestionar Cajas":
        return <GestionarCajas />;
      default:
        return <span>Tipo no soportado</span>;
    }
  };

  const drawerWidth = useMemo(() => {
    if (
      ["Conformacion Curso", "Gestionar Cajas"].includes(drawerContent.type)
    ) {
      return 600;
    } else if (drawerContent.type === "Horarios Curso") {
      return 500;
    } else {
      return 420;
    }
  }, [drawerContent]);

  return (
    <div className="main-view-wrapper">
      <div className="side-menu-wrapper">
        <div className="side-menu-group">
          <Tooltip placement="right" title={"Inicio"} mouseEnterDelay={0.15}>
            <div
              className={handleActiveModule("inicio")}
              onClick={() => handleRoute("/")}
            >
              <GoHome size={24} />
            </div>
          </Tooltip>
          {isAvailable(
            "students",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip placement="right" title={"Alumnos"} mouseEnterDelay={0.15}>
              <div
                className={handleActiveModule("alumnos")}
                onClick={() => handleRoute("/alumnos")}
              >
                <GoMortarBoard size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "instructors",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Instructores"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("instructores")}
                onClick={() => handleRoute("/instructores")}
              >
                <FaChalkboardTeacher size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "courses",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip placement="right" title={"Cursos"} mouseEnterDelay={0.15}>
              <div
                className={handleActiveModule("cursos")}
                onClick={() => handleRoute("/cursos")}
              >
                <BiBook size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "moves",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Finanzas"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("finanzas")}
                onClick={() => handleRoute("/finanzas")}
              >
                <BiDollar size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "transfers",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Transferencias"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("transferencias")}
                onClick={() => handleRoute("/transferencias")}
              >
                <LiaExchangeAltSolid size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "stats",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Estadísticas"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("estadisticas")}
                onClick={() => handleRoute("/estadisticas")}
              >
                <TfiStatsUp size={24} />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="side-menu-group">
          {isAvailable(
            "users",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Usuarios y roles"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("usuarios")}
                onClick={() => handleRoute("/usuarios")}
              >
                <FiUsers size={24} />
              </div>
            </Tooltip>
          )}
          {isAvailable(
            "settings",
            SECCION_PERMISOS.modulo,
            user?.rol?.permissions
          ) && (
            <Tooltip
              placement="right"
              title={"Configuración"}
              mouseEnterDelay={0.15}
            >
              <div
                className={handleActiveModule("configuracion")}
                onClick={() => handleRoute("/configuracion")}
              >
                <FiSettings size={24} />
              </div>
            </Tooltip>
          )}
          <Popover
            placement="rightBottom"
            trigger="click"
            content={
              <div className="logged-in-user-wrapper">
                {user?.name && (
                  <p className="logged-in-user-name">{user.name}</p>
                )}
                <Button onClick={() => handleLogout()} type="primary">
                  Cerrar sesión
                </Button>
              </div>
            }
          >
            <div className="menu-item">
              <FiLogOut size={24} />
            </div>
          </Popover>
        </div>
      </div>
      {children}
      <Drawer
        open={drawerContent.visible}
        onClose={onDrawerClose}
        closable={false}
        width={drawerWidth}
      >
        <div className="form-wrapper">{handleDrawer()}</div>
      </Drawer>
    </div>
  );
};
