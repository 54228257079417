import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Checkbox, Form, Input, Tooltip } from "antd";
import { TbShieldCheck } from "react-icons/tb";
import { FiEdit3, FiEye, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { PERMISOS_TEMPLATE } from "../../../assets/permisos-template";
import { SETTINGS_TEMPLATE } from "../../../utils/settingsTemplate";
import { handlePermisos } from "../../../utils/permisos";
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE } from "../../../graphql/mutation/Usuarios";

export const CreateRol = () => {
  const { drawerContent, setDrawerContent, messageApi, setProfiles } =
    useContext(GlobalContext);

  const [permisos, setPermisos] = useState({
    students: [],
    instructors: [],
    courses: [],
    coursesSchedule: [],
    coursesConformation: [],
    moves: [],
    settings: [],
    users: [],
    stats: [],
  });

  const [newPermissionProfileResolver, { data, loading, error }] = useMutation(
    CREATE_PROFILE,
    {
      onCompleted: (data) => {
        if (data?.newPermissionProfileResolver?._id) {
          messageApi.success("Rol agregado correctamente");

          setProfiles((prevState) => {
            return [...prevState, data.newPermissionProfileResolver];
          });

          setDrawerContent({ visible: false, type: "", item: {} });
        } else {
          messageApi.error("Ocurrió un error al agregar un rol");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar un rol");
      },
    }
  );

  const [form] = Form.useForm();

  const onFinish = (v) => {
    v.name = v.name.trim() || "";

    if (v.name.length < 3) {
      return messageApi.info("Debe tener un nombre de al menos 3 caracteres");
    }

    newPermissionProfileResolver({
      variables: { permissionInput: { name: v.name, permissions: permisos } },
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="createRol"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item name="name" label="Nombre">
          <Input />
        </Form.Item>
        <div style={{ marginBottom: "24px" }}>
          <span className="horizontal permisos-wrapper">
            <b>Permisos</b>
            <span className="horizontal permisos-item">
              <Tooltip title="Habilitar módulo" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <TbShieldCheck />
                </span>
              </Tooltip>
              <Tooltip title="Ver" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiEye />
                </span>
              </Tooltip>
              <Tooltip title="Agregar" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiPlus />
                </span>
              </Tooltip>
              <Tooltip title="Editar" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiEdit3 />
                </span>
              </Tooltip>
              <Tooltip title="Eliminar" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <AiOutlineDelete />
                </span>
              </Tooltip>
            </span>
          </span>
          {PERMISOS_TEMPLATE.map((seccion, index) => {
            return (
              <span className="horizontal permisos-wrapper" key={index}>
                <p>{seccion.label}</p>
                <span
                  className="horizontal permisos-item"
                  style={{ width: "fit-content" }}
                >
                  {SETTINGS_TEMPLATE.map((check, index) => {
                    return (
                      <Checkbox
                        checked={permisos[seccion.name]?.includes(check)}
                        key={index}
                        onChange={(v) =>
                          handlePermisos(
                            seccion.name,
                            check,
                            v.target.checked,
                            setPermisos
                          )
                        }
                      />
                    );
                  })}
                </span>
              </span>
            );
          })}
        </div>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button htmlType="submit" className="btn-guardar" size="large" loading={loading}>
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() =>
              setDrawerContent({ visible: false, item: {}, type: "" })
            }
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
