import { gql } from "@apollo/client";

export const CREATE_STUDENT = gql`
  mutation NewStudentResolver($turnsId: [String], $studentInput: studentInput) {
    newStudentResolver(turnsId: $turnsId, studentInput: $studentInput) {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      phoneNumbers {
        phoneNumber
      }
      email
      addressInfo {
        street
        cp
        city
      }
      medicalRecord
      createdAt
      status
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($studentId: String, $studentInput: studentInput) {
    updateStudentResolver(studentId: $studentId, studentInput: $studentInput) {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      phoneNumbers {
        phoneNumber
      }
      email
      addressInfo {
        street
        cp
        city
      }
      medicalRecord
      createdAt
    }
  }
`;

export const DELETE_STUDENT = gql`
  mutation DeleteStudent($studentId: String) {
    deleteStudentResolver(studentId: $studentId) {
      status
      message
    }
  }
`;

export const WITHDRAWAL_STUDENT = gql`
  mutation StudentWithdrawalResolver(
    $studentId: String
    $turnId: String
    $motive: String
    $description: String
  ) {
    studentWithdrawalResolver(
      studentId: $studentId
      turnId: $turnId
      motive: $motive
      description: $description
    ) {
      status
      message
    }
  }
`;
