
import { MainView } from "../components/layout/mainView/MainView";
import { Transferencias } from "../components/layout/transferencias/Transferencias";

export const TransfersView = () => {
  return (
    <MainView>
      <Transferencias />
    </MainView>
  );
};
