export const makeFloat = (value) => {
  return parseFloat(value?.toString()?.replace(",", ".") || 0);
};

export const thousandFormatter = (number, entero) => {
  try {
    if (number == null) {
      number = 0;
    }

    if (entero) {
      number = makeFloat(number).toLocaleString("de-DE", {
        maximumFractionDigits: 0,
      });
    } else {
      number = makeFloat(number).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  } catch (e) {
    return number;
  }

  return number;
};
