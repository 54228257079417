import { Button, Drawer, Modal, Table } from "antd";
import dayjs from "dayjs";
import { FiX } from "react-icons/fi";
import { useContext, useMemo, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { GlobalContext } from "../../context/GlobalContext";
import { ArqueoDeCaja } from "../reports/components/cierreCaja/ArqueoDeCaja";

const columns = () => {
  return [
    {
      title: "Método de pago",
      dataIndex: "name",
      key: "name",
      render: (data) => data,
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (data) => (
        <p
          className="number-ff"
          style={data >= 0 ? { color: "#00A150" } : { color: "#FF4D48" }}
        >
          {thousandFormatter(data)}
        </p>
      ),
    },
  ];
};

export const ModalTransferencias = ({ dataDetalle, setDataDetalle }) => {
  const { config } = useContext(GlobalContext);

  const formattedData = useMemo(() => {
    if (config && dataDetalle) {
      const { accountingBoxes, paymentMethods } = config || {};

      const accountingBoxesMap = accountingBoxes?.reduce((acc, box) => {
        acc[box._id] = box.name;
        return acc;
      }, {});

      const paymentMethodsMap = paymentMethods?.reduce((acc, method) => {
        acc[method._id] = method.name;
        return acc;
      }, {});

      let { abFrom, abTo, transferInput } = dataDetalle;

      let obj = {
        ...dataDetalle,
        transferInput: transferInput?.map((element) => ({
          ...element,
          name: paymentMethodsMap[element.paymentMethodId],
        })),
      };

      if (typeof abFrom === "string") {
        obj.abFrom = {
          _id: abFrom,
          name: accountingBoxesMap[abFrom],
        };
      }

      if (typeof abTo === "string") {
        obj.abTo = {
          _id: abTo,
          name: accountingBoxesMap[abTo],
        };
      }

      return obj;
    }
  }, [config, dataDetalle]);

  return (
    <Modal
      open={dataDetalle?._id}
      className="custom-modal-wrapper"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      closeIcon={
        <div className="close-icon" onClick={() => setDataDetalle(null)}>
          <FiX />
        </div>
      }
      footer={false}
    >
      <>
        {dataDetalle?.type === "openingData" && (
          <span className="horizontal">
            <h4>Apertura</h4>
          </span>
        )}
        {dataDetalle?._id && dataDetalle?.type !== "openingData" && (
          <span className="horizontal" style={{ gap: 16 }}>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Origen:</p>
              <b>{formattedData.abFrom?.name}</b>
            </span>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Destino:</p>
              <b>{formattedData.abTo?.name}</b>
            </span>
            <span
              className="horizontal"
              style={{ width: "fit-content", gap: 4 }}
            >
              <p>Fecha:</p>
              <b className="number-ff">
                {dayjs(dataDetalle.createdAt, "x")?.format("DD/MM/YYYY")}
              </b>
            </span>
          </span>
        )}
        {dataDetalle ? (
          <Table
            style={{
              marginTop: 20,
              width: "clamp(400px, 90vw, 800px)",
            }}
            // loading={loading}
            columns={columns()}
            dataSource={formattedData?.transferInput || []}
            rowKey={"paymentMethodId"}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              // showTotal: (total, range) =>
              //   `${range[0]}-${range[1]} de ${total} movimientos`,
            }}
            // footer={(data) => {
            //   return <span>{`Total: $${dataDetalle.amount}`}</span>;
            // }}
          />
        ) : (
          <></>
        )}
      </>
      <span className="horizontal" style={{ marginTop: 16 }}>
        <Button
          onClick={() => setDataDetalle(null)}
          style={{ marginLeft: "auto" }}
          className="btn-cancelar"
        >
          Volver
        </Button>
      </span>
      {/* <Drawer
        open={showDrawer}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setShowDrawer(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <ArqueoDeCaja
            dataCierre={dataDetalle}
            // dataConceptos={dataConceptos}
          />
        </PDFViewer>
      </Drawer> */}
    </Modal>
  );
};
