import { useLazyQuery } from "@apollo/client";
import { GET_UPDATED_USER_DATA } from "../../graphql/query/Usuarios";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const passphrase = process.env.REACT_APP_TOKEN_SECRET;

export const useUser = (user, setUser) => {
  const [getUserData, { data }] = useLazyQuery(GET_UPDATED_USER_DATA, {
    fetchPolicy: "network-only",
  });

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (
      user?._id &&
      !flag &&
      !["student", "instructor"].includes(user?.type?.toLowerCase())
    ) {
      getUserData({ variables: { userId: user._id } });
    }
  }, [user]);

  useEffect(() => {
    if (data?.getUserInfoResolver) {
      const token = data.getUserInfoResolver.message || null;

      if (token) {
        let tmp = JSON.parse(
          CryptoJS.AES.decrypt(token, passphrase).toString(CryptoJS.enc.Utf8)
        );

        if (tmp?._id) {
          setFlag(true);
          setUser(tmp);
          localStorage.setItem("token", token);
        }
      }
    }
  }, [data]);

  return null;
};
