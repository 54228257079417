export const studentAddTurn = (
  setTurnos,
  selectedCourse,
  selectedTurn,
  cursos,
  messageApi
) => {
  if (!selectedCourse || !selectedTurn) {
    return messageApi.info("Por favor seleccione un curso y un turno");
  }

  setTurnos((prevState) => {
    let array = [...prevState];

    let curso = cursos.find((curso) => curso._id === selectedCourse);

    let turno = curso?.turnsConformation?.find(
      (turno) => turno._id === selectedTurn
    );

    if (turno?._id) {
      if (array?.map((element) => element.id)?.includes(turno?._id)) {
        messageApi.info("Ese turno ya fue agregado");
      } else {
        array.push({ id: turno._id, name: `${curso.name} - ${turno.name}` });
      }
    }

    return array;
  });
};

export const studentDeleteTurn = (setTurnos, index, messageApi) => {
  if (index === null || index === undefined) {
    return messageApi.info("No se obtuvo un turno");
  }

  setTurnos((prevState) => {
    let array = [...prevState];

    array.splice(index, 1);

    return array;
  });
};
