import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select, Switch } from "antd";
import { CREATE_MOVE } from "../../../graphql/mutation/Finanzas";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { GET_COURSES_BY_STUDENT_ID } from "../../../graphql/query/Cursos";
import {
  handleFeeChecked,
  handleFeeValue,
  isFeePaid,
} from "../../../utils/handleFee";
import {
  MAIN_CURRENCY_ID,
  MAIN_PROFILE_ID,
  PAGO_DE_CUOTA_ID,
} from "../../../utils/relevantIds";
import { BlobLoader } from "../loader/BlobLoader";
import { GET_COURSE_MOVES_BY_STUDENT_ID } from "../../../graphql/query/Finanzas";
import { makeFloat } from "../../../utils/thousandFormatter";
import {
  addPaymentMethod,
  deletePaymentMethod,
  filterOption,
  getPaymentTotal,
  handlePaymentMethod,
  handlePaymentValue,
} from "../../../utils/handlePayment/paymentUtils";
import { FiX } from "react-icons/fi";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const CreateMove = () => {
  const {
    setDrawerContent,
    config,
    user,
    messageApi,
    alumnos,
    setMoves,
    movesPoll,
    setFilteredMoves,
  } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [hasStudent, setHasStudent] = useState(false);
  const [selectedAlumno, setSelectedAlumno] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [cuotas, setCuotas] = useState([]);
  const [conceptId, setConceptId] = useState("");
  const [paidFees, setPaidFees] = useState([]);
  const [payment, setPayment] = useState([{ paymentMethodId: null, total: 0 }]);

  const [newAccountingBoxMovesResolver, { data, loading, error }] =
    useMutation(CREATE_MOVE);

  const [
    getCoursesByStudentId,
    { loading: loadingById, error: errorById, data: dataById },
  ] = useLazyQuery(GET_COURSES_BY_STUDENT_ID, { fetchPolicy: "network-only" });

  const [
    getCourseMovesByStudentId,
    { loading: loadingMoves, error: errorMoves, data: dataMoves },
  ] = useLazyQuery(GET_COURSE_MOVES_BY_STUDENT_ID, {
    fetchPolicy: "network-only",
  });

  const onFinish = (values) => {
    if (
      !values.operation ||
      !values.areaId ||
      !values.accountBoxId ||
      !conceptId ||
      !values.currencyId ||
      !values.date
    ) {
      messageApi.info(
        "Los campos operación, área, caja, concepto, total, divisa, métodos de pago y fecha son obligatorios, ingrese un valor para cada uno de ellos"
      );
      return;
    }

    const total = getPaymentTotal(payment);

    values.payment = payment.map((element) => {
      return {
        paymentMethodId: element.paymentMethodId,
        total: makeFloat(element.total),
      };
    });

    if (conceptId !== PAGO_DE_CUOTA_ID && !total) {
      messageApi.info("Ingrese al menos un metodo de pago y monto, por favor");
      return;
    }

    let date = Number(dayjs(values.date)?.format("x"));

    values.date = date;
    values.total = makeFloat(total);
    values.conceptId = conceptId;

    if (hasStudent) {
      if (!selectedAlumno) {
        return messageApi.info(
          "Debe seleccionar un alumno si decidió asignar uno."
        );
      }

      values.studentId = selectedAlumno;

      if (selectedCourse && conceptId === PAGO_DE_CUOTA_ID) {
        let flag = false;

        let acumulador = 0;

        let obj = {
          courseId: selectedCourse,
          fees: cuotas
            .filter((element) => element.checked)
            .map((cuota) => {
              if (!/^[0-9]+([.,][0-9]{1,2})?$/.test(cuota.value)) {
                messageApi.info(
                  `Por favor, ingrese un monto válido. Cuota ${cuota.feeNumber}`
                );
                flag = true;
              } else {
                let number = makeFloat(cuota?.value);

                acumulador += number;

                return {
                  feeNumber: cuota.feeNumber,
                  value: makeFloat(cuota?.value),
                };
              }
            }),
        };

        if (flag) {
          return;
        }

        if (obj.fees?.length < 1) {
          return messageApi.info(
            "No puede crear un pago de cuotas sin seleccionar ninguna"
          );
        }

        if (total !== acumulador) {
          messageApi.info(
            "La suma de las cuotas debe ser igual al total de los métodos de pago"
          );
          return;
        }

        values.total = acumulador;

        values.feeDetail = obj;
      }
    } else {
      values.studentId = null;
    }

    let newMove = { ...values };

    newAccountingBoxMovesResolver({ variables: { moveInput: newMove } })
      .then((res) => {
        if (res?.data?.newAccountingBoxMovesResolver?._id) {
          messageApi.success("Se creó un nuevo movimiento correctamente");
          const resultado = res.data.newAccountingBoxMovesResolver;

          setFilteredMoves((prevState) => {
            let array = [resultado, ...prevState];

            return array;
          });

          setDrawerContent({
            visible: false,
            type: "",
            item: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al crear un movimiento");
      });
  };

  const handleOptions = () => {
    if (user?.rol?._id === MAIN_PROFILE_ID) {
      return config?.accountingBoxes?.map((element) => {
        return {
          value: element._id,
          label: element.name,
        };
      });
    } else {
      return user?.userBoxRelations?.map((element) => {
        return {
          value: element.accountBoxData?._id,
          label: element.accountBoxData?.name,
        };
      });
    }
  };

  useEffect(() => {
    if (selectedAlumno) {
      getCoursesByStudentId({
        variables: {
          studentId: selectedAlumno,
        },
      });
    }
  }, [selectedAlumno]);

  useEffect(() => {
    if (selectedCourse && selectedAlumno) {
      getCourseMovesByStudentId({
        variables: {
          studentId: selectedAlumno,
          courseId: selectedCourse,
        },
      });
    }
  }, [selectedCourse, selectedAlumno]);

  useEffect(() => {
    try {
      if (selectedCourse) {
        let filtro = dataById?.getCoursesByStudentIdResolver?.find(
          (element) => element?.courseInfo?._id === selectedCourse
        )?.courseInfo;

        if (filtro) {
          let array = [];
          let cantidadCuotas = Number(filtro.cost?.amountOfFees);
          for (let index = 0; index < cantidadCuotas; index++) {
            array.push({ checked: false, feeNumber: index, value: 0 });
          }

          setCuotas(array);
        }
      } else {
        setCuotas([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedCourse, dataById]);

  useEffect(() => {
    if (Array.isArray(dataMoves?.getCourseMovesByStudentId)) {
      let array = [];
      dataMoves.getCourseMovesByStudentId.forEach((element) =>
        element?.feeDetail?.fees?.forEach((element) => array.push(element))
      );

      setPaidFees(array);
    }
  }, [dataMoves]);

  const paymentOptions = useMemo(() => {
    return config?.paymentMethods?.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
  }, [config]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearMove"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <span className="horizontal">
          <Form.Item
            label="Operación"
            name="operation"
            className="form-item-half-row"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
            initialValue="INGRESO"
          >
            <Select
              options={[
                { value: "INGRESO", label: "INGRESO" },
                { value: "EGRESO", label: "EGRESO" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Área"
            name="areaId"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={config?.areas?.map((element) => {
                return { value: element._id, label: element.name };
              })}
              showSearch
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            label="Caja"
            name="accountBoxId"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select options={handleOptions()} />
          </Form.Item>
          <Form.Item label="Concepto" className="form-item-half-row">
            <Select
              options={config?.concepts?.map((element) => {
                return { value: element._id, label: element.name };
              })}
              value={conceptId}
              onChange={(v) => setConceptId(v)}
              showSearch
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            label="Divisa"
            name="currencyId"
            className="form-item-half-row"
            initialValue={MAIN_CURRENCY_ID}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              options={config?.currencies?.map((element) => {
                return {
                  value: element._id,
                  label: `${element.symbol} - ${element.name}`,
                };
              })}
              showSearch
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Fecha"
            name="date"
            className="form-item-half-row"
            initialValue={dayjs()}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </span>
        <Form.Item label="Descripción" name="description">
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item label="Métodos de pago">
          <div className="columna">
            {payment?.map((metodo, index) => {
              return (
                <span className="horizontal" key={index}>
                  <Select
                    style={{ width: "60%" }}
                    options={paymentOptions}
                    showSearch
                    filterOption={filterOption}
                    value={metodo?.paymentMethodId}
                    onChange={(v) => handlePaymentMethod(setPayment, index, v)}
                  />
                  <Input
                    style={{ flex: 1 }}
                    type="number"
                    value={metodo.total}
                    onChange={(v) =>
                      handlePaymentValue(setPayment, index, v.target.value)
                    }
                  />
                  <span
                    className="pointer"
                    onClick={() => deletePaymentMethod(setPayment, index)}
                  >
                    <FiX size={14} />
                  </span>
                </span>
              );
            })}
            <span className="horizontal">
              <Button
                style={{ marginLeft: "auto" }}
                onClick={() => addPaymentMethod(setPayment)}
              >
                Agregar otro método
              </Button>
            </span>
          </div>
        </Form.Item>
        <div className="move-student-section">
          <span className="horizontal">
            <p>Asignar a un alumno</p>
            <Switch checked={hasStudent} onChange={(v) => setHasStudent(v)} />
          </span>
          {hasStudent && (
            <Form.Item label="Alumno">
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={alumnos
                  ?.filter((element) => element.status === "ACTIVE")
                  ?.map((student) => {
                    return { value: student._id, label: student.fullName };
                  })}
                value={selectedAlumno}
                onChange={(v) => setSelectedAlumno(v)}
              />
            </Form.Item>
          )}
          {hasStudent &&
            selectedAlumno &&
            conceptId === PAGO_DE_CUOTA_ID &&
            (loadingById ? (
              <BlobLoader />
            ) : (
              <>
                <Form.Item label="Curso">
                  <Select
                    options={dataById?.getCoursesByStudentIdResolver?.map(
                      (element) => {
                        return {
                          value: element.courseInfo?._id,
                          label: `${element.courseInfo?.name} - ${element.turnInfo?.name}`,
                        };
                      }
                    )}
                    showSearch
                    filterOption={(input, option) =>
                      (option.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedCourse}
                    onChange={(v) => setSelectedCourse(v)}
                  />
                </Form.Item>
                {selectedCourse && cuotas?.length > 0 && (
                  <Form.Item label="Coutas">
                    {cuotas.map((element) => {
                      return (
                        <span
                          className="horizontal"
                          key={element.feeNumber}
                          style={{ marginBottom: "4px" }}
                        >
                          <span
                            className="contenedor-cuota"
                            style={
                              isFeePaid(element, paidFees)
                                ? { backgroundColor: "#0000000A" }
                                : element?.checked
                                ? {
                                    color: "var(--detail-color)",
                                    border: "1px solid var(--detail-color)",
                                  }
                                : {}
                            }
                            onClick={() =>
                              !isFeePaid(element, paidFees) &&
                              setCuotas((prevState) =>
                                handleFeeChecked(prevState, element.feeNumber)
                              )
                            }
                          >
                            {element.feeNumber + 1}
                          </span>
                          <Input
                            type="number"
                            value={element.value}
                            disabled={isFeePaid(element, paidFees)}
                            onChange={(v) =>
                              setCuotas((prevState) =>
                                handleFeeValue(
                                  prevState,
                                  element.feeNumber,
                                  v.target.value,
                                  messageApi
                                )
                              )
                            }
                          />
                        </span>
                      );
                    })}
                  </Form.Item>
                )}
              </>
            ))}
        </div>
        <span className="horizontal">
          <Form.Item
            label="Total"
            className="form-item-half-row"
            style={{ marginLeft: "auto" }}
          >
            <Input
              disabled={conceptId === PAGO_DE_CUOTA_ID}
              value={getPaymentTotal(payment)}
            />
          </Form.Item>
        </span>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
