import { Page, View, Document, Font } from "@react-pdf/renderer";
import { ItemsTable } from "./ItemsTable.jsx";
import { pdfStyles } from "../../styles.js";
import { Title } from "../Title.jsx";
import { Detail } from "./Detail.jsx";

Font.register({
  family: "Roboto",
  fonts: [{ fontWeight: 600 }, { fontWeight: 700 }],
});

export const Recibo = ({ data, alumnos }) => {
  return (
    <Document>
      <Page size="A4">
        <View style={pdfStyles.page}>
          <Title data={data} />
          <Detail data={data} alumnos={alumnos} />
          <ItemsTable data={data} />
        </View>
      </Page>
    </Document>
  );
};
