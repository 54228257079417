import { Alumnos } from "../components/layout/alumnos/Alumnos"
import { MainView } from "../components/layout/mainView/MainView"

export const AlumnosView = () => {
  return (
    <MainView>
        <Alumnos />
    </MainView>
  )
}
