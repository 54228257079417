import { useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useContext } from "react";
import { CREATE_COURSE } from "../../../graphql/mutation/Cursos";
import { GlobalContext } from "../../context/GlobalContext";
import { FormProvider } from "antd/es/form/context";
import dayjs from "dayjs";
import { makeFloat } from "../../../utils/thousandFormatter";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const CreateCurso = () => {
  const { setDrawerContent, cursos, setCursos, messageApi, config } =
    useContext(GlobalContext);
  const [form] = Form.useForm();

  const [newCourseResolver, { data, loading, error }] = useMutation(
    CREATE_COURSE,
    {
      onCompleted: (data) => {
        if (data?.newCourseResolver) {
          if (data.newCourseResolver.name) {
            //! AGREGAR CONDICION SIN STATUS
            let array = [...cursos];
            array.push({ ...data.newCourseResolver, turnsConformation: [] });

            setCursos(array);

            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
            messageApi.success("Curso creado correctamente");
          }
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar un curso");
      },
    }
  );

  const onFinish = (values) => {
    let curso = {};

    if (
      !values.name ||
      !values.category ||
      !values.duration ||
      !values.startDate ||
      !values.finishDate ||
      !values.amountOfFees ||
      !values.inscription ||
      !values.total
    ) {
      return messageApi.info(
        "Todos los campos son obligatorios, ingrese un valor para cada uno"
      );
    }

    curso.name = values.name;
    curso.description = values.description;
    curso.category = values.category;
    curso.duration = values.duration;
    curso.startDate = dayjs(values.startDate).format("DD-MM-YYYY");
    curso.finishDate = dayjs(values.finishDate).format("DD-MM-YYYY");

    let cost = {
      amountOfFees: Number(values.amountOfFees),
      inscription: makeFloat(values?.inscription),
      total: makeFloat(values?.total),
    };
    curso.cost = cost;

    newCourseResolver({ variables: { courseInput: curso } });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearCurso"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item
          label="Nombre *"
          name="name"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item
          name="duration"
          label="Duración *"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Categoría"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select
            options={[
              { value: "ESTETICA", label: "ESTETICA" },
              { value: "PELUQUERIA", label: "PELUQUERIA" },
            ]}
          />
        </Form.Item>
        <span className="horizontal">
          <Form.Item
            label="Inicio"
            name="startDate"
            className="form-item-half-row"
            initialValue={dayjs()}
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
          <Form.Item
            label="Fin"
            name="finishDate"
            className="form-item-half-row"
            initialValue={dayjs()}
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </span>
        <Form.Item
          name="inscription"
          label="Monto inscripción"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
            { required: true, message: "Campo requerido" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="total"
          label="Monto total"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
            { required: true, message: "Campo requerido" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amountOfFees"
          label="Cantidad de cuotas"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        {/* <Form.Item
          name="feeValue"
          label="Monto por cuota"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
            { required: true, message: "Campo requerido" },
          ]}
        >
          <Input />
        </Form.Item> */}
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
