import { useContext, useMemo } from "react";
import { StudentContext } from "../../context/StudentContext";
import { ErrorMessage } from "../../ui/error/ErrorMessage";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { useLocation } from "react-router-dom";
import { Empty, Tabs } from "antd";

const tabItems = [
  {
    label: `Asistencias`,
    key: 0,
    children: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  },
  {
    label: `Notas`,
    key: 1,
    children: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  },
];

export const CursoData = () => {
  const { dataInfo, loadingInfo, errorInfo, data, loading, error } =
    useContext(StudentContext);

  const location = useLocation();

  const info = useMemo(() => {
    if (dataInfo?.getStudentInformationResolver) {
      const obj = JSON.parse(dataInfo?.getStudentInformationResolver);

      return obj;
    }
  }, [dataInfo]);

  const cursoData = useMemo(() => {
    const search = location.search;

    const id = new URLSearchParams(search).get("id");

    if (data?.getCoursesByStudentIdResolver) {
      let curso = data?.getCoursesByStudentIdResolver?.find(
        (element) => element.courseInfo?._id === id
      );

      return curso;
    }

    return null;
  }, [data, location]);

  const turno = useMemo(() => {
    if (cursoData?.turnInfo) {
      return cursoData.turnInfo;
    }

    return null;
  }, [cursoData]);

  const getHorario = (element) => {
    if (element) {
      const { day, startTime, endTime } = element;

      return `${day} desde ${startTime} hasta ${endTime}`;
    }
    return "";
  };

  if (error) return <ErrorMessage />;

  if (loading) return <BlobLoader />;

  return (
    <div className="student-home-wrapper">
      <p className="mobile-section-title">{cursoData?.courseInfo?.name}</p>
      {turno && (
        <div className="turno-description-wrapper">
          <p className="turno-subtitle" style={{ marginBottom: 8 }}>
            {turno?.name}
          </p>
          {turno?.schedule?.map((element, index) => {
            return (
              <p style={{ fontSize: "0.9rem" }} key={index}>
                {getHorario(element)}
              </p>
            );
          })}
        </div>
      )}

      <Tabs items={tabItems} style={{ margin: "0px 16px" }} />
    </div>
  );
};
