import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Form, Input, Modal, Select } from "antd";
import { FiPlus, FiX } from "react-icons/fi";
import {
  addTurn,
  addTurnDay,
  deleteTurn,
  deleteTurnDay,
  handleTurnClassroom,
  handleTurnContent,
  handleTurnName,
} from "../../../utils/horariosCurso";
import { dias } from "../../../utils/coursesUtils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_COURSE_TURN } from "../../../graphql/mutation/Cursos";
import { GET_COURSE_CONFORMATION } from "../../../graphql/query/Cursos";
import { BlobLoader } from "../loader/BlobLoader";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { CreateClassRoom } from "./CreateClassRoom";
import { ErrorMessage } from "../error/ErrorMessage";

export const HorariosCurso = () => {
  const {
    drawerContent,
    setDrawerContent,
    cursos,
    setCursos,
    messageApi,
    user,
    config,
    loadingGlobal,
  } = useContext(GlobalContext);

  const [schedule, setSchedule] = useState([]);

  const [deletedTurns, setDeletedTurns] = useState([]);

  const [showNewAula, setShowNewAula] = useState(false);

  const [classrooms, setClassrooms] = useState([]);

  const [form] = Form.useForm();

  const [getCourseConformationResolver, { loading, error, data }] =
    useLazyQuery(GET_COURSE_CONFORMATION, {
      fetchPolicy: "network-only",
    });

  const [manageCourseTurnsResolver, { dataTurn, loadingTurn, errorTurn }] =
    useMutation(CREATE_COURSE_TURN, {
      onCompleted: (data) => {
        if (data?.manageCourseTurnsResolver?._id) {
          let index = cursos.findIndex(
            (curso) => curso._id === drawerContent.item._id
          );
          if (index >= 0) {
            let array = structuredClone(cursos);

            array[index] = data.manageCourseTurnsResolver;
            setCursos(array);
            messageApi.success("Curso actualizado correctamente");
            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
          }
        } else {
          messageApi.error("Hubo un error al actualizar el curso");
        }
      },
      onError: () => {
        messageApi.error("Hubo un error al actualizar el curso");
      },
    });

  const isDeletable = (conformation) => {
    if (conformation?.instructor || conformation?.students?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onFinish = () => {
    if (drawerContent?.item?._id && schedule?.length > 0) {
      let flag = false;
      let array = schedule.map((turno) => {
        if (!turno.name || !turno.classroomId) {
          messageApi.info("Falta información sobre el turno");
          flag = true;
        }
        return {
          name: turno.name,
          classroomId: turno.classroomId,
          schedule: turno.schedule.map((horario) => {
            if (!horario.day || !horario.startTime || !horario.endTime) {
              messageApi.info("Falta información sobre horarios");
              flag = true;
            }
            return {
              day: horario.day,
              startTime: horario.startTime,
              endTime: horario.endTime,
            };
          }),
          _id: turno._id ? turno._id : null,
          courseId: drawerContent.item?._id,
        };
      });

      if (flag) {
        return;
      }

      manageCourseTurnsResolver({
        variables: {
          turnInput: array,
          deleteTurns: deletedTurns,
          courseId: drawerContent.item?._id,
        },
      });
    } else {
      return messageApi.info("Falta información necesaria para guardar");
    }
  };

  useEffect(() => {
    if (drawerContent?.item?._id) {
      getCourseConformationResolver({
        variables: {
          courseId: drawerContent.item?._id,
        },
      });
    }
  }, [drawerContent]);

  useEffect(() => {
    if (data?.getCourseConformationResolver) {
      setSchedule(data.getCourseConformationResolver);
    }
  }, [data]);

  useEffect(() => {
    if (config?.classrooms) {
      let array = config?.classrooms?.map((element) => {
        return { value: element._id, label: element.name };
      });

      setClassrooms(array);
    }
  }, [config]);

  if (loading) return <BlobLoader />;

  if (error) return <ErrorMessage />;

  return (
    drawerContent.item && (
      <Form
        form={form}
        layout="vertical"
        onFinish={(v) => onFinish(v)}
        name="turnConformation"
        className="form-layout"
        disabled={
          !isAvailable(
            "coursesConformation",
            SECCION_PERMISOS.editar,
            user?.rol?.permissions
          )
        }
      >
        <Modal
          open={showNewAula}
          footer={false}
          title="Nueva aula"
          onCancel={() => setShowNewAula(false)}
        >
          <CreateClassRoom setShowNewAula={setShowNewAula} />
        </Modal>
        <div className="form-content">
          <p className="titulo-curso">{drawerContent.item.name}</p>
          <span className="linea-turno">
            <p>Horario</p>
            {isAvailable(
              "coursesSchedule",
              SECCION_PERMISOS.crear,
              user?.rol?.permissions
            ) && (
              <Button
                style={{ marginLeft: "auto", marginRight: "5px" }}
                onClick={() => setSchedule(addTurn(schedule))}
              >
                Nuevo turno
              </Button>
            )}
          </span>
          <div className="horarios-layout">
            {schedule?.map((turno, turnIndex) => {
              isDeletable(turno.conformation);
              return (
                <div className="turno-wrapper" key={turnIndex}>
                  <span className="linea-turno">
                    <p>Nombre del turno</p>
                    {isDeletable(turno.conformation) && (
                      <FiX
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSchedule((prevState) =>
                            deleteTurn(prevState, turnIndex)
                          );
                          setDeletedTurns([...deletedTurns, turno._id]);
                        }}
                      />
                    )}
                  </span>
                  <Input
                    value={turno?.name}
                    onChange={(v) =>
                      setSchedule((prevState) =>
                        handleTurnName(v.target.value, prevState, turnIndex)
                      )
                    }
                  />
                  <p>Aula</p>
                  <span className="horizontal">
                    <Select
                      placeholder="Seleccione un aula"
                      style={{ width: "200px" }}
                      options={classrooms}
                      showSearch
                      filterOption={(input, option) =>
                        (option.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={turno?.classroomId}
                      onChange={(v) =>
                        setSchedule((prevState) =>
                          handleTurnClassroom(v, prevState, turnIndex)
                        )
                      }
                    />
                    <Button
                      className="horizontal"
                      style={{ width: "fit-content" }}
                      onClick={() => setShowNewAula(true)}
                    >
                      <FiPlus size={18} />
                    </Button>
                  </span>
                  <span className="linea-turno">
                    <p style={{ width: "31%" }}>Día</p>
                    <p style={{ width: "31%" }}>Inicio</p>
                    <p style={{ width: "31%" }}>Fin</p>
                    <span style={{ width: "4%" }}></span>
                  </span>
                  {turno?.schedule?.map((dia, dayIndex) => {
                    return (
                      <span
                        className="linea-turno"
                        key={`${turnIndex}-${dayIndex}`}
                      >
                        <Select
                          options={dias}
                          style={{ width: "31%" }}
                          value={dia?.day}
                          onChange={(v) =>
                            setSchedule(
                              handleTurnContent(
                                v,
                                schedule,
                                "day",
                                turnIndex,
                                dayIndex
                              )
                            )
                          }
                        />
                        <Input
                          type="time"
                          value={dia?.startTime}
                          style={{ width: "31%" }}
                          onChange={(v) =>
                            setSchedule(
                              handleTurnContent(
                                v.target.value,
                                schedule,
                                "startTime",
                                turnIndex,
                                dayIndex
                              )
                            )
                          }
                        />
                        <Input
                          type="time"
                          value={dia?.endTime}
                          style={{ width: "31%" }}
                          onChange={(v) =>
                            setSchedule(
                              handleTurnContent(
                                v.target.value,
                                schedule,
                                "endTime",
                                turnIndex,
                                dayIndex
                              )
                            )
                          }
                        />
                        <span style={{ width: "4%" }}>
                          <FiX
                            size={18}
                            style={{
                              cursor: "pointer",
                              display: "grid",
                              placeItems: "center",
                            }}
                            onClick={() =>
                              setSchedule(
                                deleteTurnDay(schedule, turnIndex, dayIndex)
                              )
                            }
                          />
                        </span>
                      </span>
                    );
                  })}
                  {isAvailable(
                    "coursesSchedule",
                    SECCION_PERMISOS.editar,
                    user?.rol?.permissions
                  ) && (
                    <Button
                      style={{ marginLeft: "auto", marginRight: "5px" }}
                      onClick={() =>
                        setSchedule(addTurnDay(schedule, turnIndex))
                      }
                    >
                      Agregar día
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              disabled={
                !isAvailable(
                  "coursesSchedule",
                  SECCION_PERMISOS.editar,
                  user?.rol?.permissions
                )
              }
              loading={loadingTurn}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() => {
                setDrawerContent({
                  visible: false,
                  content: "",
                  item: {},
                });
              }}
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      </Form>
    )
  );
};
