import { Button, Form, Transfer } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";

const filterOption = (inputValue, option) => {
  let value =
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
    option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  return value;
};

export const NewAsistencia = ({ turnConformation, setDrawerData, data }) => {
  const { alumnos, user } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [targetKeys, setTargetKeys] = useState([]);

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const getAlumnos = () => {
    if (alumnos?.length > 0) {
      let students = alumnos
        .filter((element) => element.status === "ACTIVE")
        ?.map((alumno) => {
          return {
            key: alumno._id,
            title: alumno.fullName,
            description: alumno.dni ? alumno.dni : "",
          };
        });

      return students;
    } else {
      return [];
    }
  };

  const onFinish = (values) => {};

  return (
    <Form
      form={form}
      layout="vertical"
      name="crear asistencia"
      requiredMark={false}
      className="form-layout"
      onFinish={onFinish}
    >
      <div className="form-content">
        <b style={{ marginBottom: 8 }}>Nueva asistencia</b>

        <Form.Item label="Alumnos">
          <Transfer
            style={{ width: "100%" }}
            dataSource={getAlumnos()}
            showSearch
            onChange={handleChange}
            filterOption={filterOption}
            targetKeys={targetKeys}
            listStyle={{
              width: 256,
              height: 500,
            }}
            locale={{ itemUnit: "Alumno", itemsUnit: "Alumnos" }}
            render={(item) => {
              return `${item.title} - ${item.description}`;
            }}
          />
        </Form.Item>
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              // onClick={handleAccountBoxValidation}
              size="large"
              // loading={loadingMoves}
            >
              Buscar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() =>
                setDrawerData({ visible: false, type: "", item: null })
              }
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      </div>
    </Form>
  );
};
