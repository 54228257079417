import { StyleSheet } from "@react-pdf/renderer";

export const borderColor = "#d8d8d8";

export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
    margin: 20,
    gap: 16,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  horizontal: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  horizontalBetween: {
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  columna: {
    flexDirection: "column",
    gap: 8,
  },
  titulo: {
    fontSize: 14,
    fontWeight: 700,
  },
  subtitulo: {
    fontSize: 13,
  },
  footer: {
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: "#e0e0e0",
    paddingTop: 10,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: borderColor,
    borderRadius: 4,
    marginBottom: 16,
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "#e8e8e8",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "semibold",
    flexGrow: 1,
    fontSize: 12,
  },
});
