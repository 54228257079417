export const handleFeeChecked = (prevState, numero) => {
  let array = [...prevState];

  array[numero].checked = !array[numero].checked;

  return array;
};

export const handleFeeValue = (prevState, numero, value, messageApi) => {
  let array = [...prevState];

  array[numero].value = value;

  return array;
};

export const isFeePaid = (element, paidFees) => {
  if (element && paidFees) {
    if (
      paidFees?.map((cuota) => cuota.feeNumber)?.includes(element.feeNumber)
    ) {
      return true;
    }
  }

  return false;
};

export const isFromThisMove = (element, oldFees) => {
  if (element && oldFees) {
    if (oldFees?.map((cuota) => cuota.feeNumber)?.includes(element.feeNumber)) {
      return true;
    }
  }

  return false;
};
