import { gql } from "@apollo/client";

export const GET_INSTRUCTORS = gql`
  query getInstructorsResolver {
    getInstructorsResolver {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      phoneNumbers {
        phoneNumber
      }
      email
      addressInfo {
        city
        cp
        street
      }
      createdAt
    }
  }
`;
