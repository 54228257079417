import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Form, Select, Transfer } from "antd";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { GET_COURSE_CONFORMATION } from "../../../graphql/query/Cursos";
import { GET_INSTRUCTORS } from "../../../graphql/query/Instructores";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import {
  ASIGN_INSTRUCTOR,
  ASIGN_STUDENTS,
} from "../../../graphql/mutation/Cursos";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { ErrorMessage } from "../error/ErrorMessage";

const filterOption = (inputValue, option) => {
  let value =
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
    option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  return value;
};

export const ConformacionCurso = () => {
  const {
    drawerContent,
    setDrawerContent,
    messageApi,
    instructores,
    setInstructores,
    alumnos,
    cursos,
    cursosPoll,
    user,
  } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [curso, setCurso] = useState(null);

  const [turno, setTurno] = useState(null);

  const [instructorSeleccionado, setInstructorSeleccionado] = useState(null);

  const [targetKeys, setTargetKeys] = useState([]);

  const [success, setSuccess] = useState({
    instructor: false,
    students: false,
  });

  const [getCourseConformationResolver, { loading, error, data }] =
    useLazyQuery(GET_COURSE_CONFORMATION, {
      fetchPolicy: "network-only",
    });

  const {
    loading: loadingInstructors,
    error: errorInstructors,
    data: dataInstructors,
  } = useQuery(GET_INSTRUCTORS, { fetchPolicy: "network-only" });

  const [
    asignInstructorToCourseResolver,
    { dataInst, loadingInst, errorInst },
  ] = useMutation(ASIGN_INSTRUCTOR, {
    onCompleted: (data) => {
      if (data.asignInstructorToCourseResolver?.status === 200) {
        setSuccess((prevState) => ({
          ...prevState,
          instructor: true,
        }));
      }
    },
  });

  const [
    asignStudentsToCourseResolver,
    { dataStudents, loadingStudents, errorStudents },
  ] = useMutation(ASIGN_STUDENTS, {
    onCompleted: (data) => {
      if (data.asignStudentsToCourseResolver?.status === 200) {
        setSuccess((prevState) => ({
          ...prevState,
          students: true,
        }));
      }
    },
  });

  const getOptions = () => {
    if (instructores?.length > 0) {
      return instructores?.map((instructor) => {
        return {
          value: instructor._id,
          label: instructor.fullName,
        };
      });
    }
  };

  const getAlumnos = () => {
    if (alumnos?.length > 0) {
      let students = alumnos
        .filter((element) => element.status === "ACTIVE")
        ?.map((alumno) => {
          return {
            key: alumno._id,
            title: alumno.fullName,
            description: alumno.dni ? alumno.dni : "",
          };
        });

      return students;
    } else {
      return [];
    }
  };

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const onFinish = (values) => {
    if (instructorSeleccionado) {
      asignInstructorToCourseResolver({
        variables: {
          instructorId: instructorSeleccionado,
          turnId: drawerContent.item?.turno?._id,
        },
      });
      asignStudentsToCourseResolver({
        variables: {
          turnId: drawerContent.item?.turno?._id,
          studentsList: targetKeys,
        },
      });
    } else {
      return messageApi.info("Debe seleccionar un instructor");
    }
  };

  useEffect(() => {
    if (drawerContent?.item) {
      getCourseConformationResolver({
        variables: {
          courseId: drawerContent.item?.curso._id,
        },
      });
    }
  }, [drawerContent]);

  useEffect(() => {
    if (data?.getCourseConformationResolver) {
      setCurso(data.getCourseConformationResolver);
    }
  }, [data]);

  useEffect(() => {
    if (!instructores) {
      setInstructores(dataInstructors?.getInstructorsResolver);
    }
  }, [dataInstructors]);

  useEffect(() => {
    if (curso) {
      let filtroTurno = curso.filter(
        (turno) => turno._id === drawerContent.item.turno._id
      );
      setTurno(filtroTurno[0]);
    }
  }, [curso]);

  useEffect(() => {
    if (turno) {
      setInstructorSeleccionado(turno.conformation?.instructor?._id);
      setTargetKeys(
        turno.conformation?.students?.map((student) => student._id)
      );
    }
  }, [turno]);

  useEffect(() => {
    if (success.instructor && success.students) {
      cursosPoll.start(500);
      setTimeout(() => {
        cursosPoll.stop();
        setDrawerContent({
          visible: false,
          type: "",
          item: {},
        });
      }, 1000);
      messageApi.success("Conformación del curso actualizada");
    }
  }, [success]);

  if (loading || loadingInstructors) return <BlobLoader />;

  if (error) return <ErrorMessage />;

  return (
    <Form
      form={form}
      name="form-conformacion"
      layout="vertical"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        {turno && (
          <Form.Item>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>{turno.name}</p>
          </Form.Item>
        )}
        <Form.Item label="Instructor *">
          {instructores && (
            <Select
              options={getOptions()}
              style={{ width: "100%" }}
              onChange={(v) => setInstructorSeleccionado(v)}
              value={instructorSeleccionado}
              showSearch
              filterOption={filterOption}
            />
          )}
        </Form.Item>
        <Form.Item label="Alumnos">
          <Transfer
            style={{ width: "100%" }}
            dataSource={getAlumnos()}
            showSearch
            onChange={handleChange}
            filterOption={filterOption}
            targetKeys={targetKeys}
            listStyle={{
              width: 256,
              height: 500,
            }}
            locale={{itemUnit: "Alumno", itemsUnit: "Alumnos"}}
            render={(item) => {
              return `${item.title} - ${item.description}`;
            }}
          />
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            disabled={
              !isAvailable(
                "coursesConformation",
                SECCION_PERMISOS.editar,
                user?.rol?.permissions
              )
            }
            loading={loadingStudents}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
