import { MainView } from "../components/layout/mainView/MainView";
import { Profesores } from "../components/layout/profesores/Profesores";

export const ProfesoresView = () => {
  return (
    <MainView>
      <Profesores />
    </MainView>
  );
};
