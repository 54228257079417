import { Cursos } from "../components/layout/cursos/Cursos";
import { MainView } from "../components/layout/mainView/MainView";

export const CursosView = () => {
  return (
    <MainView>
      <Cursos />
    </MainView>
  );
};
