import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { borderColor } from "../../styles";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#e8e8e8",
    borderBottomWidth: 1,
    // alignItems: "center",
    // minHeight: 24,
    fontSize: 11,
    marginTop: 4,
  },
  qty: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
  },
  description: {
    width: "30%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRight: "1px solid #d8d8d8",
    borderRightWidth: 1,
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
    // paddingLeft: 8,
  },
  rate: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
  },
});

export const TableRow = ({ data }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.qty}>{data?.concept?.name}</Text>
      <Text style={styles.description}>{data?.description}</Text>
      <Text style={styles.rate}>{data?.paymentMethod?.name}</Text>
      <Text style={styles.amount}>{`$${thousandFormatter(data?.total)}`}</Text>
    </View>
  );
};
