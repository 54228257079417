import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { borderColor } from "../../styles";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "semibold",
  },
  description: {
    width: "80%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    fontStyle: "semibold",
  },
  total: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
    fontStyle: "semibold",
  },
});

export const TableFooter = ({ data }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{`$${thousandFormatter(data?.total)}`}</Text>
    </View>
  );
};
