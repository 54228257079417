import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { borderColor, pdfStyles } from "../../styles";

export const TableHeader = () => {
  const styles = StyleSheet.create({
    paymentMethod: {
      width: "70%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    amount: {
      width: "30%",
    },
  });

  return (
    <View style={pdfStyles.container}>
      <Text style={styles.paymentMethod}>Concepto</Text>
      <Text style={styles.amount}>Saldo</Text>
    </View>
  );
};
