import { gql } from "@apollo/client";

export const GET_AB_TRANSFERS = gql`
  query getAbTransfersResolver($accountBoxId: String) {
    getAbTransfersResolver(accountBoxId: $accountBoxId) {
      transfersIn {
        _id
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
      transfersOut {
        _id
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
    }
  }
`;

export const GET_TRANSFERS = gql`
  query getTransfersResolver {
    getTransfersResolver {
      _id
      abFrom
      abTo
      transferInput {
        paymentMethodId
        amount
      }
      createdAt
      total
      userId
    }
  }
`;
