import { useContext, useState } from "react";
import "./mainModal.css";
import { Button, Checkbox, DatePicker, Input, Table, Tooltip } from "antd";
import { GlobalContext } from "../../context/GlobalContext";
import dayjs from "dayjs";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { isAvailable } from "../../../utils/isAvailable";
import { FiEye } from "react-icons/fi";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const MainModal = ({ children, setShowModal, showModal }) => {
  const { config, messageApi, cursos, user, setDrawerContent } =
    useContext(GlobalContext);

  const [desde, setDesde] = useState(dayjs());
  const [hasta, setHasta] = useState(dayjs());

  const [selectedAreas, setSelectedAreas] = useState([
    "ESTETICA",
    "PELUQUERIA",
  ]);
  const [resultado, setResultado] = useState(null);

  const ColumnaAcciones = ({ item }) => {
    return (
      <div className="fila-acciones">
        {isAvailable(
          "courses",
          SECCION_PERMISOS.ver,
          user?.rol?.permissions
        ) && (
          <Tooltip title="Ver detalles" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiEye
                className="icono-acciones"
                onClick={(e) => {
                  e.stopPropagation();
                  setDrawerContent({
                    visible: true,
                    type: "Ver Curso",
                    item: item,
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Duración",
      dataIndex: "duration",
      width: "100px",
      key: "duration",
      render: (dataIndex) => {
        return <span>{`${dataIndex} clases`}</span>;
      },
    },
    {
      title: "Categoría",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Aula",
      dataIndex: "classroomId",
      key: "classroomId",
      render: (data) => {
        return config?.classrooms?.find((element) => element._id === data)
          ?.name;
      },
    },
    {
      title: "Inscriptos",
      dataIndex: "turnsConformation",
      width: "150px",
      key: "turnsConformation",
      render: (dataIndex) => {
        let count = 0;
        dataIndex.forEach((turn) => {
          count += turn.enrollmentCount;
        });
        return (
          <p className="number-ff number-center">
            {thousandFormatter(count, true)}
          </p>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "100px",
      key: "",
      render: (dataIndex, item) => <ColumnaAcciones item={item} />,
    },
  ];

  const handleBuscar = () => {
    if (selectedAreas?.length < 1) {
      messageApi.info("Debe seleccionar una categoria");
    }

    if (!desde || !hasta) {
      messageApi.info(
        "Es necesario que ingrese ambas fechas para buscar la disponibilidad"
      );
    }

    const dateformat = "YYYY-MM-DD";

    const [yearA, monthA, dayA] = dayjs(desde).format(dateformat).split("-");
    const startDate = `${dayA}-${monthA}`;

    const [yearB, monthB, dayB] = dayjs(hasta).format(dateformat).split("-");
    const endDate = `${dayB}-${monthB}`;

    let filtro = cursos?.filter((element) => {
      const [day, month, year] = element.startDate?.split("-");
      const currentDateString = `${day}-${month}`;

      if (currentDateString >= startDate && currentDateString <= endDate) {
        return true;
      } else {
        return false;
      }
    });

    setResultado(filtro);
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Nombre turno",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Inscriptos",
        dataIndex: "enrollmentCount",
        width: "150px",
        key: "enrollmentCount",
        render: (dataIndex, item) => {
          let cupo =
            config?.classrooms?.find(
              (element) => element._id === item?.classroomId
            )?.maxCapacity || 0;

          return (
            <p className="number-ff number-center">
              {`${thousandFormatter(dataIndex, true)} / ${cupo}`}
            </p>
          );
        },
      },
    ];

    const data = record.turnsConformation;

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={"_id"}
        size="small"
      />
    );
  };

  return (
    <div
      className="modal-wrapper"
      onClick={() => {
        setShowModal(false);
      }}
    >
      {!resultado ? (
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h3>Buscar disponibilidad</h3>
          <span className="horizontal">
            <div className="columna" style={{ position: "relative" }}>
              <p>Desde</p>

              <DatePicker
                format={DATE_FORMAT}
                value={desde}
                onChange={(date, dateString) => setDesde(date)}
              />
            </div>
            <div className="columna" style={{ position: "relative" }}>
              <p>Hasta</p>
              <DatePicker
                format={DATE_FORMAT}
                value={hasta}
                onChange={(date, dateString) => setHasta(date)}
      
              />
            </div>
          </span>
          <span style={{ marginRight: "auto" }}>
            <h4 style={{ marginTop: "8px" }}>Categorías</h4>
            <Checkbox.Group
              onChange={(v) => setSelectedAreas(v)}
              value={selectedAreas}
            >
              <span className="columna">
                <Checkbox value="ESTETICA">ESTETICA</Checkbox>
                <Checkbox value="PELUQUERIA">PELUQUERIA</Checkbox>
              </span>
            </Checkbox.Group>
          </span>
          <span
            className="horizontal space-between"
            style={{ marginTop: "8px" }}
          >
            <Button className="btn-guardar" onClick={() => handleBuscar()}>
              Buscar
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancelar</Button>
          </span>
        </div>
      ) : (
        <div
          className="modal-content"
          style={{ width: "clamp(400px, 900px, 80%)" }}
          onClick={(e) => e.stopPropagation()}
        >
          <span className="horizontal space-between">
            <p>Disponibilidad</p>
            <span className="horizontal" style={{ width: "fit-content" }}>
              <Button className="btn-guardar" onClick={() => setResultado()}>
                Volver
              </Button>
              <Button onClick={() => setShowModal(false)}>Cerrar</Button>
            </span>
          </span>
          <Table
            columns={columns}
            className="table-wrapper"
            style={{ width: "100%" }}
            dataSource={resultado}
            rowKey={"_id"}
            size="small"
            pagination={{
              pageSize: 8,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} cursos`,
              showSizeChanger: false,
            }}
            expandable={{
              expandedRowRender,
              columnWidth: "4%",
              expandRowByClick: true,
              showExpandColumn: false,
            }}
          />
        </div>
      )}
    </div>
  );
};
