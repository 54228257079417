import { Button, Form, Transfer } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ACCOUNTING_BOXES_BY_USER_ID } from "../../../graphql/query/Cajas";
import { MANAGE_ACCOUNTING_BOXES } from "../../../graphql/mutation/Cajas";
import { ErrorMessage } from "../error/ErrorMessage";

export const GestionarCajas = () => {
  const { setDrawerContent, drawerContent, messageApi, config, user } =
    useContext(GlobalContext);
    
  const [form] = Form.useForm();

  const [getAccountingBoxesByUserResolver, { loading, error, data }] =
    useLazyQuery(GET_ACCOUNTING_BOXES_BY_USER_ID, {
      fetchPolicy: "network-only",
    });

  const [
    manageUserBoxRelationsResolver,
    { data: dataManage, loading: loadingManage, error: errorManage },
  ] = useMutation(MANAGE_ACCOUNTING_BOXES, {
    onCompleted: (data) => {
      if (data?.manageUserBoxRelationsResolver?.status === 200) {
        messageApi.success("Se asociaron cajas correctamente");
        setDrawerContent({
          visible: false,
          item: {},
          type: "",
        });

        //! ACTUALIZAR
      } else {
        messageApi.error("Hubo un error al asociar cajas al usuario");
      }
    },
    onError: (error) => {
      messageApi.error("Hubo un error al asociar cajas al usuario");
    },
  });

  const [targetKeys, setTargetKeys] = useState([]);

  const getCajas = () => {
    if (config.accountingBoxes?.length > 0) {
      let cajas = config.accountingBoxes.map((caja) => {
        return {
          key: caja._id,
          title: caja.name,
        };
      });

      return cajas;
    } else {
      return [];
    }
  };

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const onFinish = (v) => {
    let relations = [];
    let cajasAsociadas = [];
    if (data.getAccountingBoxesByUserResolver.length > 0) {
      data.getAccountingBoxesByUserResolver.forEach((element) => {
        relations.push({
          idRelacion: element._id,
          idCaja: element.accountBoxData?._id,
        });

        cajasAsociadas.push(element.accountBoxData?._id);
      });
    }

    let activateBoxes = [];
    let deactivateBoxes = [];

    targetKeys.forEach((target) => {
      if (!cajasAsociadas.includes(target)) {
        activateBoxes.push(target);
      }
    });

    cajasAsociadas.forEach((element) => {
      if (!targetKeys.includes(element)) {
        let relacion = relations.find((obj) => obj.idCaja === element);

        deactivateBoxes.push(relacion.idRelacion);
      }
    });

    manageUserBoxRelationsResolver({
      variables: {
        userId: drawerContent?.item?._id,
        relationsToActivate: activateBoxes,
        relationsToDeactivate: deactivateBoxes,
      },
    });
  };

  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  useEffect(() => {
    if (drawerContent?.item?._id) {
      getAccountingBoxesByUserResolver({
        variables: { userId: drawerContent?.item?._id },
      });
    }
  }, [user, drawerContent]);

  useEffect(() => {
    if (Array.isArray(data?.getAccountingBoxesByUserResolver)) {
      setTargetKeys(
        data.getAccountingBoxesByUserResolver.map(
          (element) => element.accountBoxData?._id
        )
      );
    }
  }, [data]);

  if (error) return <ErrorMessage />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="gestionarCajas"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item>
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            {drawerContent?.item?.name}
          </p>
        </Form.Item>
        <Form.Item label="Cajas">
          {loading ? (
            <BlobLoader />
          ) : (
            <Transfer
              style={{ width: "100%" }}
              dataSource={getCajas()}
              showSearch
              filterOption={filterOption}
              onChange={handleChange}
              targetKeys={targetKeys}
              listStyle={{
                width: 256,
                height: 600,
              }}
              locale={{ itemUnit: "Caja", itemsUnit: "Cajas" }}
              render={(item) => {
                return `${item.title}`;
              }}
            />
          )}
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loadingManage}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
