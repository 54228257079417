import { gql } from "@apollo/client";

export const CREATE_INSTRUCTOR = gql`
  mutation NewInstructor($instructorInput: instructorInput) {
    newInstructorResolver(instructorInput: $instructorInput) {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      phoneNumbers {
        phoneNumber
      }
      email
      addressInfo {
        street
        cp
        city
      }
      createdAt
    }
  }
`;

export const UPDATE_INSTRUCTOR = gql`
  mutation UpdateInstructorResolver(
    $instructorInput: instructorInput
    $instructorId: String
  ) {
    updateInstructorResolver(
      instructorInput: $instructorInput
      instructorId: $instructorId
    ) {
      _id
      fullName
      dni
      nationality
      gender
      birthDay
      phoneNumbers {
        phoneNumber
      }
      email
      addressInfo {
        street
        cp
        city
      }
      createdAt
    }
  }
`;

export const DELETE_INSTRUCTOR = gql`
  mutation deleteInstructorResolver($instructorId: String) {
    deleteInstructorResolver(instructorId: $instructorId) {
      message
      status
    }
  }
`;
