import { useLazyQuery } from "@apollo/client";
import { GET_COURSES } from "../../graphql/query/Cursos";
import { useEffect } from "react";

export const useCursos = (
  setCursos,
  messageApi,
  setLoadingGlobal,
  setCursosPoll,
  user
) => {
  const [
    getCoursesResolver,
    { loading, error, data, startPolling, stopPolling },
  ] = useLazyQuery(GET_COURSES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (user?._id 
      // && user?.type === "SYSTEM"
      ) {
      getCoursesResolver();
    }
  }, [user]);

  useEffect(() => {
    setLoadingGlobal((prevState) => {
      let object = { ...prevState };

      object.cursos = loading;
      return object;
    });
  }, [loading]);

  useEffect(() => {
    if (error) {
      messageApi.error("Error al obtener cursos");
    }
  }, [error]);

  useEffect(() => {
    if (Array.isArray(data?.getCoursesResolver)) {
      setCursos(data.getCoursesResolver);
    }
  }, [data]);

  useEffect(() => {
    setCursosPoll({
      start: startPolling,
      stop: stopPolling,
    });
  }, []);

  return;
};
