import { Button, Input, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GlobalContext } from "../../../context/GlobalContext";
import { BlobLoader } from "../../../ui/loader/BlobLoader";
// import { makeFloat, thousandFormatter } from "../../../utils/thousandFormatter";

import { NEW_TRANSFER } from "../../../../graphql/mutation/Transferencias";
import { getTotalMetodosDePagos } from "../../../../utils/getTotalMetodosDePagos";
import { thousandFormatter } from "../../../../utils/thousandFormatter";
import { GET_AB_TRANSFERS } from "../../../../graphql/query/Transferencias";

export const RecibirFondos = ({ showModal, setShowModal, fetchCajaData }) => {
  const { config, messageApi } = useContext(GlobalContext);

  const [getAbTransfersResolver, { data, loading, error }] = useLazyQuery(
    GET_AB_TRANSFERS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    newTransferResolver,
    { data: dataNew, loading: loadingNew, error: errorNew },
  ] = useMutation(NEW_TRANSFER, {
    fetchPolicy: "network-only",
  });

  const [selectedABFrom, setSelectedABFrom] = useState(null);
  const [selectedABTo, setSelectedABTo] = useState(null);

  const [resultados, setResultados] = useState([]);

  const abOptions = useMemo(() => {
    if (config?.accountingBoxes && Array.isArray(config?.accountingBoxes)) {
      return config.accountingBoxes.map((caja) => {
        return {
          value: caja._id,
          label: caja.name,
          isTesoreria: caja.isTesoreria,
        };
      });
    }

    return [];
  }, [config]);

  const handleGuardarTransferencia = () => {
    const { item, type } = showModal;
    if (!item?.abHistoryId) {
      messageApi.info("Es necesario un id de cierre");
      return;
    }

    if (!selectedABFrom || !selectedABTo) {
      messageApi.info("Necesita seleccionar caja de origen y destino");
      return;
    }

    if (selectedABFrom === selectedABTo) {
      messageApi.info("Cajas de destino y de origen no pueden ser iguales");
      return;
    }

    if (resultados?.length < 1) {
      messageApi.info("No hay conceptos que transferir");
      return;
    }

    if (
      resultados.some((concepto) => {
        if (concepto.amount == null) {
          messageApi.info(`${concepto?.name} necesita un valor`);
          return true;
        }

        if (isNaN(concepto.amount)) {
          messageApi.info(`${concepto?.name} no tiene un número`);
          return true;
        }

        if (concepto.amount < 0) {
          messageApi.info(
            `${concepto?.name} no puede contener números negativos`
          );
          return true;
        }

        if (concepto.amount > concepto.total && concepto.amount != 0) {
          messageApi.info(
            `${concepto?.name} no puede ser superior al valor total`
          );
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    const filteredPayment = resultados
      .map((element) => {
        return { paymentMethodId: element._id, amount: element.amount || 0 };
      })
      .filter((concepto) => concepto.amount !== 0);

    if (filteredPayment?.length < 1) {
      messageApi.info("No se permite crear transferencias sin valor");
      return;
    }

    let obj = {
      abFrom: selectedABFrom,
      abTo: selectedABTo,
      transferInput: filteredPayment,
      total: filteredPayment?.reduce((result, item) => {
        return (result += parseFloat(item.amount));
      }, 0),
      abHistoryId: item?.abHistoryId,
    };

    newTransferResolver({ variables: obj })
      .then((res) => {
        if (res?.data?.newTransferResolver?.status === 200) {
          messageApi.success("Transferencia creada correctamente");

          fetchCajaData();
          setShowModal({ open: false, type: null, item: null });
        } else {
          messageApi.error("Ocurrió un error al crear una transferencia");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al crear una transferencia");
      });
  };

  const handleValues = (value, id) => {
    if (id) {
      let parsedValue = parseFloat(value?.toString()?.replace(",", ".")) || 0;

      setResultados((prevState) => {
        return prevState.map((concepto) => {
          if (concepto._id === id) {
            return { ...concepto, amount: parsedValue };
          } else {
            return concepto;
          }
        });
      });
    }
  };

  const conceptos = useMemo(() => {
    return getTotalMetodosDePagos(data?.getAbTransfersResolver, config);
  }, [data, config]);

  useEffect(() => {
    setResultados(
      conceptos?.map((element) => {
        return { ...element, amount: 0 };
      })
    );
  }, [conceptos]);

  useEffect(() => {
    if (selectedABFrom) {
      getAbTransfersResolver({ variables: { accountBoxId: selectedABFrom } });
    }
  }, [selectedABFrom]);

  useEffect(() => {
    const { type, item } = showModal;

    if (type === "new" && item) {
      const tesoreriaId = abOptions?.find(
        (element) => element.isTesoreria
      )?.value;

      setSelectedABFrom(tesoreriaId || null);

      setSelectedABTo(item?.accountBoxId || null);
    }
  }, [showModal, abOptions]);

  return (
    <div className="modal-transferencia-wrapper">
      <span className="horizontal gap16" style={{ marginTop: 8 }}>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja origen</p>
          <Select
            style={{ width: 250 }}
            options={abOptions || []}
            value={selectedABFrom}
            disabled
            placeholder="Seleccionar caja origen"
          />
        </span>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja destino</p>
          <Select
            style={{ width: 250 }}
            options={
              abOptions?.filter((element) => {
                return element.value !== selectedABFrom;
              }) || []
            }
            disabled
            value={selectedABTo}
            onChange={(v) => setSelectedABTo(v)}
            placeholder="Seleccionar caja destino"
          />
        </span>
      </span>
      {loading ? (
        <BlobLoader />
      ) : (
        <div className="concepts-grid">
          <span
            className="transferencia-conceptos-fila"
            style={{ marginTop: 16, fontWeight: 500 }}
          >
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Método de pago
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Disponible
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              A transferir
            </p>
          </span>
          {resultados?.map((concepto) => {
            return (
              <span
                className="transferencia-conceptos-fila"
                key={concepto?._id}
              >
                <p className="transferencia-columna">{concepto?.name}</p>
                <p
                  className="transferencia-columna  number-ff"
                  style={{ textAlign: "right" }}
                >
                  {thousandFormatter(concepto?.total)}
                </p>
                <span className="transferencia-columna">
                  <Input
                    type="number"
                    value={concepto?.amount}
                    onChange={(v) => handleValues(v.target.value, concepto._id)}
                  />
                </span>
              </span>
            );
          })}
        </div>
      )}
      <div
        className="horizontal gap24"
        style={{
          marginLeft: "auto",
          width: "fit-content",
          marginTop: 16,
        }}
      >
        <Button
          className="btn-guardar"
          onClick={handleGuardarTransferencia}
          loading={loadingNew}
        >
          Guardar
        </Button>
        <Button
          className="btn-cancelar"
          onClick={() => setShowModal({ open: false, type: null, item: null })}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
