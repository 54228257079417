import { useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { UPDATE_STUDENT } from "../../../graphql/mutation/Alumnos";
import { getCountries } from "../../../utils/getCountries";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import { getCities } from "../../../utils/getCities";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const UpdateAlumno = ({ edit = true }) => {
  const { drawerContent, setDrawerContent, messageApi, config } =
    useContext(GlobalContext);
  const [form] = Form.useForm();

  const [checked, setChecked] = useState(false);

  const [updateStudentResolver, { data, loading, error }] = useMutation(
    UPDATE_STUDENT,
    {
      onCompleted: (data) => {
        if (data?.updateStudentResolver) {
          if (data.updateStudentResolver?.fullName) {
            // let array = [...alumnos];
            // array.push(data.updateStudentResolver);

            // setAlumnos(array);

            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
            messageApi.success("Alumno actualizado correctamente");
          }
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al actualizar un alumno!");
      },
    }
  );

  const onFinish = (values) => {
    let student;

    if (values) {
      let addressInfo = {};
      if (values.street) {
        addressInfo.street = values.street;
      }
      if (values.cp) {
        addressInfo.cp = values.cp;
      }
      if (values.city) {
        addressInfo.city = values.city;
      }

      let phoneNumbers = [];
      if (values.phoneNumber) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber });
      }
      if (values.phoneNumber2) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber2 });
      }

      student = {
        fullName: values.fullName ? values.fullName : "",
        dni: values.dni ? values.dni : "",
        birthDay: values.birthDay
          ? dayjs(values.birthDay).format("DD-MM-YYYY")
          : "",
        email: values.email ? values.email : "",
        nationality: values.nationality ? values.nationality : "AR",
        gender: values.gender ? values.gender : "femenino",
        medicalRecord: checked,
        addressInfo: addressInfo,
        phoneNumbers: phoneNumbers,
      };

      updateStudentResolver({
        variables: {
          studentId: drawerContent.item?._id,
          studentInput: student,
        },
      });
    }
  };

  useEffect(() => {
    if (drawerContent?.item) {
      let data = drawerContent.item;

      form.setFieldsValue({
        fullName: data.fullName,
        dni: data.dni,
        birthDay: dayjs(data.birthDay, "DD-MM-YYYY"),
        phoneNumber: data.phoneNumbers[0].phoneNumber,
        phoneNumber2: data.phoneNumbers[1]?.phoneNumber,
        email: data.email,
        nationality: data.nationality ? data.nationality : "AR",
        city: data.addressInfo?.city,
        street: data.addressInfo?.street,
        cp: data.addressInfo?.cp,
        gender: data.gender,
        medicalRecord: data.medicalRecord,
      });

      if (data.medicalRecord === true) {
        setChecked(true);
      }
    }
  }, [drawerContent]);

  if (loading) return <BlobLoader />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearAlumno"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      disabled={!edit}
      className="form-layout"
    >
      <div className={edit ? "form-content" : "form-content-details"}>
        <Form.Item
          label="Nombre completo *"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <span className="horizontal">
          <Form.Item
            className="form-item-half-row"
            label="DNI *"
            name="dni"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className="form-item-half-row"
            label="Fecha de nacimiento *"
            name="birthDay"
            initialValue={dayjs()}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            className="form-item-half-row"
            label="Teléfono *"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input
              type="tel"
              // pattern=""
            />
          </Form.Item>
          <Form.Item
            className="form-item-half-row"
            label="Teléfono alternativo"
            name="phoneNumber2"
            rules={[
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input
              type="tel"
              // pattern=""
            />
          </Form.Item>
        </span>
        <Form.Item
          label="Email *"
          name="email"
          rules={[
            { required: true, message: "Campo requerido" },
            {
              type: "email",
              message: "Ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input
            type="email"
            // pattern=""
          />
        </Form.Item>
        <Form.Item initialValue="AR" label="Nacionalidad" name="nationality">
          <Select
            options={getCountries()}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="city" label="Ciudad">
          <Select
            options={getCities(config?.locations)}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="cp" label="Código postal">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="street" label="Domicilio">
          <Input type="text" />
        </Form.Item>
        <Form.Item label="Género" name="gender" initialValue="femenino">
          <Select
            options={[
              { value: "femenino", label: "Femenino" },
              { value: "masculino", label: "Masculino" },
              { value: "otro", label: "Otro" },
            ]}
          />
        </Form.Item>
        <Form.Item label="Cobertura médica" name="medicalRecord">
          <label className="align-row">
            <Input
              type="checkbox"
              id="coberturaCheck"
              style={{ width: "20px", accentColor: "var(--background-color)" }}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />{" "}
            ¿Presentó?
          </label>
        </Form.Item>
      </div>

      {edit && (
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() => {
                setDrawerContent({
                  visible: false,
                  content: "",
                  item: {},
                });
              }}
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      )}
    </Form>
  );
};
