import { Dashboard } from "../components/layout/dashboard/Dashboard";
import { MainView } from "../components/layout/mainView/MainView";

export const DashboardView = () => {
  return (
    <MainView>
      <Dashboard />
    </MainView>
  );
};
