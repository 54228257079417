import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";
import { CREATE_ACCOUNTING_BOX } from "../../../../graphql/mutation/Config";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";

export const NewCaja = ({ drawerData, setDrawerData }) => {
  const { messageApi, configPoll } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const closeDrawer = () => {
    setDrawerData({ open: false, item: null, type: "" });
  };

  const [newAccountingBoxResolver, { data, loading, error }] = useMutation(
    CREATE_ACCOUNTING_BOX,
    {
      onCompleted: (data) => {
        if (data?.newAccountingBoxResolver?.status === 200) {
          messageApi.success("Caja agregada correctamente");
          form.setFieldsValue({
            name: "",
            description: "",
          });

          configPoll.start(500);
          setTimeout(() => {
            configPoll.stop();
          }, 1000);

          closeDrawer();
        } else {
          messageApi.error("Ocurrió un error al agregar una caja");
        }
      },
      onError: (error) => {
        console.log("error", error);
        messageApi.error("Ocurrió un error al agregar una caja");
      },
    }
  );

  const onFinish = (v) => {
    let name = v.name?.trim().toUpperCase() || "";
    let description = v.description?.trim() || "";

    if (name.length < 3) {
      return messageApi.info("El nombre debe contener al menos 3 caracteres");
    }

    newAccountingBoxResolver({
      variables: {
        accountingBoxInput: { name: name, description: description },
      },
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: "100%" }}
      onFinish={onFinish}
      name="crearAlumno"
      requiredMark={false}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item label="Nombre" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Descripción" name="description">
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button className="btn-cancelar" size="large" onClick={closeDrawer}>
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
