import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";
import { CREATE_CLASSROOM } from "../../../graphql/mutation/Config";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

export const CreateClassRoom = ({ setShowNewAula }) => {
  const { messageApi, configPoll } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [newClassroomResolver, { data, loading, error }] = useMutation(
    CREATE_CLASSROOM,
    {
      onCompleted: (data) => {
        if (data?.newClassroomResolver?.status === 200) {
          messageApi.success("Aula agregada correctamente");

          configPoll.start(500);
          setTimeout(() => {
            configPoll.stop();
          }, 1000);

          form.setFieldsValue({
            name: "",
            maxCapacity: "",
          });

          setShowNewAula(false);
        } else {
          messageApi.error("Ocurrió un error al agregar un aula");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar un aula");
      },
    }
  );

  const onFinish = (v) => {
    let name = v.name?.trim() || "";
    let maxCapacity = v.maxCapacity?.trim() || "";

    if (!name) {
      return messageApi.info("El nombre debe contener al menos 3 caracteres");
    }

    if (!maxCapacity) {
      return messageApi.info("Debe ingresar un número como máxima capacidad");
    }

    try {
      maxCapacity = Number(v.maxCapacity);
      if (!Number.isInteger(maxCapacity)) {
        return messageApi.info("Por favor, ingrese un número entero");
      }

      if (maxCapacity < 1) {
        return messageApi.info("Por favor ingrese un número positivo");
      }
    } catch (error) {
      return messageApi.info("Por favor, ingrese un número entero");
    }

    newClassroomResolver({
      variables: { classroomInput: { name: name, maxCapacity: maxCapacity } },
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: "100%" }}
      name="createAula"
      onFinish={(v) => onFinish(v)}
    >
      <Form.Item label="Nombre" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Cupo" name="maxCapacity">
        <Input type="number" />
      </Form.Item>
      <Form.Item>
        <div className="botones-wrapper-content" style={{ marginTop: 24 }}>
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setShowNewAula(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
