import { useContext, useEffect, useState } from "react";
import { Header } from "../components/layout/student/Header";
import { GlobalContext } from "../components/context/GlobalContext";
import { StudentContext } from "../components/context/StudentContext";
import { useLazyQuery } from "@apollo/client";
import {
  GET_STUDENT_COURSES,
  GET_STUDENT_INFO,
} from "../graphql/query/Alumnos";

export const StudentView = ({ children }) => {
  const { user, messageApi } = useContext(GlobalContext);
  // const [] = useState(null);

  const [getCoursesByStudentIdResolver, { data, loading, error }] =
    useLazyQuery(GET_STUDENT_COURSES);

  const [
    getStudentInformationResolver,
    { data: dataInfo, loading: loadingInfo, error: errorInfo },
  ] = useLazyQuery(GET_STUDENT_INFO);

  useEffect(() => {
    if (user?._id) {
      getCoursesByStudentIdResolver({ variables: { studentId: user._id } });

      getStudentInformationResolver({ variables: { studentId: user._id } });
    }
  }, [user]);

  return (
    <StudentContext.Provider
      value={{ data, loading, error, dataInfo, loadingInfo, errorInfo }}
    >
      <div className="student-view-wrapper">
        <Header />
        {children}
      </div>
    </StudentContext.Provider>
  );
};
