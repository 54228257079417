import { useMutation, useQuery } from "@apollo/client";
import { Button, Input, Popover, Table, Tooltip } from "antd";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { FiEdit3, FiEye } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { DELETE_INSTRUCTOR } from "../../../graphql/mutation/Instructores";
import { GET_INSTRUCTORS } from "../../../graphql/query/Instructores";
import { deleteArrayElement } from "../../../utils/deleteArrayElement";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { useInstructores } from "../../hooks/useInstructores";
import { AiOutlineSearch } from "react-icons/ai";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";

export const Profesores = () => {
  const {
    setDrawerContent,
    messageApi,
    instructores,
    setInstructores,
    loadingGlobal,
    user,
  } = useContext(GlobalContext);

  const [idBorrar, setIdBorrar] = useState(null);

  const [
    deleteInstructorResolver,
    { loading: loadingDelete, error: errorDelete, data: dataDelete },
  ] = useMutation(DELETE_INSTRUCTOR, {
    onCompleted: (data) => {
      if (data?.deleteInstructorResolver?.status === 200) {
        setInstructores(deleteArrayElement([...instructores], idBorrar));

        messageApi.success(data?.deleteInstructorResolver?.message);
      } else {
        messageApi.error("Hubo un error al eliminar el Instructor");
      }
    },
    onError: (error) => {
      messageApi.error("Hubo un error de conexión al eliminar el Instructor");
    },
  });

  const deleteInstructor = (item) => {
    if (item._id) {
      setIdBorrar(item._id);
      deleteInstructorResolver({ variables: { instructorId: item._id } });
    }
  };

  const ColumnaAcciones = ({ item }) => {
    const [showPopover, setShowPopover] = useState(false);

    return (
      <div className="fila-acciones">
        {isAvailable(
          "instructors",
          SECCION_PERMISOS.ver,
          user?.rol?.permissions
        ) && (
          <Tooltip title="Ver detalles" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiEye
                className="icono-acciones"
                onClick={() => {
                  setDrawerContent({
                    visible: true,
                    type: "Ver Instructor",
                    item: item,
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
        {isAvailable(
          "instructors",
          SECCION_PERMISOS.editar,
          user?.rol?.permissions
        ) && (
          <Tooltip title="Editar" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiEdit3
                className="icono-acciones"
                onClick={() => {
                  setDrawerContent({
                    visible: true,
                    type: "Editar Instructor",
                    item: item,
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
        {isAvailable(
          "instructors",
          SECCION_PERMISOS.eliminar,
          user?.rol?.permissions
        ) && (
          <Popover
            title={
              <div className="content-popover">
                <span className="popover-texto">{`¿Realmente desea desactivar el instructor ${item.fullName}?`}</span>
                <div className="botones-wrapper-content">
                  <Button
                    className="btn-guardar"
                    onClick={() => deleteInstructor(item)}
                    loading={loadingDelete}
                  >
                    Desactivar
                  </Button>
                  <Button onClick={() => setShowPopover(false)}>
                    Cancelar
                  </Button>
                </div>
              </div>
            }
            trigger="click"
            placement="topLeft"
            open={showPopover}
          >
            <Tooltip title="Desactivar" placement="topRight">
              <span style={{ height: "20px" }}>
                <MdOutlineDeleteOutline
                  className="icono-acciones"
                  onClick={() => setShowPopover(true)}
                />
              </span>
            </Tooltip>
          </Popover>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar nombre"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.fullName.toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "DNI",
      dataIndex: "dni",
      width: "100px",
      key: "dni",
      render: (dataIndex) => (
        <p className="number-ff">{thousandFormatter(dataIndex, true)}</p>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar DNI"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.dni.toLowerCase().startsWith(value.toLowerCase()),
      sorter: (a, b) => a.dni.localeCompare(b.dni),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Teléfono",
      dataIndex: "phoneNumbers",
      width: "120px",
      key: "phoneNumbers",
      render: (dataIndex) => (
        <div className="phone-number-layout">
          {dataIndex?.map((phoneNumber) => {
            return (
              <p key={phoneNumber?.phoneNumber} className="number-ff">
                {phoneNumber?.phoneNumber}
              </p>
            );
          })}
        </div>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "140px",
      key: "",
      render: (dataIndex, item) => <ColumnaAcciones item={item} />,
    },
  ];

  const handleInstructor = () => {
    setDrawerContent({
      visible: true,
      type: "Agregar Instructor",
      item: {},
    });
  };

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Instructores</p>
        {isAvailable(
          "instructors",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <Button
            style={{ backgroundColor: "var(--complementary-color)" }}
            onClick={() => handleInstructor()}
          >
            Nuevo instructor
          </Button>
        )}
      </div>
      <div className="modulo-content">
        {loadingGlobal.instructores ? (
          <BlobLoader />
        ) : (
          <Table
            className="table-wrapper"
            columns={columns}
            dataSource={instructores}
            rowKey={"_id"}
            size="small"
            pagination={{
              pageSize: 10,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} instructores`,
              showSizeChanger: false,
            }}
          />
        )}
      </div>
    </div>
  );
};
