import { gql } from "@apollo/client";

export const LOGIN_ADMIN = gql`
  mutation loginAuthentication($loginAuthInput: loginAuthInput) {
    loginAuthenticationResolver(loginAuthInput: $loginAuthInput) {
      message
      status
    }
  }
`;

export const LOGIN_STUDENT = gql`
  mutation studentLoginResolver($dni: String, $password: String) {
    studentLoginResolver(dni: $dni, password: $password) {
      status
      message
    }
  }
`;
