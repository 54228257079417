import { Cajas } from "../components/layout/finanzas/cajas/Cajas";
import { MainView } from "../components/layout/mainView/MainView";

export const CajasView = () => {
  return (
    <MainView>
      <Cajas />
    </MainView>
  );
};
