import { gql } from "@apollo/client";

export const USER_PERMISSIONS_SUB = gql`
  subscription userPermissionsSubs {
    userPermissionsSubs {
      logout
      permission {
        permissionId
        data {
          permissions {
            students
            instructors
            courses
            coursesSchedule
            coursesConformation
            moves
            settings
            users
            stats
          }
          name
        }
      }
      user {
        userId
        data {
          _id
          name
          username
          email
          password
          createdAt
          lastLoggedIn
          rol {
            _id
            name
            permissions {
              students
              instructors
              courses
              coursesSchedule
              coursesConformation
              moves
              settings
              users
              stats
            }
          }
        }
      }
    }
  }
`;
