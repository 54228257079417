import { View } from "@react-pdf/renderer";
import { pdfStyles } from "../../styles";
import { TableRow } from "./TableRow";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";

export const ItemsTable = ({ data, dataCierre }) => {
  return (
    <View style={pdfStyles.tableContainer}>
      <TableHeader />
      <TableRow data={data} />
      <TableFooter data={data} />
    </View>
  );
};
