import { gql } from "@apollo/client";

export const GET_BALANCE = gql`
  query getBalanceResolver($dateFrom: String, $dateTo: String) {
    getBalanceResolver(dateFrom: $dateFrom, dateTo: $dateTo) {
      total
    }
  }
`;

export const GET_BALANCE_BY_CATEGORY = gql`
  query getBalanceByAccountBoxResolver($dateFrom: String, $dateTo: String) {
    getBalanceByAccountBoxResolver(dateFrom: $dateFrom, dateTo: $dateTo) {
      total
      accountBoxId
      accountBoxName
    }
  }
`;

export const GET_MOVES = gql`
  query GetAccountingBoxMovesResolver {
    getAccountingBoxMovesResolver {
      _id
      accountingBox
      moves {
        _id
        operation
        description
        date
        total
        createdAt
        abHistoryId
        concept {
          _id
          name
          description
        }
        currency {
          _id
          name
          symbol
        }
        area {
          _id
          name
          description
        }
        feeDetail {
          courseId
          fees {
            feeNumber
            value
          }
        }
        payment {
          paymentMethodId
          total
        }
        studentId
      }
    }
  }
`;

export const GET_COURSE_MOVES_BY_STUDENT_ID = gql`
  query getCourseMovesByStudentId($studentId: String, $courseId: String) {
    getCourseMovesByStudentId(studentId: $studentId, courseId: $courseId) {
      studentId
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
    }
  }
`;

export const GET_MOVES_FOR_REPORT = gql`
  query getMovesForReportResolver(
    $dateFrom: Float
    $dateTo: Float
    $areaId: ID
    $accountBoxId: ID
    $conceptId: ID
    $paymentMethodId: ID
    $studentId: ID
  ) {
    getMovesForReportResolver(
      dateFrom: $dateFrom
      dateTo: $dateTo
      areaId: $areaId
      accountBoxId: $accountBoxId
      conceptId: $conceptId
      paymentMethodId: $paymentMethodId
      studentId: $studentId
    ) {
      _id
      operation
      description
      date
      total
      createdAt
      abHistoryId
      concept {
        _id
        name
        description
      }
      currency {
        _id
        name
        symbol
      }
      area {
        _id
        name
        description
      }
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
      payment {
        paymentMethodId
        total
      }
      accountingBox {
        _id
        name
      }
      studentId
    }
  }
`;
