import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation NewUserResolver($userInput: userInput, $permissionId: String) {
    newUserResolver(userInput: $userInput, permissionId: $permissionId) {
      _id
      name
      username
      email
      createdAt
      permissions {
        name
        _id
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation newPermissionProfileResolver($permissionInput: permissionInput) {
    newPermissionProfileResolver(permissionInput: $permissionInput) {
      _id
      name
      permissions {
        students
        instructors
        courses
        coursesSchedule
        coursesConformation
        moves
        settings
        users
        stats
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updatePermissionProfileResolver(
    $permissionInput: permissionInput
    $permissionId: String
  ) {
    updatePermissionProfileResolver(
      permissionInput: $permissionInput
      permissionId: $permissionId
    ) {
      message
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserResolver($userId: String, $userInput: userInput) {
    updateUserResolver(userId: $userId, userInput: $userInput) {
      _id
      name
      username
      email
      password
      createdAt
      permissions {
        _id
        name
        createdAt
      }
    }
  }
`;
