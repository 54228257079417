import { Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import { FiX } from "react-icons/fi";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ACCOUTING_BOXES_HISTORY } from "../../../../../graphql/query/Cajas";
import { DetalleCierre } from "./DetalleCierre";
import { AccionesCierres } from "./AccionesCierres.jsx";

const columns = (setSelectedCierre, fetchCajaData) => {
  return [
    {
      title: "Fecha de apertura",
      dataIndex: "dateFrom",
      key: "dateFrom",
      width: 180,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">{dayjs(data, "x").format("DD/MM/YYYY")}</p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Fecha de cierre",
      dataIndex: "dateTo",
      key: "dateTo",
      width: 180,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">{dayjs(data, "x").format("DD/MM/YYYY")}</p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (data) => (
        <Tag color={data === "OPEN" ? "green" : "volcano"}>
          {data === "OPEN" ? "Abierto" : "Cerrado"}
        </Tag>
      ),
    },
    {
      title: "Saldo apertura",
      dataIndex: "openingTotal",
      key: "openingTotal",
      align: "right",
      render: (data) => (
        <p className="number-ff" style={{ color: "#00A150" }}>
          {thousandFormatter(data)}
        </p>
      ),
    },

    {
      title: "Saldo cierre",
      dataIndex: "closingTotal",
      key: "closingTotal",
      align: "right",
      render: (data, item) => {
        return (
          <p className="number-ff" style={{ color: "#00A150" }}>
            {thousandFormatter(data)}
          </p>
        );
      },
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data, item) => (
        <AccionesCierres item={item} setSelectedCierre={setSelectedCierre} fetchCajaData={fetchCajaData} />
      ),
    },
  ];
};

export const ModalCajas = ({ cajaData, setCajaData }) => {
  // const { config } = useContext(GlobalContext);

  const [
    getABHistoryResolver,
    { data: dataAB, loading: loadingAB, error: errorAB },
  ] = useLazyQuery(GET_ACCOUTING_BOXES_HISTORY, {
    fetchPolicy: "network-only",
  });

  const [selectedCierre, setSelectedCierre] = useState(null);

  const [listaCierres, setListaCierres] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // const [showDrawer, setShowDrawer] = useState(false);

  const fetchCajaData = () => {
    if (cajaData?.item?._id && selectedCierre === null) {
      getABHistoryResolver({
        variables: { accountBoxId: cajaData.item?._id, page: page },
      });
    }
  };

  useEffect(() => {
    fetchCajaData();
  }, [cajaData, selectedCierre, page]);

  // useEffect(() => {
  //   if (cajaData?.item?._id) {
  //     abConceptsResolver({ variables: { abId: cajaData?.item?._id } });
  //   }
  // }, [cajaData]);

  useEffect(() => {
    if (dataAB?.getABHistoryResolver?.history) {
      const { history, pagination } = dataAB.getABHistoryResolver;
      // setListaCierres(getFormattedList(dataAB.getABHistoryResolver.history));
      setListaCierres(history || []);

      setTotalItems(pagination?.totalItems || 0);
    }
  }, [dataAB]);

  return (
    <Modal
      open={cajaData?.open}
      className="custom-modal-wrapper"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      closeIcon={
        <div
          className="close-icon"
          onClick={() =>
            setCajaData((prevState) => {
              return {
                ...prevState,
                open: false,
                stage: "table",
                item: null,
              };
            })
          }
        >
          <FiX />
        </div>
      }
      footer={false}
    >
      <>
        <h4>{cajaData?.item?.name}</h4>
        {!selectedCierre ? (
          <>
            <Table
              style={{
                marginTop: 20,
                width: "clamp(400px, 90vw, 1000px)",
              }}
              loading={loadingAB}
              columns={columns(setSelectedCierre, fetchCajaData)}
              dataSource={listaCierres || []}
              rowKey={"_id"}
              pagination={{
                pageSize: 8,
                total: totalItems,
                current: page,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} cierres`,
                showSizeChanger: false,
                onChange: (v) => {
                  setPage(v || 1);
                },
              }}
              // summary={(rows) => {
              //   console.log(rows);
              //   const total = rows?.reduce((acc, item) => {
              //     return (acc += item?.closingTotal || 0);
              //   }, 0);

              //   console.log("TOTAL", total);
              // }}
            />
            {/* <Drawer
              open={showDrawer}
              width={"calc(100% - 70px)"}
              destroyOnClose
              onClose={(v) => setShowDrawer(false)}
            >
              <PDFViewer
                style={{ width: "100%", height: "100%", border: "none" }}
              >
                <ArqueoDeCaja
                  dataCierre={{ name: cajaData?.item?.name, context: "caja" }}
                  dataConceptos={conceptos}
                />
              </PDFViewer>
            </Drawer> */}
          </>
        ) : (
          <DetalleCierre
            selectedCierre={selectedCierre}
            setSelectedCierre={setSelectedCierre}
            setCajaData={setCajaData}
            cajaData={cajaData}
          />
        )}
      </>
    </Modal>
  );
};
