import { gql } from "@apollo/client";

export const GET_COURSES = gql`
  query GetCoursesResolver {
    getCoursesResolver {
      name
      description
      createdAt
      category
      _id
      startDate
      finishDate
      duration
      cost {
        amountOfFees
        inscription
        total
      }
      turnsConformation {
        enrollmentCount
        _id
        name
        classroomId
        schedule {
          day
          startTime
          endTime
        }
      }
    }
  }
`;

export const GET_COURSE_CONFORMATION = gql`
  query getCourseConformationResolver($courseId: String) {
    getCourseConformationResolver(courseId: $courseId) {
      _id
      name
      classroomId
      schedule {
        startTime
        endTime
        day
      }
      conformation {
        students {
          _id
          fullName
          dni
        }
        instructor {
          _id
          fullName
          dni
        }
      }
    }
  }
`;

export const GET_COURSES_BY_STUDENT_ID = gql`
  query GetCoursesByStudentIdResolver($studentId: String) {
    getCoursesByStudentIdResolver(studentId: $studentId) {
      _id
      courseInfo {
        _id
        name
        description
        category
        duration
        createdAt
        status
        cost {
          amountOfFees
          inscription
          total
        }
      }
      turnInfo {
        _id
        name
        schedule {
          day
          startTime
          endTime
        }
      }
    }
  }
`;
