export const updateArrayMovimientos = (
  res,
  setMoves,
  setFilteredMoves,
  messageApi,
  setDrawerContent,
  movesPoll,
  modulo
) => {
  if (res?.data?.updateMoveResolver?._id) {
    const move = res.data.updateMoveResolver;
    
    messageApi.success("Movimiento actualizado correctamente");

    if (modulo === "cajas") {
      // Manera antigua.
      setMoves((prevState) => {
        let object = structuredClone(prevState);
        let indice = object.findIndex((box) => box._id === move.accountBoxId);

        if (indice >= 0) {
          let moveIndex = object[indice]?.moves?.findIndex(
            (element) => element._id === move._id
          );

          if (moveIndex >= 0) {
            let moves = [...object[indice].moves];

            moves[moveIndex] = { ...move };

            object[indice].moves = moves;
          }

          return object;
        } else {
          if (movesPoll?.start) {
            movesPoll.start(500);
            setTimeout(() => {
              movesPoll.stop();
            }, 1000);
          }

          return prevState;
        }
      });
    } else {
      // Movimientos filtrados

      setFilteredMoves((prevState) => {
        let array = [...prevState];

        let moveIndex = array.findIndex((element) => element._id === move._id);

        if (moveIndex >= 0) {
          array[moveIndex] = move;
        }

        return array;
      });
    }

    setDrawerContent({
      visible: false,
      type: "",
      item: {},
    });
  } else {
    messageApi.error("Ocurrió un error al actualizar un movimiento");
  }
};

export const updateArrayMovimientoDelete = (
  data,
  setMoves,
  setFilteredMoves,
  messageApi,
  setShowPopover,
  modulo
) => {
  if (data?.deleteMoveResolver?._id) {
    const move = data.deleteMoveResolver;
    messageApi.success("Movimiento actualizado correctamente");

    if (modulo === "cajas") {
      // Manera antigua.
      setMoves((prevState) => {
        let array = structuredClone(prevState);

        let boxIndex = array.findIndex(
          (box) => box._id === data.deleteMoveResolver.accountBoxId
        );

        if (boxIndex >= 0) {
          let moveIndex = array[boxIndex].moves?.findIndex(
            (move) => move._id === data.deleteMoveResolver._id
          );

          if (moveIndex >= 0) {
            array[boxIndex].moves.splice(moveIndex, 1);

            return array;
          }
        }

        return array;
      });
    } else {
      // Movimientos filtrados
      setFilteredMoves((prevState) => {
        let array = [...prevState];

        let moveIndex = array.findIndex((element) => element._id === move._id);

        if (moveIndex >= 0) {
          array.splice(moveIndex, 1);
        }

        return array;
      });
    }

    setShowPopover(false);
  } else {
    messageApi.error("Ocurrió un error al actualizar un movimiento");
  }
};
