import React from "react";

export const Inicio = () => {
  return (
    <div
      className="modulo-wrapper"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <p className="titulo-sistema">CÁNADEZ</p>
      <p className="subtitulo-sistema">Gestión</p>
    </div>
  );
};
