import { useLazyQuery } from "@apollo/client";
import { Base64 } from "js-base64";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_COURSE_CONFORMATION } from "../../../graphql/query/Cursos";
import { Button, Tabs } from "antd";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { GlobalContext } from "../../context/GlobalContext";
import { turnoTabs } from "./utils";
import { ErrorMessage } from "../../ui/error/ErrorMessage";

export const Turno = () => {
  const location = useLocation();

  const { user, messageApi } = useContext(GlobalContext);

  const [turnConformation, setTurnConformation] = useState(null);

  const [error, setError] = useState(false);

  const [getCourseConformationResolver, { loading }] = useLazyQuery(
    GET_COURSE_CONFORMATION,
    {
      fetchPolicy: "network-only",
    }
  );

  const data = useMemo(() => {
    const search = location.search;

    const payload = new URLSearchParams(search).get("payload");

    if (payload) {
      try {
        const data = Base64.decode(payload);

        return JSON.parse(data);
      } catch (error) {
        console.log("error", error);
        return null;
      }
    }

    setError(true);

    return null;
  }, [location]);

  useEffect(() => {
    if (data?.curso?._id) {
      getCourseConformationResolver({
        variables: { courseId: data?.curso?._id },
      })
        .then((res) => {
          if (res?.data?.getCourseConformationResolver) {
            const dataTurno = res?.data?.getCourseConformationResolver?.find(
              (element) => element._id === data.turno._id
            );

            if (dataTurno?.conformation) {
              setTurnConformation(dataTurno?.conformation);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [data]);

  // useEffect(() => {
  //   console.log("turnConformation", turnConformation);
  // }, [turnConformation]);

  if (error) return <ErrorMessage />;

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Turnos</p>
      </div>
      <span className="horizontal" style={{ margin: "12px" }}>
        {data?.curso?.name && <b>{data.curso.name}</b>}
        {data?.turno?.name && <b>{` - ${data.turno.name}`}</b>}
      </span>
      <Tabs items={turnoTabs(turnConformation, data)} style={{ margin: "0px 12px" }} />
    </div>
  );
};
