import { View } from "@react-pdf/renderer";
import { TableHeader } from "./TableHeader.jsx";
import { TableRow } from "./TableRow.jsx";
import { TableFooter } from "./TableFooter.jsx";
import { pdfStyles } from "../../styles.js";

export const ItemsTable = ({ data }) => {
  return (
    <View style={pdfStyles.tableContainer}>
      <TableHeader />
      <TableRow data={data} />
      <TableFooter data={data} />
    </View>
  );
};
