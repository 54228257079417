import { Button, Form, Input, Pagination } from "antd";
import { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { GlobalContext } from "../../../context/GlobalContext";
import { isAvailable } from "../../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../../assets/permisos-template";
import { useMutation } from "@apollo/client";
import { CREATE_AREA } from "../../../../graphql/mutation/Config";
import { BlobLoader } from "../../../ui/loader/BlobLoader";

export const Areas = () => {
  const { config, messageApi, user, configPoll, loadingGlobal } =
    useContext(GlobalContext);
  const [busqueda, setBusqueda] = useState("");
  const [pagina, setPagina] = useState(1);

  const [form] = Form.useForm();

  const [newAreaResolver, { data, loading, error }] = useMutation(CREATE_AREA, {
    onCompleted: (data) => {
      if (data?.newAreaResolver?.status === 200) {
        configPoll.start(500);
        setTimeout(() => {
          configPoll.stop();
        }, 1000);

        messageApi.success("Area agregada correctamente");
        form.setFieldsValue({
          name: "",
          description: "",
        });
      } else {
        messageApi.error("Ocurrió un error al agregar un area");
      }
    },
    onError: (error) => {
      messageApi.error("Ocurrió un error al agregar un area");
    },
  });

  const SIZE = 21;
  const paginado = (number) => {
    let array = [];

    if (config?.areas) {
      array = [...config.areas];

      if (busqueda) {
        array = array.filter((element) => {
          if (element.name?.toLowerCase().includes(busqueda.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      }

      number = (pagina - 1) * SIZE;
      if (array.length > 0) {
        array = array.splice(number, SIZE);
      }
    }

    return array;
  };

  const onFinish = (v) => {
    let name = v.name?.trim().toUpperCase() || "";
    let description = v.description?.trim() || "";
    if (name.length < 3) {
      return messageApi.info("El nombre debe contener al menos 2 caracteres");
    }

    newAreaResolver({
      variables: { areaInput: { name: name, description: description } },
    });
  };
  
  return (
    <div className="config-layout">
      <div className="config-column">
        <div className="config-table-wrapper">
          <span className="config-tabla-header">
            <p className="config-new-title" style={{ marginBottom: "8px" }}>Lista de areas</p>
            <Input
              type="search"
              addonAfter={<FiSearch style={{ cursor: "pointer" }} />}
              value={busqueda}
              onChange={(v) => setBusqueda(v.target.value)}
              placeholder="Buscar areas"
            />
          </span>
          {loadingGlobal?.config ? (
            <BlobLoader />
          ) : (
            <>
              <div className="column-ciudades">
                {paginado()?.map((element) => {
                  return (
                    <span className="city-wrapper" key={element._id}>
                      {element.name}
                    </span>
                  );
                })}
              </div>
              <Pagination
                style={{ marginLeft: "auto", marginTop: "4px" }}
                value={pagina}
                total={config?.areas?.length}
                showSizeChanger={false}
                size="small"
                pageSize={SIZE}
                onChange={(v) => setPagina(v)}
              />
            </>
          )}
        </div>
      </div>
      <div className="config-column">
        {isAvailable(
          "settings",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <div className="config-table-wrapper">
            <p className="config-new-title">Nueva area</p>
            <Form
              form={form}
              layout="vertical"
              style={{ width: "100%" }}
              onFinish={(v) => onFinish(v)}
            >
              <Form.Item label="Nombre" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="Descripción" name="description">
                <Input.TextArea />
              </Form.Item>
              <Form.Item className="form-custom-footer">
                <div className="botones-wrapper-content">
                  <Button
                    htmlType="submit"
                    className="btn-guardar"
                    size="large"
                    loading={loading}
                  >
                    Guardar
                  </Button>
                  <Button
                    className="btn-cancelar"
                    size="large"
                    onClick={() => {}}
                  >
                    Cancelar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
