export const handlePermisos = (seccion, check, value, setPermisos) => {
  setPermisos((prevState) => {
    let object = structuredClone(prevState);

    if (!object[seccion]) {
      object[seccion] = [check];
      return object;
    }

    if (object[seccion]?.includes(check)) {
      let index = object[seccion].indexOf(check);

      if (index >= 0) {
        object[seccion].splice(index, 1);
      }
    } else {
      object[seccion].push(check);
    }

    return object;
  });
};
