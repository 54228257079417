import { Modal, Tooltip } from "antd";
import { NewTransfer } from "../../../transferencias/NewTransfer.jsx";
import { useContext, useState } from "react";
import { isAvailable } from "../../../../../utils/isAvailable.js";
import { SECCION_PERMISOS } from "../../../../../assets/permisos-template.js";
import { GlobalContext } from "../../../../context/GlobalContext.js";
import { TbListDetails } from "react-icons/tb";
import { HiChevronDoubleUp, HiChevronDoubleDown } from "react-icons/hi";
import { RecibirFondos } from "../../../finanzas/cajas/RecibirFondos.jsx";

export const AccionesCierres = ({ item, setSelectedCierre, fetchCajaData }) => {
  const { user } = useContext(GlobalContext);

  const [showNewTransfer, setShowNewTransfer] = useState({
    open: false,
    type: "",
    item: null,
  });

  const [showRecibirFondosModal, setShowRecibirFondosModal] = useState({
    open: false,
    type: "",
    item: null,
  });

  const showTransfersOptions = item?.status?.toUpperCase() === "CLOSE";

  return (
    <>
      <span className="horizontal" style={{ justifyContent: "center" }}>
        <Tooltip title="Ver detalle">
          <span
            className="pointer"
            onClick={() => {
              setSelectedCierre(item);
            }}
          >
            <TbListDetails />
          </span>
        </Tooltip>
        {showTransfersOptions &&
          isAvailable(
            "settings",
            SECCION_PERMISOS.crear,
            user?.rol?.permissions
          ) && (
            <>
              <Tooltip title="Enviar transferencia">
                <span
                  className="pointer"
                  onClick={() =>
                    setShowNewTransfer({
                      open: true,
                      type: "new",
                      item: {
                        abHistoryId: item?._id,
                        accountBoxId: item?.accountBoxId,
                      },
                    })
                  }
                >
                  <HiChevronDoubleUp />
                </span>
              </Tooltip>
              <Tooltip title="Recibir transferencia">
                <span
                  className="pointer"
                  onClick={() =>
                    setShowRecibirFondosModal({
                      open: true,
                      type: "new",
                      item: {
                        abHistoryId: item?._id,
                        accountBoxId: item?.accountBoxId,
                      },
                    })
                  }
                >
                  <HiChevronDoubleDown />
                </span>
              </Tooltip>
            </>
          )}
      </span>

      <Modal
        open={showNewTransfer.open}
        footer={false}
        title={"Enviar transferencia"}
        onCancel={() =>
          setShowNewTransfer({ open: false, type: null, item: null })
        }
        className="custom-modal-wrapper"
        destroyOnClose
      >
        <NewTransfer
          showNewTransfer={showNewTransfer}
          setShowNewTransfer={setShowNewTransfer}
          fetchCajaData={fetchCajaData}
        />
      </Modal>

      <Modal
        open={showRecibirFondosModal.open}
        footer={false}
        title="Recibir transferencia"
        onCancel={() =>
          setShowRecibirFondosModal({ open: false, type: null, item: null })
        }
        className="custom-modal-wrapper"
        destroyOnClose
      >
        <RecibirFondos
          showModal={showRecibirFondosModal}
          setShowModal={setShowRecibirFondosModal}
          fetchCajaData={fetchCajaData}
        />
      </Modal>
    </>
  );
};
