import { Asistencias } from "./Asistencias.jsx";
import { Notas } from "./Notas.jsx";

export const turnoTabs = (turnConformation, data) => [
  {
    label: `Asistencias`,
    key: "asistencias",
    children: <Asistencias turnConformation={turnConformation} data={data} />,
  },
  {
    label: `Notas`,
    key: "notas",
    children: <Notas turnConformation={turnConformation}  data={data} />,
  },
];

export const asistenciasColumns = () => {
  return [
    { title: "Clase Nº", key: "", dataIndex: "" },
    { title: "Acciones", key: "", dataIndex: "", width: 100, align: "center" },
  ];
};

export const notasColumns = () => {
  return [
    { title: "Nota Nº", key: "", dataIndex: "" },
    { title: "Acciones", key: "", dataIndex: "", width: 100, align: "center" },
  ];
};
