import { Button, Drawer, Table } from "antd";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useLazyQuery } from "@apollo/client";
import { GET_MOVES_FOR_REPORT } from "../../../graphql/query/Finanzas";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { isAvailable } from "../../../utils/isAvailable";
import { MAIN_PROFILE_ID } from "../../../utils/relevantIds";
import { PDFViewer } from "@react-pdf/renderer";
import {
  GET_ACCOUNTING_BOXES_BY_USER_ID,
} from "../../../graphql/query/Cajas";
import { columnMovimientos } from "./utils";
import { Recibo } from "../reports/components/recibo/Recibo";
import { ErrorMessage } from "../../ui/error/ErrorMessage";
import { Filtros } from "./Filtros";
import dayjs from "dayjs";
import { FiSliders } from "react-icons/fi";
import { BsFiletypeXls } from "react-icons/bs";
import { handleExportar } from "../dashboard/utils";
import { useNavigate } from "react-router-dom";

const oneWeakAgo = dayjs().subtract(1, "week").startOf("day");

export const Finanzas = () => {
  const {
    setDrawerContent,
    user,
    config,
    alumnos,
    messageApi,
    loadingGlobal,
    filteredMoves,
    setFilteredMoves,
  } = useContext(GlobalContext);

  const [pdfData, setPdfData] = useState({
    open: false,
    data: null,
  });

  const [movesFilter, setMovesFilter] = useState({
    dateFrom: oneWeakAgo,
  });

  const [showFilters, setShowFilters] = useState(false);

  const navigate = useNavigate();

  const [
    getMovesForReportResolver,
    { loading: loadingMoves, error: errorMoves, data: dataMoves },
  ] = useLazyQuery(GET_MOVES_FOR_REPORT, { fetchPolicy: "network-only" });

  const [
    getAccountingBoxesByUserResolver,
    { loading: loadingCajas, error: errorCajas, data: dataCajas },
  ] = useLazyQuery(GET_ACCOUNTING_BOXES_BY_USER_ID, {
    fetchPolicy: "network-only",
  });

  const handleAddMove = () => {
    setDrawerContent({
      visible: true,
      type: "Agregar Movimiento",
      item: {},
    });
  };

  const handleGetFilteredMoves = (filtros) => {
    let obj = { ...filtros };

    if (obj.dateFrom) {
      obj.dateFrom = parseFloat(dayjs(obj.dateFrom).format("x"));
    }

    if (obj.dateTo) {
      obj.dateTo = parseFloat(dayjs(obj.dateTo.endOf("day")).format("x"));
    }

    getMovesForReportResolver({ variables: obj })
      .then((res) => {
        if (res?.data?.getMovesForReportResolver) {
          let movimientos = [...res.data.getMovesForReportResolver];

          movimientos.sort((a, b) => b.date - a.date);

          setFilteredMoves(movimientos);
          setShowFilters(false);
        } else {
          messageApi.error("Ocurrió un error al obtener movimientos");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al obtener movimientos");
      });
  };

  useEffect(() => {
    if (user?._id && !dataCajas?.getAccountingBoxesByUserResolver) {
      getAccountingBoxesByUserResolver({
        variables: { userId: user._id },
      });
    }
  }, [user]);

  useEffect(() => {
    let filtros = { ...movesFilter, accountBoxId: null };

    // INICIO

    if (user?.rol?._id === MAIN_PROFILE_ID) {
      // Si es admin, si hay cajas y le asigno la primera.

      if (config?.accountingBoxes?.[0]?._id) {
        filtros.accountBoxId =
          filtros.accountBoxId || config?.accountingBoxes?.[0]?._id;
      } else {
        // Si no que no dispare
        return;
      }
    } else {
      // Si no es admin
      if (dataCajas?.getAccountingBoxesByUserResolver?.length < 1) {
        // !config?.accountingBoxes?.[0]?._id
        // Si no es admin y no tiene cajas no busco
        return;
      }

      if (dataCajas?.getAccountingBoxesByUserResolver?.length > 0) {
        filtros.accountBoxId =
          dataCajas.getAccountingBoxesByUserResolver[0]?.accountBoxData?._id;
      }
    }

    if (filtros.accountBoxId) {
      setMovesFilter(filtros);
      handleGetFilteredMoves(filtros);
    }
  }, [dataCajas, user, config]);

  useEffect(() => {
    setFilteredMoves([]);
  }, []);

  if (errorMoves) return <ErrorMessage />;

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Finanzas</p>
        <span className="span-mid-width">
          <Button onClick={() => setShowFilters(true)}>
            <span className="horizontal gap8" style={{ display: "flex" }}>
              <FiSliders />
              <p>Filtros</p>
            </span>
          </Button>
          <Button
            onClick={() =>
              handleExportar(filteredMoves, config?.paymentMethods, messageApi)
            }
          >
            <span className="horizontal gap8" style={{ display: "flex" }}>
              <BsFiletypeXls style={{ fontSize: 20 }} />
              <p>Exportar</p>
            </span>
          </Button>
          {isAvailable(
            "settings",
            SECCION_PERMISOS.crear,
            user?.rol?.permissions
          ) && (
            <Button onClick={() => navigate("/finanzas/cajas")}>Cajas</Button>
          )}
        </span>
        {isAvailable(
          "moves",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && <Button onClick={() => handleAddMove()}>Nuevo movimiento</Button>}
      </div>
      <div className="modulo-content">
        {loadingMoves || loadingGlobal?.config ? (
          <BlobLoader />
        ) : (
          <>
            <Drawer
              open={pdfData.open}
              width={"calc(100% - 70px)"}
              destroyOnClose
              onClose={(v) => setPdfData({ open: false, data: null })}
            >
              <PDFViewer
                style={{ width: "100%", height: "100%", border: "none" }}
              >
                <Recibo data={pdfData.data} alumnos={alumnos} />
              </PDFViewer>
            </Drawer>
            <Table
              className="table-wrapper"
              columns={columnMovimientos(setPdfData, alumnos, "finanzas")}
              dataSource={filteredMoves}
              loading={loadingMoves}
              rowKey={"_id"}
              size="small"
              pagination={{
                pageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} movimientos`,
                showSizeChanger: false,
              }}
            />
            <Drawer
              key="drawerFiltros"
              open={showFilters}
              destroyOnClose
              closable={false}
            >
              <Filtros
                setMovesFilter={setMovesFilter}
                movesFilter={movesFilter}
                setShowFilters={setShowFilters}
                loadingMoves={loadingMoves}
                dataCajas={dataCajas}
                handleGetFilteredMoves={handleGetFilteredMoves}
              />
            </Drawer>
          </>
        )}
      </div>
    </div>
  );
};
