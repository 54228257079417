import { Page, View, Document, Font } from "@react-pdf/renderer";
import { DetalleCaja } from "./DetalleCaja.jsx";
import { Title } from "../Title.jsx";
import { pdfStyles } from "../../styles.js";
import { ItemsTable } from "./ItemsTable.jsx";

Font.register({
  family: "Roboto",
  fonts: [{ fontWeight: 600 }, { fontWeight: 700 }],
});

export const ArqueoDeCaja = ({ dataCierre = {}, dataConceptos }) => {
  return (
    <Document>
      <Page size="A4">
        <View style={pdfStyles.page}>
          <Title data={dataCierre} showSubtitle={false} showTodayDate={true} />
          <DetalleCaja dataCierre={dataCierre} />
          <ItemsTable data={dataConceptos} dataCierre={dataCierre} />
        </View>
      </Page>
    </Document>
  );
};
