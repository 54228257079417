import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsersWithPermissionsResolver {
    getUsersWithPermissionsResolver {
      _id
      name
      username
      email
      password
      createdAt
      permissions {
        _id
        name
      }
    }
  }
`;

export const GET_PROFILES = gql`
  query GetPermissionsProfileResolver {
    getPermissionsProfileResolver {
      _id
      name
      permissions {
        students
        instructors
        courses
        coursesSchedule
        coursesConformation
        moves
        settings
        users
        stats
        transfers
      }
    }
  }
`;

export const GET_UPDATED_USER_DATA = gql`
  query getUserInfoResolver($userId: String) {
    getUserInfoResolver(userId: $userId) {
      message
      status
    }
  }
`;
