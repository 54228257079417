import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { Button, Form, Select, Input } from "antd";
import { GET_COURSES_BY_STUDENT_ID } from "../../../../graphql/query/Cursos";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BlobLoader } from "../../loader/BlobLoader";
import { WITHDRAWAL_STUDENT } from "../../../../graphql/mutation/Alumnos";
import { ErrorMessage } from "../../error/ErrorMessage";

export const DesafectarCursos = () => {
  const {
    drawerContent,
    setDrawerContent,
    cursos,
    cursosPoll,
    messageApi,
    config,
  } = useContext(GlobalContext);

  const [turnOptions, setTurnOptions] = useState([]);
  const [selectedTurn, setSelectedTurn] = useState(null);
  const [motivoBaja, setMotivoBaja] = useState(null);

  const [form] = Form.useForm();

  const [studentWithdrawalResolver, { loading, error, data }] = useMutation(
    WITHDRAWAL_STUDENT,
    {
      onCompleted: (data) => {
        if (data?.studentWithdrawalResolver?.status === 200) {
          messageApi.success("Alumno desafectado correctamente");
          cursosPoll.start(500);
          setTimeout(() => {
            cursosPoll.stop();
          }, 1000);

          setDrawerContent({
            visible: false,
            type: "",
            item: {},
          });
        } else {
          messageApi.error("Ocurrió un error al desafectar un alumno");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al desafectar un alumno");
      },
    }
  );

  const [
    getCoursesByStudentId,
    { loading: loadingById, error: errorById, data: dataById },
  ] = useLazyQuery(GET_COURSES_BY_STUDENT_ID, { fetchPolicy: "network-only" });

  const onChange = (v) => {
    setSelectedTurn(v);
  };

  const onFinish = (values) => {
    values.description = values.description?.trim() || "";
    if (!selectedTurn) {
      return messageApi.info("Seleccione un turno al cual desafectar");
    }

    if (!motivoBaja) {
      return messageApi.info("Seleccione un motivo");
    }

    studentWithdrawalResolver({
      variables: {
        studentId: drawerContent.item?._id,
        turnId: selectedTurn,
        motive: motivoBaja,
        description: values.description,
      },
    });
  };

  useEffect(() => {
    if (drawerContent?.item?._id) {
      getCoursesByStudentId({
        variables: {
          studentId: drawerContent.item._id,
        },
      });
    }
  }, [drawerContent]);

  useEffect(() => {
    if (dataById?.getCoursesByStudentIdResolver?.length > 0) {
      setTurnOptions(
        dataById.getCoursesByStudentIdResolver.map((element) => {
          return {
            value: element.turnInfo?._id,
            label: `${element.courseInfo?.name} - ${element.turnInfo?.name}`,
          };
        })
      );
    }
  }, [dataById]);

  if (error) return <ErrorMessage />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="desafectarCurso"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        {drawerContent?.item?.fullName && (
          <span>{`Desafectar a ${drawerContent.item.fullName}`}</span>
        )}
        {loadingById ? (
          <BlobLoader />
        ) : (
          <Form.Item style={{ marginTop: "16px" }}>
            <Select
              options={turnOptions}
              showSearch
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Elija un turno"
              onChange={(v) => onChange(v)}
              value={selectedTurn}
            />
          </Form.Item>
        )}
        <Form.Item label="Motivo">
          <Select
            options={config?.withdrawalReasons?.map((element) => {
              return {
                value: element._id,
                label: element.name,
              };
            })}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={motivoBaja}
            onChange={(v) => setMotivoBaja(v)}
          />
        </Form.Item>
        <Form.Item label="Descripción" name="description">
          <Input.TextArea />
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
