import { gql } from "@apollo/client";

export const NEW_TRANSFER = gql`
  mutation newTransferResolver(
    $abFrom: String
    $abTo: String
    $transferInput: [transferInput]
    $total: Float
    $abHistoryId: String
  ) {
    newTransferResolver(
      abFrom: $abFrom
      abTo: $abTo
      transferInput: $transferInput
      total: $total
      abHistoryId: $abHistoryId
    ) {
      message
      status
    }
  }
`;

// export const UPDATE_TRANSFER = gql`
//   mutation updateTransferResolver(
//     $_id: String
//     $abFrom: String
//     $abTo: String
//     $transferInput: [transferInput]
//     $total: Float
//   ) {
//     updateTransferResolver(
//       _id: $_id
//       abFrom: $abFrom
//       abTo: $abTo
//       transferInput: $transferInput
//       total: $total
//     ) {
//       message
//       status
//     }
//   }
// `;

export const DELETE_TRANSFER = gql`
  mutation deleteTransferResolver($_id: String) {
    deleteTransferResolver(_id: $_id) {
      message
      status
    }
  }
`;
