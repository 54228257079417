import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_CONFIG } from "../../graphql/query/Config";
import { mapConfig } from "../../utils/orderConfig";

export const useConfig = (
  setConfig,
  messageApi,
  setLoadingGlobal,
  setConfigPoll,
  user
) => {
  const [
    getConfigResolver,
    { loading, error, data, startPolling, stopPolling },
  ] = useLazyQuery(GET_CONFIG, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      user?._id
      //!  && user?.type === "SYSTEM"
    ) {
      getConfigResolver();
    }
  }, [user]);

  useEffect(() => {
    setLoadingGlobal((prevState) => {
      let object = { ...prevState };

      object.config = loading;
      return object;
    });
  }, [loading]);

  useEffect(() => {
    if (error) {
      messageApi.error("Error al obtener alumnos");
    }
  }, [error]);

  useEffect(() => {
    if (data?.getConfigResolver) {
      setConfig(mapConfig(data.getConfigResolver));
    }
  }, [data]);

  useEffect(() => {
    setConfigPoll({
      start: startPolling,
      stop: stopPolling,
    });
  }, []);

  return;
};
