import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Checkbox, Form, Input, Tooltip } from "antd";
import { PERMISOS_TEMPLATE } from "../../../assets/permisos-template";
import { FiEdit3, FiEye, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { TbShieldCheck } from "react-icons/tb";
import { SETTINGS_TEMPLATE } from "../../../utils/settingsTemplate";
import { handlePermisos } from "../../../utils/permisos";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE } from "../../../graphql/mutation/Usuarios";

export const UpdateRol = ({ edit = true }) => {
  const { drawerContent, setDrawerContent, messageApi, profiles, setProfiles } =
    useContext(GlobalContext);

  const [permisos, setPermisos] = useState({
    students: [],
    instructors: [],
    courses: [],
    coursesSchedule: [],
    coursesConformation: [],
    moves: [],
    transfers: [],
    settings: [],
    users: [],
    stats: [],
  });

  const [name, setName] = useState("");

  const [form] = Form.useForm();

  const [updatePermissionProfileResolver, { loading, error, data }] =
    useMutation(UPDATE_PROFILE, {
      onCompleted: (data) => {
        if (data.updatePermissionProfileResolver?.status === 200) {
          messageApi.success("Se actualizó el rol correctamente");

          setProfiles((prevState) => {
            let array = [...prevState];

            let index = array.findIndex(
              (element) => element._id === drawerContent?.item?._id
            );

            if (index > -1) {
              array[index] = {
                ...array[index],
                name: name,
                permissions: permisos,
              };
            }

            return array;
          });

          setDrawerContent({
            visible: false,
            type: "",
            item: {},
          });
        } else {
          messageApi.error("Ocurrió un error al actualizar un rol");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al actualizar un rol");
      },
    });

  const onFinish = (v) => {
    if (!permisos || !name) {
      return messageApi.info("Debe tener nombre y permisos");
    }

    updatePermissionProfileResolver({
      variables: {
        permissionInput: { name: name, permissions: permisos },
        permissionId: drawerContent.item?._id,
      },
    });
  };

  useEffect(() => {
    if (drawerContent?.item) {
      setName(drawerContent?.item?.name);
      let permissions = { ...drawerContent.item?.permissions };

      delete permissions.__typename;

      setPermisos(permissions);
    }
  }, [drawerContent]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="updateRol"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
      disabled={!edit}
    >
      <div className="form-content">
        <Form.Item label="Nombre">
          <Input value={name} onChange={(v) => setName(v.target.value)} />
        </Form.Item>
        <div style={{ marginBottom: "24px" }}>
          <span className="horizontal permisos-wrapper">
            <b>Permisos</b>
            <span className="horizontal permisos-item">
              <Tooltip title="Habilitar módulo" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <TbShieldCheck />
                </span>
              </Tooltip>
              <Tooltip title="Ver" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiEye />
                </span>
              </Tooltip>
              <Tooltip title="Nuevo" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiPlus />
                </span>
              </Tooltip>
              <Tooltip title="Editar" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <FiEdit3 />
                </span>
              </Tooltip>
              <Tooltip title="Eliminar" placement="topRight">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <AiOutlineDelete />
                </span>
              </Tooltip>
            </span>
          </span>
          {PERMISOS_TEMPLATE.map((seccion, index) => {
            return (
              <span className="horizontal permisos-wrapper" key={index}>
                <p>{seccion.label}</p>
                <span
                  className="horizontal permisos-item"
                  style={{ width: "fit-content" }}
                >
                  {SETTINGS_TEMPLATE.map((check, index) => {
                    return (
                      <Checkbox
                        checked={permisos[seccion.name]?.includes(check)}
                        key={index}
                        onChange={(v) =>
                          handlePermisos(
                            seccion.name,
                            check,
                            v.target.checked,
                            setPermisos
                          )
                        }
                      />
                    );
                  })}
                </span>
              </span>
            );
          })}
        </div>
      </div>
      {edit && (
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() =>
                setDrawerContent({ visible: false, item: {}, type: "" })
              }
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      )}
    </Form>
  );
};
