import { gql } from "@apollo/client";

export const MANAGE_ACCOUNTING_BOXES = gql`
  mutation ManageUserBoxRelationsResolver(
    $userId: String
    $relationsToActivate: [String]
    $relationsToDeactivate: [String]
  ) {
    manageUserBoxRelationsResolver(
      userId: $userId
      relationsToActivate: $relationsToActivate
      relationsToDeactivate: $relationsToDeactivate
    ) {
      message
      status
    }
  }
`;

// aBHistoryInput,
//     transferInput, // Remanente de la caja (para la proxima apertura)
//     abHistoryId,

export const CLOSE_ACCOUNT_BOX = gql`
  mutation newABCloseResolver(
    $aBHistoryInput: aBHistoryInput
    $transferInput: [transferInput]
    $abHistoryId: String
  ) {
    newABCloseResolver(
      aBHistoryInput: $aBHistoryInput
      transferInput: $transferInput
      abHistoryId: $abHistoryId
    ) {
      _id
      dateFrom
      dateTo
      accountBoxId
      status
      totalAmount
    }
  }
`;
