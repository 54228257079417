import { useContext, useEffect } from "react";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import "./usuarios.css";
import { Button, Tooltip } from "antd";
import { GlobalContext } from "../../context/GlobalContext";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { useQuery } from "@apollo/client";
import { GET_PROFILES, GET_USERS } from "../../../graphql/query/Usuarios";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { TbListDetails } from "react-icons/tb";
import { ErrorMessage } from "../../ui/error/ErrorMessage";

export const Usuarios = () => {
  const { data, loading, error } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });
  const {
    data: dataRol,
    loading: loadingRol,
    error: errorRol,
  } = useQuery(GET_PROFILES, { fetchPolicy: "network-only" });

  const { setDrawerContent, user, profiles, setProfiles, users, setUsers } =
    useContext(GlobalContext);

  const handleSelect = (item, type) => {
    if (isAvailable("users", SECCION_PERMISOS.editar, user?.rol?.permissions)) {
      switch (type) {
        case "user":
          setDrawerContent({
            visible: true,
            type: "Editar Usuario",
            item: item,
          });
          break;
        case "rol":
          setDrawerContent({
            visible: true,
            type: "Editar Rol",
            item: item,
          });
      }
    }
  };

  const handleGestionarCajas = (e, usuario) => {
    e.stopPropagation();
    setDrawerContent({
      visible: true,
      type: "Gestionar Cajas",
      item: usuario,
    });
  };

  useEffect(() => {
    if (data?.getUsersWithPermissionsResolver) {
      setUsers(data.getUsersWithPermissionsResolver);
    }
  }, [data]);

  useEffect(() => {
    if (dataRol?.getPermissionsProfileResolver) {
      setProfiles(dataRol?.getPermissionsProfileResolver);
    }
  }, [dataRol]);

  if (error || errorRol) return <ErrorMessage />;

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Usuarios</p>
      </div>
      <div className="modulo-content">
        <div className="usuarios-layout">
          <div className="column-usuarios">
            <span className="horizontal usuarios-header">
              <h3>Roles</h3>
              {isAvailable(
                "users",
                SECCION_PERMISOS.crear,
                user?.rol?.permissions
              ) && (
                <Button
                  className="btn-guardar"
                  onClick={() =>
                    setDrawerContent({
                      visible: true,
                      type: "Agregar Rol",
                      item: {},
                    })
                  }
                >
                  Nuevo rol
                </Button>
              )}
            </span>
            {loadingRol ? (
              <BlobLoader />
            ) : (
              <div className="users-wrapper">
                {profiles?.map((rol, index) => {
                  return (
                    <div className="usuario-wrapper" key={index}>
                      <span className="horizontal">
                        <p className="user-name">{rol.name}</p>
                        <span
                          className="horizontal"
                          style={{ width: "fit-content" }}
                        >
                          {isAvailable(
                            "users",
                            SECCION_PERMISOS.ver,
                            user?.rol?.permissions
                          ) && (
                            <Tooltip title="Ver detalles" placement="topRight">
                              <span className="icono-acciones">
                                <AiOutlineEye
                                  size={18}
                                  onClick={() =>
                                    setDrawerContent({
                                      visible: true,
                                      type: "Ver Rol",
                                      item: rol,
                                    })
                                  }
                                />
                              </span>
                            </Tooltip>
                          )}
                          {isAvailable(
                            "users",
                            SECCION_PERMISOS.editar,
                            user?.rol?.permissions
                          ) && (
                            <Tooltip title="Editar" placement="topRight">
                              <span className="icono-acciones">
                                <FiEdit3
                                  size={18}
                                  onClick={() => handleSelect(rol, "rol")}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="column-usuarios">
            <span className="horizontal usuarios-header">
              <h3>Usuarios</h3>
              {isAvailable(
                "users",
                SECCION_PERMISOS.crear,
                user?.rol?.permissions
              ) && (
                <Button
                  className="btn-guardar"
                  onClick={() =>
                    setDrawerContent({
                      visible: true,
                      type: "Crear Usuario",
                      item: {},
                    })
                  }
                >
                  Nuevo usuario
                </Button>
              )}
            </span>
            <div className="users-wrapper">
              {loading ? (
                <BlobLoader />
              ) : (
                users?.map((usuario, index) => {
                  return (
                    <div className="usuario-wrapper" key={index}>
                      <span className="user-upper-line">
                        <p className="user-name">{usuario.name}</p>
                        <span
                          className="horizontal"
                          style={{ width: "fit-content" }}
                        >
                          {isAvailable(
                            "users",
                            SECCION_PERMISOS.ver,
                            user?.rol?.permissions
                          ) && (
                            <Tooltip title="Ver detalles" placement="topRight">
                              <span className="icono-acciones">
                                <AiOutlineEye
                                  size={18}
                                  onClick={() =>
                                    setDrawerContent({
                                      visible: true,
                                      type: "Ver Usuario",
                                      item: usuario,
                                    })
                                  }
                                />
                              </span>
                            </Tooltip>
                          )}
                          {isAvailable(
                            "users",
                            SECCION_PERMISOS.editar,
                            user?.rol?.permissions
                          ) && (
                            <Tooltip title="Editar" placement="topRight">
                              <span className="icono-acciones">
                                <FiEdit3
                                  size={18}
                                  onClick={() => handleSelect(usuario, "user")}
                                />
                              </span>
                            </Tooltip>
                          )}
                          <Tooltip title="Gestionar cajas" placement="topRight">
                            <span
                              className="icono-acciones"
                              onClick={(e) => handleGestionarCajas(e, usuario)}
                            >
                              <TbListDetails size={18} />
                            </span>
                          </Tooltip>
                        </span>
                      </span>
                      <p className="rol">{usuario.permissions?.name}</p>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
