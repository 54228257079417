import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_STUDENTS } from "../../graphql/query/Alumnos";

export const useAlumnos = (setAlumnos, messageApi, setLoadingGlobal, user) => {
  const [getStudentsResolver, { loading, error, data }] =
    useLazyQuery(GET_STUDENTS, {fetchPolicy: "network-only"});

  useEffect(() => {
    setLoadingGlobal((prevState) => {
      let object = { ...prevState };

      object.alumnos = loading;
      return object;
    });
  }, [loading]);

  useEffect(() => {
    if (error) {
      messageApi.error("Error al obtener alumnos");
    }
  }, [error]);

  useEffect(() => {
    if (Array.isArray(data?.getStudentsResolver)) {
      setAlumnos(data.getStudentsResolver);
    }
  }, [data]);

  useEffect(() => {
    if(user?._id 
      // && user?.type === "SYSTEM"
      ) {
      getStudentsResolver()
    }
  }, [user])

  return;
};
