import { Button, Form, Input, message } from "antd";
import "./login.css";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { useMutation } from "@apollo/client";
import { LOGIN_ADMIN, LOGIN_STUDENT } from "../../../graphql/mutation/Login";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BlobLoader } from "../../ui/loader/BlobLoader.jsx";
import CryptoJS from "crypto-js";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

const passphrase = process.env.REACT_APP_TOKEN_SECRET;

const titulos = {
  admin: "Administración",
  student: "Alumnos",
};

export const Login = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { user, setUser } = useContext(GlobalContext);

  const [loginType, setLoginType] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();
  const [showLoader, setShowLoader] = useState(false);

  const [loginAuthenticationResolver] = useMutation(LOGIN_ADMIN);

  const [studentLoginResolver] = useMutation(LOGIN_STUDENT);

  const saveToken = (token) => {
    try {
      let tmp = JSON.parse(
        CryptoJS.AES.decrypt(token, passphrase).toString(CryptoJS.enc.Utf8)
      );

      if (loginType === "student") {
        tmp.type = "student";

        token = CryptoJS.AES.encrypt(
          JSON.stringify(tmp),
          passphrase
        ).toString();
      }

      localStorage.setItem("token", token);
      setUser(tmp);

      if (tmp?.type?.toLowerCase() === "student") {
        navigate("/student");
      } else if (tmp?.type?.toLowerCase() === "instructor") {
        navigate("/instructor");
      } else {
        navigate("/");
      }
    } catch (error) {
      messageApi.error("No se pudo registrar el token de acceso");
      setShowLoader(false);
    }
  };

  const handleLoginType = (type) => {
    setLoginType(type);
  };

  const handleAdminLogin = (values) => {
    setShowLoader(true);
    loginAuthenticationResolver({
      variables: {
        loginAuthInput: {
          password: values.password,
          username: values.username,
        },
      },
    })
      .then((res) => {
        if (res?.data?.loginAuthenticationResolver?.status === 200) {
          saveToken(res.data.loginAuthenticationResolver?.message);
        } else {
          localStorage.removeItem("token");
          if (res?.data?.loginAuthenticationResolver?.message) {
            messageApi.error(res.data.loginAuthenticationResolver.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
        messageApi.error("Ocurrió un error al intentar ingresar al sistema");
      })
      .finally(() => setShowLoader(false));
  };

  const handleStudentLogin = (values) => {
    setShowLoader(true);
    studentLoginResolver({
      variables: {
        dni: values.username,
        password: values.password,
      },
    })
      .then((res) => {
        if (res?.data?.studentLoginResolver?.status === 200) {
          saveToken(res.data.studentLoginResolver?.message);
        } else {
          localStorage.removeItem("token");
          if (res?.data?.studentLoginResolver?.message) {
            messageApi.error(res.data.studentLoginResolver.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
        messageApi.error("Ocurrió un error al intentar ingresar al sistema");
      })
      .finally(() => setShowLoader(false));
  };

  const onFinish = (values) => {
    if (!loginType) {
      return messageApi.info("Seleccione un tipo de autenticación");
    }

    switch (loginType) {
      case "admin":
        handleAdminLogin(values);
        break;
      case "student":
        handleStudentLogin(values);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="login-wrapper">
      {contextHolder}
      <img
        src="/assets/blob-scene-haikei.svg"
        alt="Imagen de fondo"
        className="background-image"
      />

      {showLoader && (
        <div className="login-form fade-in">
          <BlobLoader />
        </div>
      )}

      {!loginType && (
        <div className="login-form fade-in">
          <span className="titulo-login">Ingreso</span>
          <Button
            block
            size="large"
            className="btn-generico"
            onClick={() => handleLoginType("admin")}
          >
            Administración
          </Button>
          {/* <Button
            block
            size="large"
            className="btn-generico"
            onClick={() => handleLoginType("student")}
          >
            Soy alumno
          </Button> */}
        </div>
      )}

      {!showLoader && loginType && (
        <div className="login-form fade-in">
          <span className="titulo-login fade-in">{titulos[loginType]}</span>
          <Form
            form={form}
            name="login-form"
            className="fade-in"
            onFinish={onFinish}
          >
            <Form.Item name="username">
              <Input
                prefix={<AiOutlineUser size={18} />}
                placeholder="Usuario"
                size="large"
              />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password
                visibilityToggle
                prefix={<RiLockPasswordLine size={18} />}
                placeholder="Contraseña"
                size="large"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0, marginTop: 24 }}>
              <span className="horizontal">
                <Button
                  block
                  size="large"
                  className="btn-generico"
                  htmlType="submit"
                >
                  Ingresar
                </Button>
                <Button
                  block
                  size="large"
                  className="btn-cancelar"
                  onClick={() => handleLoginType(null)}
                >
                  Volver
                </Button>
              </span>
            </Form.Item>
          </Form>
        </div>
      )}

      {/* <span className="btn-group">
              <a href="#">Olvidé mi contraseña</a>
              <a href="#">Registrarme</a>
            </span> */}
    </div>
  );
};
