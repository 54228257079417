export const MAIN_CITY_ID = "64749175ebdbc74c731a95d2";

export const MAIN_CURRENCY_ID = "64b2ab35db351910c3e6d74c";

export const MAIN_PAYMENT_METHOD_ID = "64b72931739346d91f306473";

// //! PRODUCCION
export const MAIN_PROFILE_ID = "64b07f63608551eb23fb0aef";
export const PAGO_DE_CUOTA_ID = "64b6f56d8240f97daf393269";

// //! DEV
// export const MAIN_PROFILE_ID = "64e51bfb3205df8238058f5e";
// export const PAGO_DE_CUOTA_ID = "64e92d8f7bedbd575d5f04a5";
