import { Configuracion } from "../components/layout/configuracion/Configuracion"
import { MainView } from "../components/layout/mainView/MainView"

export const ConfigView = () => {
  return (
    <MainView >
        <Configuracion />
    </MainView>
  )
}
