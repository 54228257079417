import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { borderColor, pdfStyles } from '../../styles';

export const TableHeader = () => {
  const styles = StyleSheet.create({
    qty: {
      width: "25%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    description: {
      width: "30%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      // overflow: "hidden",
    },
    rate: {
      width: "25%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    amount: {
      width: "20%",
    },
  });

  return (
    <View style={pdfStyles.container}>
      <Text style={styles.qty}>Concepto</Text>
      <Text style={styles.description}>Descripción</Text>
      <Text style={styles.rate}>Método de pago</Text>
      <Text style={styles.amount}>Monto</Text>
    </View>
  );
};
