import { makeFloat } from "./thousandFormatter.js";

export const calculateCorrection = (item, newPayment, openABHistory, date) => {
  try {
    if (item) {
      let {
        _id,
        abHistoryId,
        payment: prevPayment,
        accountBoxId,
        operation,
      } = item;

      if (abHistoryId && accountBoxId) {
        const openABHistoryId = openABHistory._id;

        console.log(
          "openABHistoryId, abHistoryId",
          openABHistoryId,
          abHistoryId
        );

        if (openABHistoryId !== abHistoryId) {

          //* No es la ab abierta. Hay que crear correction.

          // Create a map for easy lookup of paymentMethodId
          const originalPaymentMap = new Map();
          prevPayment.forEach((p) =>
            originalPaymentMap.set(p.paymentMethodId, p.total)
          );

          const updatedPaymentMap = new Map();
          newPayment.forEach((p) =>
            updatedPaymentMap.set(p.paymentMethodId, p.total)
          );

          const corrections = [];

          // Check for changes in payment methods
          newPayment.forEach(({ paymentMethodId, total }) => {
            const originalTotal = originalPaymentMap.get(paymentMethodId) || 0;
            const difference = total - originalTotal;

            if (difference !== 0) {
              corrections.push({
                paymentMethodId,
                correction: difference,
              });
            }

            // Remove from original map once processed
            originalPaymentMap.delete(paymentMethodId);
          });

          // Any remaining methods in original map were removed in the update
          originalPaymentMap.forEach((total, paymentMethodId) => {
            corrections.push({
              paymentMethodId,
              correction: total * -1, // Compensate for the removal
            });
          });

          // Calculate the total correction (sum of all differences)
          const totalCorrection = corrections.reduce(
            (sum, p) => sum + p.correction,
            0
          );

          // Decide the operation type based on the total correction
          let correctionOperation = operation;
          if (totalCorrection < 0 && operation === "INGRESO") {
            correctionOperation = "EGRESO";
          } else if (totalCorrection > 0 && operation === "EGRESO") {
            correctionOperation = "INGRESO";
          }

          const correctionPayment = corrections.map(
            ({ paymentMethodId, correction }) => ({
              paymentMethodId,
              total: makeFloat(correction),
            })
          );

          const obj = {
            idMove: _id,
            abHistoryId: abHistoryId,
            accountBoxId,
            operation: correctionOperation,
            date,
            payment: correctionPayment,
            total: makeFloat(Math.abs(totalCorrection)),
          };

          return obj;
        }
      }
    }

    return null;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
