import { Button, Input, Popover, Tooltip } from "antd";
import { thousandFormatter } from "../../../utils/thousandFormatter.js";
import { AiOutlineSearch } from "react-icons/ai";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { isAvailable } from "../../../utils/isAvailable";
import { SECCION_PERMISOS } from "../../../assets/permisos-template";
import { FiEdit3, FiEye, FiUserPlus } from "react-icons/fi";
import { GlobalContext } from "../../context/GlobalContext";
import { MdOutlineDeleteOutline } from "react-icons/md";

export const columns = (
  setDrawerContent,
  deleteStudentResolver,
  setAlumnos,
  cursosPoll,
  studentsFees
) => {
  return [
    {
      title: "Nombre",
      dataIndex: "fullName",
      key: "fullName",
      render: (data, item) => {
        const cursos = getMora(item, studentsFees);

        if (cursos?.length) {
          let inDebt = cursos.filter(
            (curso) => curso.shouldHavePaid > curso.cuotasPagas
          );

          if (inDebt?.length > 0) {
            return (
              <span className="horizontal">
                <Popover
                  placement="topLeft"
                  title="En mora"
                  trigger="hover"
                  content={
                    <div className="columna">
                      {inDebt.map((curso, index) => {
                        return (
                          <span
                            className="horizontal"
                            key={`${item._id}-${index}`}
                          >
                            <p>{`${curso.name} - pagó ${curso.cuotasPagas} de ${curso.shouldHavePaid} cuotas`}</p>
                          </span>
                        );
                      })}
                    </div>
                  }
                >
                  <span className="horizontal" style={{ width: "fit-content" }}>
                    <p className="en-mora" >{data}</p>
                  </span>
                </Popover>
              </span>
            );
          }
        }

        return data;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar nombre"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.fullName.toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "DNI",
      dataIndex: "dni",
      width: "100px",
      key: "dni",
      render: (dataIndex) => (
        <p className="number-ff">{thousandFormatter(dataIndex, true)}</p>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar DNI"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record.dni.toLowerCase().startsWith(value.toLowerCase()),
      sorter: (a, b) => a.dni.localeCompare(b.dni),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Edad",
      dataIndex: "birthDay",
      key: "birthDay",
      width: "60px",
      render: (dataIndex) => {
        let date = dayjs(dataIndex, "DD-MM-YYYY");
        let diferencia = date.diff(dayjs(), "year") * -1;
        return <p className="number-ff">{diferencia}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Teléfono",
      dataIndex: "phoneNumbers",
      width: "120px",
      key: "phoneNumbers",
      render: (dataIndex) => (
        <div className="phone-number-layout">
          {dataIndex &&
            dataIndex?.map((phoneNumber) => {
              return (
                <p key={phoneNumber.phoneNumber} className="number-ff">
                  {phoneNumber.phoneNumber}
                </p>
              );
            })}
        </div>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "",
      width: "140px",
      key: "",
      render: (dataIndex, item) => (
        <ColumnaAcciones
          item={item}
          setDrawerContent={setDrawerContent}
          deleteStudentResolver={deleteStudentResolver}
          setAlumnos={setAlumnos}
          cursosPoll={cursosPoll}
        />
      ),
    },
  ];
};

const ColumnaAcciones = ({
  item,
  setDrawerContent,
  deleteStudentResolver,
  setAlumnos,
  cursosPoll,
}) => {
  const { messageApi, user } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);
  const [showAsignar, setShowAsignar] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteAlumno = (item) => {
    if (item._id) {
      setLoading(true);
      deleteStudentResolver({ variables: { studentId: item._id } })
        .then(() => {
          setAlumnos((prevState) =>
            prevState.filter((element) => element._id !== item._id)
          );

          cursosPoll.start(500);
          setTimeout(() => {
            cursosPoll.stop();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Hubo un error de conexión al eliminar el Alumno");
        })
        .finally(() => setLoading(false));
    } else {
      messageApi.info("Intenteló de nuevo");
    }
  };

  return (
    <div className="fila-acciones">
      {isAvailable(
        "students",
        SECCION_PERMISOS.ver,
        user?.rol?.permissions
      ) && (
        <Tooltip title="Ver detalles" placement="topRight">
          <span style={{ height: "20px" }}>
            <FiEye
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Ver Alumno",
                  item: item,
                });
              }}
            />
          </span>
        </Tooltip>
      )}
      {isAvailable(
        "students",
        SECCION_PERMISOS.editar,
        user?.rol?.permissions
      ) && (
        <Tooltip title="Editar" placement="topRight">
          <span style={{ height: "20px" }}>
            <FiEdit3
              className="icono-acciones"
              onClick={() => {
                setDrawerContent({
                  visible: true,
                  type: "Editar Alumno",
                  item: item,
                });
              }}
            />
          </span>
        </Tooltip>
      )}
      {isAvailable(
        "students",
        SECCION_PERMISOS.eliminar,
        user?.rol?.permissions
      ) &&
        item?.status === "ACTIVE" && (
          <Popover
            content={
              <div className="content-popover">
                <span className="popover-texto">{`¿Realmente desea desactivar el alumno ${item.fullName}?`}</span>
                <div
                  className="botones-wrapper-content"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="btn-guardar"
                    onClick={() => deleteAlumno(item)}
                    loading={loading}
                  >
                    Desactivar
                  </Button>
                  <Button onClick={() => setShowPopover(false)}>
                    Cancelar
                  </Button>
                </div>
              </div>
            }
            trigger="click"
            placement="left"
            open={showPopover}
          >
            <Tooltip title="Desactivar" placement="topRight">
              <span style={{ height: "20px" }}>
                <MdOutlineDeleteOutline
                  className="icono-acciones"
                  onClick={() => setShowPopover(true)}
                />
              </span>
            </Tooltip>
          </Popover>
        )}
      {isAvailable(
        "coursesConformation",
        SECCION_PERMISOS.editar,
        user?.rol?.permissions
      ) && (
        <Popover trigger="click" placement="left" open={showAsignar}>
          <Tooltip title="Gestionar cursos" placement="topRight">
            <span style={{ height: "20px" }}>
              <FiUserPlus
                className="icono-acciones"
                onClick={() =>
                  setDrawerContent({
                    visible: true,
                    type: "Gestionar Cursos Alumno",
                    item: item,
                  })
                }
              />
            </span>
          </Tooltip>
        </Popover>
      )}
    </div>
  );
};

const CURRENT_MONTH = dayjs().month() + 1;
const CURRENT_DAY = dayjs().date();

const getMora = (item, studentsFees) => {
  let dataFees = studentsFees?.getStudentsFeesResolver?.find(
    (element) => item._id === element._id
  );

  if (dataFees) {
    const cursos = dataFees?.student?.courses;

    if (cursos?.length > 0) {
      const resultCursos = [];

      let i = 0;
      for (i; i < cursos?.length; i++) {
        const { name, moves, amountOfFees, startDate } = cursos[i] || {};

        const startMonth = dayjs(startDate, "DD/MM/YYYY").month() + 1;

        let shouldHavePaid = 0;

        if (CURRENT_DAY > 10) {
          shouldHavePaid = CURRENT_MONTH - startMonth + 1;
        } else {
          shouldHavePaid = CURRENT_MONTH - startMonth;
        }

        if (name && moves?.length && amountOfFees && startDate) {
          let cuotasPagas = 0;
          moves.forEach((move) => {
            if (move.feeDetail?.fees) {
              cuotasPagas += move.feeDetail.fees.length;
            }
          });

          resultCursos.push({
            name,
            amountOfFees,
            cuotasPagas,
            startMonth,
            shouldHavePaid,
          });
        }
      }

      return resultCursos;
    }
  }

  return null;
};
