export const PERMISOS_TEMPLATE = [
  { name: "students", label: "Alumnos" },
  { name: "courses", label: "Cursos" },
  { name: "coursesSchedule", label: "Cursos horarios" },
  { name: "coursesConformation", label: "Cursos conformación" },
  { name: "stats", label: "Estadísticas" },
  { name: "instructors", label: "Instructores" },
  { name: "moves", label: "Finanzas" },
  { name: "transfers", label: "Transferencias" },
  { name: "users", label: "Usuarios" },
  { name: "settings", label: "Configuración" },
];

export const SECCION_PERMISOS = {
  modulo: 0,
  ver: 1,
  crear: 2,
  editar: 3,
  eliminar: 4,
};
