import { Form, Tabs } from "antd";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { AsignarCursos } from "./AsignarCursos";
import { DesafectarCursos } from "./DesafectarCursos";

export const GestionarCursosAlumno = () => {
  const [form] = Form.useForm();

  const { drawerContent, setDrawerContent } = useContext(GlobalContext);

  const items = [
    // {
    //   key: 1,
    //   label: "Asignar curso",
    //   children: <AsignarCursos />,
    // },
    {
      key: 1,
      label: "Desafectar curso",
      children: <DesafectarCursos />,
    },
  ];

  return <Tabs items={items} className="tabs-wrapper"/>;
};
