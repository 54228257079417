import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { appReload } from "../../../utils/appReload";
import { FiX } from "react-icons/fi";
import { CgMenu } from "react-icons/cg";

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    appReload();
  };

  return (
    <header className="header-student">
      {!showMenu ? (
        <CgMenu className="pointer fade-in" onClick={() => setShowMenu(true)} />
      ) : (
        <>
          <FiX className="pointer fade-in" onClick={() => setShowMenu(false)} />
          <div className="student-menu-wrapper fade-in">
            <span
              className="student-menu-item"
              onClick={() => {
                navigate("/student");
                setShowMenu(false);
              }}
            >
              Mis cursos
            </span>
            <span className="student-menu-item" onClick={() => handleLogout()}>
              Cerrar sesión
            </span>
          </div>
        </>
      )}
      <p
        className="titulo-sistema pointer"
        style={{ fontSize: 20 }}
        onClick={() => navigate("/student")}
      >
        CÁNADEZ
      </p>
    </header>
  );
};
