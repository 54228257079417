import { Button, DatePicker, Input } from "antd";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { useLazyQuery } from "@apollo/client";
import { GET_BALANCE_BY_CATEGORY } from "../../../graphql/query/Finanzas";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts";
import dayjs from "dayjs";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { ErrorMessage } from "../../ui/error/ErrorMessage";
import "./dashboard.css";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

const getColor = (i) => {
  const colores = [
    "#ff8097",
    "#84b6f4",
    "#877FD7",
    "#7AB495",
    "#E1A7CA",
    "#ffca99",
    "#a3ffac",
    "#cce5ff",
    "#eaffc2",
    "#fdfd96",
  ];

  let indice = i % 10;
  return colores[indice];
};

export const Dashboard = () => {
  const [dates, setDates] = useState({
    dateFrom: null,
    dateTo: null,
  });

  const [tempDates, setTempDates] = useState({
    dateFrom: null,
    dateTo: null,
  });

  const [getBalanceByCategory, { loading, error, data }] = useLazyQuery(
    GET_BALANCE_BY_CATEGORY,
    { fetchPolicy: "network-only" }
  );

  const getBarChartData = () => {
    if (data?.getBalanceByAccountBoxResolver) {
      let array = data.getBalanceByAccountBoxResolver;

      let object = { name: "" };

      array?.map((element) => {
        object[element.accountBoxName] = element.total;
      });

      return [object];
    }

    return [];
  };

  const getTotal = () => {
    if (data?.getBalanceByAccountBoxResolver) {
      let array = data.getBalanceByAccountBoxResolver;
      let totales = array?.map((element) => {
        return element.total;
      });
      const total = totales.reduce((sumaParcial, a) => sumaParcial + a, 0);

      return total;
    } else {
      return 0;
    }
  };

  const handleCalcular = () => {
    setDates({ ...tempDates });

    getBalanceByCategory({ variables: { ...tempDates } });
  };

  const handleDate = (value, tipo) => {
    let fecha = null;

    if (value) {
      if (tipo === "dateTo") {
        fecha = value.endOf("day");
      } else {
        fecha = value;
      }

      fecha = fecha.format("x");
    }

    setTempDates((prevState) => {
      return { ...prevState, [tipo]: fecha };
    });
  };

  useEffect(() => {
    getBalanceByCategory({ variables: dates });
  }, []);

  if (error) return <ErrorMessage />;

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Estadísticas</p>
      </div>
      <div className="modulo-content">
        <div className="dashboard-layout">
          <div className="columna-dashboard">
            <b>Total</b>
            <span className="header-columna">
              <DatePicker
                format={DATE_FORMAT}
                placeholder="Desde"
                onChange={(date) => handleDate(date, "dateFrom")}
              />
              <DatePicker
                format={DATE_FORMAT}
                placeholder="Hasta"
                onChange={(date) => handleDate(date, "dateTo")}
              />
              <Button className="btn-guardar" onClick={handleCalcular}>
                Calcular
              </Button>
            </span>
            {loading ? (
              <BlobLoader />
            ) : (
              <div className="total-container">
                <p className="total-p">{thousandFormatter(getTotal())}</p>
              </div>
            )}
          </div>
          <div className="columna-dashboard">
            <b>Total por categoría</b>
            {loading ? (
              <BlobLoader />
            ) : (
              <div className="chart-container">
                <ResponsiveContainer width="100%" aspect="1.3">
                  <BarChart
                    margin={{
                      top: 24,
                      right: 24,
                      left: 36,
                      bottom: 24,
                    }}
                    data={getBarChartData()}
                    maxBarSize={50}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="accountBoxName" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine y={0} stroke="#000" />
                    {data?.getBalanceByAccountBoxResolver?.map(
                      (element, index) => {
                        return (
                          <Bar
                            dataKey={element.accountBoxName}
                            fill={getColor(index)}
                            key={`bar-${index}`}
                          />
                        );
                      }
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
