import { useNavigate } from "react-router-dom";

export const CursoCard = ({ data }) => {

  const navigate = useNavigate();

  const handleGoToCurso = () => {
    navigate(`/student/curso?id=${data?.courseInfo?._id}`);
  };

  return (
    <div className="curso-wrapper" onClick={handleGoToCurso}>
      <p className="curso-title">{data?.courseInfo?.name}</p>
      <p className="turno-subtitle">{data?.turnInfo?.name}</p>
    </div>
  );
};
