import CryptoJS from "crypto-js";

export const handleUser = () => {
  const passphrase = "Canadez-2023-03-20";

  let token = localStorage.getItem("token");

  try {
    if (token) {
      let decryptedToken = CryptoJS.AES.decrypt(token, passphrase).toString(
        CryptoJS.enc.Utf8
      );

      if (!decryptedToken) {
        localStorage.removeItem("token");
        return null;
      }

      decryptedToken = JSON.parse(decryptedToken);

      if (!decryptedToken._id) {
        localStorage.removeItem("token");
        return null;
      }

      // decryptedToken.type = "student";

      return decryptedToken;
    } else {
      localStorage.removeItem("token");
      return null;
    }
  } catch (error) {
    localStorage.removeItem("token");
    return null;
  }
};
