import { MainView } from '../components/layout/mainView/MainView'
import { Usuarios } from '../components/layout/usuarios/Usuarios'

export const UsuariosView = () => {
  return (
    <MainView >
        <Usuarios />
    </MainView>
  )
}
