import { Inicio } from "../components/layout/inicio/Inicio";
import { MainView } from "../components/layout/mainView/MainView";

export const InicioView = () => {
  return (
    <MainView>
      <Inicio />
    </MainView>
  );
};
