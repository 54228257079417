import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { borderColor } from "../../styles";
import { useMemo } from "react";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "semibold",
  },
  description: {
    width: "70%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    fontStyle: "semibold",
  },
  total: {
    width: "30%",
    textAlign: "right",
    paddingRight: 8,
    fontStyle: "semibold",
  },
});

export const TableFooter = ({ data }) => {
  const TOTAL = useMemo(() => {
    return data?.reduce((result, item) => {
      return result + item.total;
    }, 0);
  }, [data]);

  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{`$${thousandFormatter(TOTAL)}`}</Text>
    </View>
  );
};
