import countries from "i18n-iso-countries";
import esLocale from "i18n-iso-countries/langs/es.json";

export const getCountries = () => {
  countries.registerLocale(esLocale);
  const countryObj = countries.getNames("es", { select: "official" });

  try {
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });

    return countryArr;
  } catch {
    return { label: "Argentina", value: "AR" };
  }
};
