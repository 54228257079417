import { Button, Input, Popover, Tooltip } from "antd";
import dayjs from "dayjs";
import { thousandFormatter } from "../../../utils/thousandFormatter.js";
import { useContext, useState } from "react";
import { isAvailable } from "../../../utils/isAvailable.js";
import { SECCION_PERMISOS } from "../../../assets/permisos-template.js";
import { FiEye } from "react-icons/fi";
import { GlobalContext } from "../../context/GlobalContext.js";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useMutation } from "@apollo/client";
import { DELETE_TRANSFER } from "../../../graphql/mutation/Transferencias.js";
import { AiOutlineSearch } from "react-icons/ai";

export const getAccountBox = (id, accountingBoxes) => {
  if (!accountingBoxes) {
    return "";
  }

  if (id) {
    const method = accountingBoxes?.find((element) => element._id === id);

    return method;
  }

  return "";
};

export const getPaymentMethod = (id, paymentMethods) => {
  if (!paymentMethods) {
    return "";
  }

  if (id) {
    const method = paymentMethods?.find((element) => element._id === id);

    return method;
  }

  return "";
};

const ColumnaAcciones = ({
  item,
  setDataDetalle,
  setShowNewTransfer,
  setTransferencias,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const { user, messageApi } = useContext(GlobalContext);

  const [deleteTransferResolver, { data, loading, error }] = useMutation(
    DELETE_TRANSFER,
    { fetchPolicy: "network-only" }
  );

  const handleDelete = () => {
    deleteTransferResolver({ variables: { _id: item?._id } })
      .then((res) => {
        if (res?.data?.deleteTransferResolver?.status === 200) {
          messageApi.success("Transferencias eliminada correctamente");
          setShowPopover(false);
          setTransferencias((prevState) => {
            let array = prevState.filter((element) => element._id !== item._id);

            return array;
          });
        } else {
          messageApi.error("Ocurrió un error al eliminar una transferencia");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al eliminar una transferencia");
      });
  };

  return (
    <div className="fila-acciones">
      {isAvailable(
        "transfers",
        SECCION_PERMISOS.ver,
        user?.rol?.permissions
      ) && (
        <Tooltip title="Ver detalles" placement="topRight">
          <span style={{ height: "20px" }}>
            <FiEye
              className="icono-acciones"
              onClick={() => {
                setDataDetalle(item);
              }}
            />
          </span>
        </Tooltip>
      )}
      {/* {isAvailable(
        "transfers",
        SECCION_PERMISOS.editar,
        user?.rol?.permissions
      ) && (
        <Tooltip title="Editar" placement="topRight">
          <span style={{ height: "20px" }}>
            <FiEdit3
              className="icono-acciones"
              onClick={() => {
                setShowNewTransfer({
                  open: true,
                  type: "edit",
                  item: item,
                });
              }}
            />
          </span>
        </Tooltip>
      )} */}
      {isAvailable(
        "transfers",
        SECCION_PERMISOS.eliminar,
        user?.rol?.permissions
      ) && (
        <Popover
          content={
            <div className="content-popover" style={{ paddingBottom: 8 }}>
              <span className="popover-texto">{`¿Realmente desea eliminar esta transferencia?`}</span>
              <div
                className="botones-wrapper-content"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  className="btn-guardar"
                  loading={loading}
                  onClick={handleDelete}
                >
                  Eliminar
                </Button>
                <Button onClick={() => setShowPopover(false)}>Cancelar</Button>
              </div>
            </div>
          }
          trigger="click"
          placement="left"
          open={showPopover}
        >
          <Tooltip title="Eliminar" placement="topRight">
            <span style={{ height: "20px" }}>
              <MdOutlineDeleteOutline
                className="icono-acciones"
                onClick={() => setShowPopover(true)}
              />
            </span>
          </Tooltip>
        </Popover>
      )}
    </div>
  );
};

export const transfersColumns = (
  setDataDetalle,
  config,
  setShowNewTransfer,
  setTransferencias
) => {
  return [
    {
      dataIndex: "abFrom",
      key: "abFrom",
      title: "Caja origen",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar caja"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record?.abFrom?.name?.toLowerCase()?.includes(value?.toLowerCase()),
    },
    {
      dataIndex: "abTo",
      key: "abTo",
      title: "Caja destino",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar caja"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) =>
        record?.abTo?.name?.toLowerCase()?.includes(value?.toLowerCase()),
    },
    {
      dataIndex: "createdAt",
      key: "createdAt",
      title: "Fecha",
      width: 120,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">
              {dayjs(data, "x")?.format("DD/MM/YYYY")}
            </p>
          );
        } else {
          return false;
        }
      },
    },
    {
      dataIndex: "total",
      key: "total",
      title: "Monto",
      align: "right",
      render: (data) => {
        return (
          <p
            className="number-ff"
            style={data >= 0 ? { color: "#00A150" } : { color: "#FF4D48" }}
          >
            {thousandFormatter(data)}
          </p>
        );
      },
    },
    {
      dataIndex: "",
      key: "",
      title: "Acciones",
      align: "center",
      width: 120,
      render: (data, item) => {
        return (
          <ColumnaAcciones
            item={item}
            setDataDetalle={setDataDetalle}
            setShowNewTransfer={setShowNewTransfer}
            setTransferencias={setTransferencias}
          />
        );
      },
    },
  ];
};
