import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    paddingBottom: 3,
    fontSize: 12,
    // fontFamily: "Helvetica",
  },
});

export const Detail = ({ data, alumnos }) => {
  const getAlumno = () => {
    if (alumnos) {
      return (
        alumnos?.find((element) => element._id === data?.studentId)?.fullName ||
        ""
      );
    }

    return "";
  };

  return (
    <View style={styles.headerContainer}>
      {data?.studentId && (
        <Text style={styles.billTo}>{`Recibimos de ${getAlumno()}`}</Text>
      )}
      {/* <Text>{data?.concept?.name || ""}</Text>
      <Text>{data?.address}</Text>
      <Text>{data?.phone}</Text>
      <Text>{data?.email}</Text> */}
    </View>
  );
};
