import { MainView } from "../components/layout/mainView/MainView"
import { Turno } from "../components/layout/turno/Turno"


export const TurnView = () => {
  return (
    <MainView>
        <Turno />
    </MainView>
  )
}
