import { Button, Drawer, Table } from "antd";
import { asistenciasColumns } from "./utils";
import { useState } from "react";
import { NewAsistencia } from "./drawerContent/NewAsistencia";

export const Asistencias = ({ turnConformation, data }) => {
  const [asistencias, setAsistencias] = useState([]);

  const [drawerData, setDrawerData] = useState({
    visible: false,
    type: "",
    item: null,
  });

  const handleDrawerContent = () => {
    switch (drawerData?.type) {
      case "new":
        return (
          <NewAsistencia
            turnConformation={turnConformation}
            setDrawerData={setDrawerData}
            data={data}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <span className="horizontal fade-in" style={{ position: "absolute" }}>
        <Button
          style={{ marginLeft: "auto", top: -54, right: 0 }}
          onClick={() => {
            setDrawerData({ visible: true, type: "new", item: data });
          }}
        >
          Cargar asistencias
        </Button>
      </span>
      <Table
        columns={asistenciasColumns(drawerData, setDrawerData, asistencias)}
        dataSource={asistencias}
        rowKey={"_id"}
      />
      <Drawer
        open={drawerData.visible}
        closable={false}
        width={["new", "edit"].includes(drawerData?.type) ? 600 : 450}
      >
        <div className="form-wrapper"> {handleDrawerContent()}</div>
      </Drawer>
    </div>
  );
};
