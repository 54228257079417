import { gql } from "@apollo/client";

export const CREATE_LOCATION = gql`
  mutation newLocationResolver($locationInput: locationInput) {
    newLocationResolver(locationInput: $locationInput) {
      status
      message
    }
  }
`;

export const CREATE_CURRENCY = gql`
  mutation newCurrencyResolver($currencyInput: currencyInput) {
    newCurrencyResolver(currencyInput: $currencyInput) {
      message
      status
    }
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation newPaymentMethodResolver($paymentMethodInput: paymentMethodInput) {
    newPaymentMethodResolver(paymentMethodInput: $paymentMethodInput) {
      message
      status
    }
  }
`;

export const CREATE_CONCEPT = gql`
  mutation newConceptResolver($conceptInput: conceptInput) {
    newConceptResolver(conceptInput: $conceptInput) {
      status
      message
    }
  }
`;

export const CREATE_WITHDRAWAL_REASON = gql`
  mutation newWithdrawalReasonResolver($withdrawalInput: withdrawalInput) {
    newWithdrawalReasonResolver(withdrawalInput: $withdrawalInput) {
      message
      status
    }
  }
`;

export const CREATE_ACCOUNTING_BOX = gql`
  mutation newAccountingBoxResolver($accountingBoxInput: accountingBoxInput) {
    newAccountingBoxResolver(accountingBoxInput: $accountingBoxInput) {
      message
      status
    }
  }
`;

export const CREATE_AREA = gql`
  mutation NewAreaResolver($areaInput: areaInput) {
    newAreaResolver(areaInput: $areaInput) {
      status
      message
    }
  }
`;

export const CREATE_CLASSROOM = gql`
  mutation NewClassroomResolver($classroomInput: classroomInput) {
    newClassroomResolver(classroomInput: $classroomInput) {
      status
      message
    }
  }
`;
