import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { borderColor } from "../../styles";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#e8e8e8",
    borderBottomWidth: 1,
    // alignItems: "center",
    // minHeight: 24,
    fontSize: 11,
    marginTop: 4,
  },
  qty: {
    width: "70%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
  },
  amount: {
    width: "30%",
    textAlign: "right",
    padding: "4px 8px 4px 8px",
    marginBottom: 4,
  },
});

export const TableRow = ({ data }) => {
  return data?.map((element) => {
    return (
      <View style={styles.row}>
        <Text style={styles.qty}>{element?.name}</Text>
        <Text style={styles.amount}>{`$${thousandFormatter(
          element?.total
        )}`}</Text>
      </View>
    );
  });
};
