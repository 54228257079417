export const deleteArrayElement = (prevState, id) => {
  let array = [...prevState];
  let indice = array.findIndex((element) => element._id === id);

  if (indice > -1) {
    array[indice] = { ...array[indice], status: "INACTIVE" };
  }

  return array;
};
