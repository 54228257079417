import { thousandFormatter } from "../thousandFormatter.js";

export const addPaymentMethod = (setPayment) => {
  setPayment((prevState) => {
    let array = [...prevState];

    array.push({ paymentMethodId: null, total: 0 });

    return array;
  });
};

export const deletePaymentMethod = (setPayment, index) => {
  setPayment((prevState) => {
    let array = prevState.filter(
      (element, elementIndex) => elementIndex !== index
    );

    return array;
  });
};

export const handlePaymentMethod = (setPayment, index, value) => {
  setPayment((prevState) => {
    let array = [...prevState];

    array[index] = { ...array[index], paymentMethodId: value };

    return array;
  });
};

export const handlePaymentValue = (setPayment, index, value) => {
  setPayment((prevState) => {
    let array = [...prevState];

    array[index] = {
      ...array[index],
      total: value?.toString()?.replace(",", "."),
    };

    return array;
  });
};

export const validatePayment = (payment, messageApi) => {
  if (!payment || payment.length < 1) {
    messageApi.info("Incluya algun método de pago");
    return false;
  }

  if (
    payment.some((element) => {
      if (!element.paymentMethodId || element.total == null) {
        return true;
      }
      return false;
    })
  ) {
    messageApi.info("Cada método de pago debe tener un valor y un total");
    return false;
  }

  return true;
};

export const getPaymentTotal = (payment) => {
  if (payment?.length > 0) {
    let total = 0;
    payment.forEach((element) => {
      total += parseFloat(element.total);
    });

    return total;
  }

  return 0;
};

export const filterOption = (input, option) => {
  return (option.label ?? "").toLowerCase().includes(input.toLowerCase());
};
