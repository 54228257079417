import { Finanzas } from "../components/layout/finanzas/Finanzas";
import { MainView } from "../components/layout/mainView/MainView";

export const FinanzasView = () => {
  return (
    <MainView>
      <Finanzas />
    </MainView>
  );
};
