import { Button, Input } from "antd";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import * as XLSX from "xlsx";

export const columns = (alumnos) => {
  return [
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar área"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return record.area?.name?.toLowerCase()?.includes(value.toLowerCase());
      },
    },
    {
      title: "Concepto",
      dataIndex: "concept",
      key: "concept",
      render: (data) => data?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar concepto"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return record.concept?.name
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
      },
    },
    {
      title: "Alumno",
      dataIndex: "studentId",
      key: "studentId",
      render: (data) =>
        alumnos?.find((element) => element._id === data)?.fullName,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar alumno"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return alumnos
          ?.find((element) => element._id === record.studentId)
          ?.fullName?.toLowerCase()
          ?.includes(value.toLowerCase());
      },
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: 90,
      render: (data) => {
        if (data) {
          return (
            <p className="number-ff">
              {dayjs(data, "x")?.format("DD/MM/YYYY")}
            </p>
          );
        } else {
          return false;
        }
      },
    },
    {
      title: "Monto",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (data, item) => (
        <p
          className="number-ff"
          style={
            item.operation
              ? item.operation === "INGRESO"
                ? { color: "#00A150" }
                : { color: "#FF4D48" }
              : { color: "black" }
          }
        >
          {thousandFormatter(data)}
        </p>
      ),
    },
  ];
};

const handleSignoMonto = (monto, operacion) => {
  if (monto) {
    if (operacion?.toUpperCase() === "EGRESO") {
      monto = monto * -1;
    }

    return monto;
  }

  return 0;
};

export const handleExportar = (data, paymentMethods, messageApi) => {
  // FORMATEAR
  try {
    const formattedData = data.map((element) => {
      let obj = {
        caja: element.accountingBox?.name || "",
        area: element.area?.name || "",
        concepto: element.concept?.name || "",
        fecha: dayjs(element.date, "x")?.format("DD/MM/YYYY") || "",
        moneda: element.currency?.name || "",
        simbolo: element.currency?.symbol || "",
        descripcion: element.description || "",
        operacion: element.operation || "",
        "fecha creacion": dayjs(element.createdAt, "x")?.format("DD/MM/YYYY"),
        TOTAL: handleSignoMonto(element.total, element.operation),
      };

      paymentMethods?.forEach((metodo) => {
        obj[metodo.name] = handleSignoMonto(
          element.payment?.find((pago) => metodo._id === pago.paymentMethodId)
            ?.total,
          element.operation
        );

        // element.payment?.find((pago) => metodo._id === pago.paymentMethodId)
        //   ?.total || 0;
      });

      return obj;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "hoja1");
    let name = `Movimientos-${dayjs().format("DD-MM-YYYY")}.xlsx`;
    XLSX.writeFile(workbook, name);
  } catch (error) {
    console.log(error);
    messageApi.error("Ocurrió un error el generar el excel");
  }
};
