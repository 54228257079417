import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardView } from "../../views/DashboardView";
import { CursosView } from "../../views/CursosView";
import { ProfesoresView } from "../../views/ProfesoresView";
import { LoginView } from "../../views/LoginView";
import { PrivateRouter } from "./PrivateRouter";
import { FinanzasView } from "../../views/FinanzasView";
import { AlumnosView } from "../../views/AlumnosView";
import { ConfigView } from "../../views/ConfigView";
import { UsuariosView } from "../../views/UsuariosView";
import { InicioView } from "../../views/InicioView";
import { StudentRouter } from "./StudentRouter";
import { InstructorRouter } from "./InstructorRouter";
import { InstructorView } from "../../views/InstructorView";
import { TransfersView } from "../../views/TransfersView";
import { StudentHome } from "../layout/student/StudentHome";
import { CursoData } from "../layout/student/CursoData";
import { StudentView } from "../../views/StudentView";
import { TurnView } from "../../views/TurnView";
import { CajasView } from "../../views/CajasView";

export const AppRouter = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRouter>
              <InicioView />
            </PrivateRouter>
          }
        />
        <Route path="/login" element={<LoginView />} />
        <Route
          path="/estadisticas"
          element={
            <PrivateRouter seccion="stats">
              <DashboardView />
            </PrivateRouter>
          }
        />
        <Route
          path="/alumnos"
          element={
            <PrivateRouter seccion="students">
              <AlumnosView />
            </PrivateRouter>
          }
        />
        <Route
          path="/instructores"
          element={
            <PrivateRouter seccion="instructors">
              <ProfesoresView />
            </PrivateRouter>
          }
        />
        <Route
          path="/cursos"
          element={
            <PrivateRouter seccion="courses">
              <CursosView />
            </PrivateRouter>
          }
        />
        <Route
          path="/finanzas"
          element={
            <PrivateRouter seccion="moves">
              <FinanzasView />
            </PrivateRouter>
          }
        />
        <Route
          path="/finanzas/cajas"
          element={
            <PrivateRouter seccion="settings">
              <CajasView />
            </PrivateRouter>
          }
        />
        <Route
          path="/transferencias"
          element={
            <PrivateRouter seccion="transfers">
              <TransfersView />
            </PrivateRouter>
          }
        />
        <Route
          path="/configuracion"
          element={
            <PrivateRouter seccion="settings">
              <ConfigView />
            </PrivateRouter>
          }
        />
        <Route
          path="/usuarios"
          element={
            <PrivateRouter seccion="users">
              <UsuariosView />
            </PrivateRouter>
          }
        />
        <Route
          path="/student"
          element={
            <StudentRouter>
              <StudentView>
                <StudentHome />
              </StudentView>
            </StudentRouter>
          }
        />
        <Route
          path="/student/curso"
          element={
            <StudentRouter>
              <StudentView>
                <CursoData />
              </StudentView>
            </StudentRouter>
          }
        />
        <Route
          path="/instructor"
          element={
            <InstructorRouter>
              <InstructorView />
            </InstructorRouter>
          }
        />

        <Route
          path="/turno"
          element={
            <PrivateRouter>
              <TurnView />
            </PrivateRouter>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
