import { useContext, useMemo } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { ErrorMessage } from "../../ui/error/ErrorMessage";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { CursoCard } from "./CursoCard";
import "./student.css";
import "./responsive.css";
import { StudentContext } from "../../context/StudentContext";

export const StudentHome = () => {
  const { user, messageApi } = useContext(GlobalContext);

  const { data, loading, error, dataInfo, loadingInfo, errorInfo } =
    useContext(StudentContext);

  const cursos = useMemo(() => {
    if (data?.getCoursesByStudentIdResolver) {
      return data.getCoursesByStudentIdResolver;
    }
  }, [data]);

  if (error) return <ErrorMessage />;

  if (loading) return <BlobLoader />;

  return (
    <div className="student-home-wrapper">
      <p className="mobile-section-title">Mis cursos</p>
      <div className="cursos-layout">
        {cursos?.map((element) => {
          return <CursoCard data={element} key={element._id} />;
        })}
      </div>
    </div>
  );
};
