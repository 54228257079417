import { Button, Input, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../../ui/loader/BlobLoader";
import { thousandFormatter } from "../../../utils/thousandFormatter";
import { getTotalMetodosDePagos } from "../../../utils/getTotalMetodosDePagos";
import { NEW_TRANSFER } from "../../../graphql/mutation/Transferencias";
import { GET_MOVES_DETAIL } from "../../../graphql/query/Cajas";

export const NewTransfer = ({
  showNewTransfer,
  setShowNewTransfer,
  fetchCajaData,
}) => {
  const { config, messageApi } = useContext(GlobalContext);

  const [getMovesDetailResolver, { data, loading, error }] = useLazyQuery(
    GET_MOVES_DETAIL,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    newTransferResolver,
    { data: dataNew, loading: loadingNew, error: errorNew },
  ] = useMutation(NEW_TRANSFER, {
    fetchPolicy: "network-only",
  });

  const [selectedABFrom, setSelectedABFrom] = useState(null);
  const [selectedABTo, setSelectedABTo] = useState(null);

  const [resultados, setResultados] = useState([]);

  const abOptions = useMemo(() => {
    if (config?.accountingBoxes && Array.isArray(config?.accountingBoxes)) {
      return config.accountingBoxes.map((caja) => {
        return {
          value: caja._id,
          label: caja.name,
          isTesoreria: caja.isTesoreria,
        };
      });
    }

    return [];
  }, [config]);

  const handleGuardarTransferencia = () => {
    if (!showNewTransfer?.item?.abHistoryId) {
      messageApi.info("Es necesario un id de cierre");
      return;
    }

    if (!selectedABFrom || !selectedABTo) {
      messageApi.info("Necesita seleccionar caja de origen y destino");
      return;
    }

    if (selectedABFrom === selectedABTo) {
      messageApi.info("Cajas de destino y de origen no pueden ser iguales");
      return;
    }

    if (resultados?.length < 1) {
      messageApi.info("No hay conceptos que transferir");
      return;
    }

    if (
      resultados.some((concepto) => {
        if (concepto.amount == null) {
          messageApi.info(`${concepto?.name} necesita un valor`);
          return true;
        }

        if (isNaN(concepto.amount)) {
          messageApi.info(`${concepto?.name} no tiene un número`);
          return true;
        }

        if (concepto.amount < 0) {
          messageApi.info(
            `${concepto?.name} no puede contener números negativos`
          );
          return true;
        }

        if (concepto.amount > concepto.total && concepto.amount != 0) {
          messageApi.info(
            `${concepto?.name} no puede ser superior al valor total`
          );
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    const filteredPayment = resultados
      .map((element) => {
        return { paymentMethodId: element._id, amount: element.amount || 0 };
      })
      .filter((concepto) => concepto.amount !== 0);

    if (filteredPayment?.length < 1) {
      messageApi.info("No se permite crear transferencias sin valor");
      return;
    }

    let obj = {
      abFrom: showNewTransfer?.item?.accountBoxId,
      abTo: selectedABTo,
      transferInput: filteredPayment,
      total: filteredPayment?.reduce((result, item) => {
        return (result += parseFloat(item.amount));
      }, 0),
      abHistoryId: showNewTransfer?.item?.abHistoryId,
    };

    if (showNewTransfer?.type === "edit") {
      // updateTransferResolver({
      //   variables: {
      //     ...obj,
      //     _id: showNewTransfer.item?._id,
      //     createdAt: showNewTransfer.item?.createdAt,
      //   },
      // })
      //   .then((res) => {
      //     if (res?.data?.updateTransferResolver?.status === 200) {
      //       messageApi.success("Transferencia actualizada correctamente");
      //       setTransferencias((prevState) => {
      //         let array = prevState.map((element) => {
      //           if (element._id === showNewTransfer.item?._id) {
      //             return {
      //               ...obj,
      //               _id: showNewTransfer.item?._id,
      //               createdAt: showNewTransfer.item?.createdAt,
      //             };
      //           } else {
      //             return element;
      //           }
      //         });
      //         return array;
      //       });
      //       setShowNewTransfer({ open: false, type: null, item: null });
      //     } else {
      //       messageApi.error("Ocurrió un error al editar una transferencia");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     messageApi.error("Ocurrió un error al editar una transferencia");
      //   });
    } else {
      newTransferResolver({ variables: obj })
        .then((res) => {
          if (res?.data?.newTransferResolver?.status === 200) {
            messageApi.success("Transferencia creada correctamente");
            // setTransferencias((prevState) => {
            //   return [
            //     {
            //       ...obj,
            //       _id: res.data.newTransferResolver.message,
            //       createdAt: Date.now(),
            //     },
            //     ...prevState,
            //   ];
            // });

            fetchCajaData();

            setShowNewTransfer({ open: false, type: null, item: null });
          } else {
            messageApi.error("Ocurrió un error al crear una transferencia");
          }
        })
        .catch((err) => {
          console.log(err);
          messageApi.error("Ocurrió un error al crear una transferencia");
        });
    }
  };

  const handleValues = (value, id) => {
    if (id) {
      let parsedValue = parseFloat(value?.toString()?.replace(",", ".")) || 0;

      setResultados((prevState) => {
        return prevState.map((concepto) => {
          if (concepto._id === id) {
            return { ...concepto, amount: parsedValue };
          } else {
            return concepto;
          }
        });
      });
    }
  };

  const conceptos = useMemo(() => {
    return getTotalMetodosDePagos(data?.getMovesDetailResolver, config);
  }, [data, config]);

  useEffect(() => {
    if (!selectedABTo) return;

    if (!showNewTransfer?.item) return;

    const { abHistoryId, accountBoxId } = showNewTransfer?.item;

    if (abHistoryId && accountBoxId) {
      getMovesDetailResolver({
        variables: {
          accountBoxId,
          abHistoryId,
        },
      });
    }
  }, [selectedABTo, showNewTransfer]);

  useEffect(() => {
    setResultados(
      conceptos?.map((element) => {
        return { ...element, amount: 0 };
      })
    );
    // }
  }, [conceptos]);

  useEffect(() => {
    const { type, item } = showNewTransfer;

    if (type === "new" && item) {
      setSelectedABFrom(item?.accountBoxId || null);

      const tesoreriaId = abOptions?.find((element) => element.isTesoreria);

      setSelectedABTo(tesoreriaId || null);
    }

    // if (type === "edit" && item) {
    //   setSelectedABFrom(item.abFrom?._id || null);
    //   setSelectedABTo(item.abTo?._id || null);
    //   setResultados(item.transferInput || []);
    // }
  }, [showNewTransfer, abOptions]);

  return (
    <div className="modal-transferencia-wrapper">
      <span className="horizontal gap16" style={{ marginTop: 8 }}>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja origen</p>
          <Select
            style={{ width: 250 }}
            options={abOptions || []}
            value={selectedABFrom}
            disabled
            // onChange={(v) => setSelectedABFrom(v)}
            placeholder="Seleccionar caja origen"
          />
        </span>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja destino</p>
          <Select
            style={{ width: 250 }}
            options={
              abOptions?.filter((element) => {
                return element.value !== selectedABFrom;
              }) || []
            }
            disabled
            value={selectedABTo}
            onChange={(v) => setSelectedABTo(v)}
            placeholder="Seleccionar caja destino"
          />
        </span>
      </span>
      {loading ? (
        <BlobLoader />
      ) : (
        <div className="concepts-grid">
          <span
            className="transferencia-conceptos-fila"
            style={{ marginTop: 16, fontWeight: 500 }}
          >
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Método de pago
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Disponible
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              A transferir
            </p>
          </span>
          {resultados?.map((concepto) => {
            return (
              <span
                className="transferencia-conceptos-fila"
                key={concepto?._id}
              >
                <p className="transferencia-columna">{concepto?.name}</p>
                <p
                  className="transferencia-columna  number-ff"
                  style={{ textAlign: "right" }}
                >
                  {thousandFormatter(concepto?.total)}
                </p>
                <span className="transferencia-columna">
                  <Input
                    type="number"
                    value={concepto?.amount}
                    onChange={(v) => handleValues(v.target.value, concepto._id)}
                  />
                </span>
              </span>
            );
          })}
        </div>
      )}
      <div
        className="horizontal gap24"
        style={{
          marginLeft: "auto",
          width: "fit-content",
          marginTop: 16,
        }}
      >
        <Button
          className="btn-guardar"
          onClick={handleGuardarTransferencia}
          loading={loadingNew}
        >
          Guardar
        </Button>
        <Button
          className="btn-cancelar"
          onClick={() =>
            setShowNewTransfer({ open: false, type: null, item: null })
          }
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
