import { useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select, Tag } from "antd";
import { useContext, useState } from "react";
import { CREATE_STUDENT } from "../../../graphql/mutation/Alumnos";
import { getCountries } from "../../../utils/getCountries";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import { getCities } from "../../../utils/getCities";
import dayjs from "dayjs";
import {
  studentAddTurn,
  studentDeleteTurn,
} from "../../../utils/manageAlumnosTurnos";
import { FiX } from "react-icons/fi";
import { MAIN_CITY_ID } from "../../../utils/relevantIds";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const CreateAlumno = () => {
  const { setDrawerContent, alumnos, setAlumnos, messageApi, cursos, config } =
    useContext(GlobalContext);
  const [form] = Form.useForm();
  const [turnos, setTurnos] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedTurn, setSelectedTurn] = useState(null);

  const [newStudentResolver, { data, loading, error }] = useMutation(
    CREATE_STUDENT,
    {
      onCompleted: (data) => {
        if (data?.newStudentResolver) {
          if (data.newStudentResolver.fullName) {
            let array = [...alumnos];
            array.push(data.newStudentResolver);

            setAlumnos(array);

            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
            messageApi.success("Alumno creado correctamente");
          }
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar un alumno!");
      },
    }
  );

  const onFinish = (values) => {
    let student;

    if (values) {
      let addressInfo = {};
      if (values.street) {
        addressInfo.street = values.street;
      }
      if (values.cp) {
        addressInfo.cp = values.cp;
      }
      if (values.city) {
        addressInfo.city = values.city;
      }

      let phoneNumbers = [];
      if (values.phoneNumber) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber });
      }
      if (values.phoneNumber2) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber2 });
      }

      student = {
        fullName: values.fullName ? values.fullName : "",
        dni: values.dni ? values.dni : "",
        birthDay: values.birthDay
          ? dayjs(values.birthDay).format("DD-MM-YYYY")
          : "",
        email: values.email ? values.email : "",
        nationality: values.nationality ? values.nationality : "AR",
        gender: values.gender ? values.gender : "femenino",
        medicalRecord: values.medicalRecord ? true : false,
        addressInfo: addressInfo,
        phoneNumbers: phoneNumbers,
      };

      newStudentResolver({
        variables: {
          studentInput: student,
          turnsId: turnos?.map((element) => element.id),
        },
      });
    }
  };

  if (loading) return <BlobLoader />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearAlumno"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item
          label="Nombre completo *"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <span className="horizontal">
          <Form.Item
            className="form-item-half-row"
            label="DNI *"
            name="dni"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className="form-item-half-row"
            label="Fecha de nacimiento *"
            name="birthDay"
            initialValue={dayjs()}
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            className="form-item-half-row"
            label="Teléfono *"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input
              type="tel"
              // pattern=""
            />
          </Form.Item>
          <Form.Item
            className="form-item-half-row"
            label="Teléfono alternativo"
            name="phoneNumber2"
            rules={[
              {
                pattern: /^\d+$/,
                message: "Ingresa solo números enteros",
              },
            ]}
          >
            <Input
              type="tel"
              // pattern=""
            />
          </Form.Item>
        </span>
        <Form.Item
          label="Email *"
          name="email"
          rules={[
            { required: true, message: "Campo requerido" },
            {
              type: "email",
              message: "Ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input
            type="email"
            // pattern=""
          />
        </Form.Item>
        <Form.Item initialValue="AR" label="Nacionalidad" name="nationality">
          <Select
            options={getCountries()}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="city" label="Ciudad" initialValue={MAIN_CITY_ID}>
          <Select
            options={getCities(config?.locations)}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="cp" label="Código postal">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="street" label="Domicilio">
          <Input type="text" />
        </Form.Item>
        <Form.Item label="Género" name="gender" initialValue="femenino">
          <Select
            options={[
              { value: "femenino", label: "Femenino" },
              { value: "masculino", label: "Masculino" },
              { value: "otro", label: "Otro" },
            ]}
          />
        </Form.Item>
        <Form.Item label="Ficha médica" name="medicalRecord">
          <label className="align-row">
            <input
              type="checkbox"
              id="coberturaCheck"
              style={{ accentColor: "var(--background-color)" }}
            />
            ¿Presentó?
          </label>
        </Form.Item>
        {cursos?.length > 0 && (
          <Form.Item label="Asignar cursos">
            <div className="columna" style={{ width: "100%", gap: "8px" }}>
              <Select
                options={cursos?.map((element) => {
                  return { value: element._id, label: element.name };
                })}
                value={selectedCourse}
                onChange={(v) => setSelectedCourse(v)}
                placeholder="Seleccione un curso"
                showSearch
                filterOption={(input, option) =>
                  (option.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
              {selectedCourse && (
                <span className="horizontal">
                  <Select
                    options={cursos
                      .find((element) => element._id === selectedCourse)
                      ?.turnsConformation?.map((element) => {
                        return {
                          value: element._id,
                          label: element.name,
                        };
                      })}
                    value={selectedTurn}
                    showSearch
                    filterOption={(input, option) =>
                      (option.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(v) => setSelectedTurn(v)}
                    placeholder="Seleccione un turno"
                  />
                  <Button
                    type="primary"
                    onClick={() =>
                      studentAddTurn(
                        setTurnos,
                        selectedCourse,
                        selectedTurn,
                        cursos,
                        messageApi
                      )
                    }
                  >
                    +
                  </Button>
                </span>
              )}
              {turnos?.length > 0 &&
                turnos.map((element, index) => {
                  return (
                    <Tag
                      color="cyan"
                      style={{ width: "fit-content" }}
                      key={`turn-${index}`}
                    >
                      <span className="horizontal">
                        <p>{element.name}</p>
                        <FiX
                          className="icono-acciones"
                          onClick={() =>
                            studentDeleteTurn(setTurnos, index, messageApi)
                          }
                        />
                      </span>
                    </Tag>
                  );
                })}
            </div>
          </Form.Item>
        )}
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
