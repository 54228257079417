import { Modal } from "antd";
import { TransferenciaCierreCaja } from "./TransferenciaCierreCaja";

export const CierreCajaModal = ({
  conceptos,
  showNewTransfer,
  setShowNewTransfer,
  selectedCierre,
  setSelectedCierre,
  // setTransferencias,
}) => {
  return (
    <Modal
      open={showNewTransfer.open}
      footer={false}
      title="Transferir y cerrar"
      onCancel={() =>
        setShowNewTransfer({ open: false, type: null, item: null })
      }
      className="custom-modal-wrapper"
      destroyOnClose
    >
      <TransferenciaCierreCaja
        conceptos={conceptos}
        showNewTransfer={showNewTransfer}
        setShowNewTransfer={setShowNewTransfer}
        // setTransferencias={setTransferencias}
        selectedCierre={selectedCierre}
        setSelectedCierre={setSelectedCierre}
      />
    </Modal>
  );
};
