import { useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useContext } from "react";
import { CREATE_INSTRUCTOR } from "../../../graphql/mutation/Instructores";
import { getCountries } from "../../../utils/getCountries";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import "./forms.css";
import { getCities } from "../../../utils/getCities";
import dayjs from "dayjs";
import { MAIN_CITY_ID } from "../../../utils/relevantIds";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const CreateInstructor = () => {
  const {
    setDrawerContent,
    instructores,
    setInstructores,
    messageApi,
    config,
  } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [newInstructorResolver, { data, loading, error }] = useMutation(
    CREATE_INSTRUCTOR,
    {
      onCompleted: (data) => {
        if (data?.newInstructorResolver) {
          if (data.newInstructorResolver.fullName) {
            //! AGREGAR CONDICION SIN STATUS
            let array = [...instructores];
            array.push(data.newInstructorResolver);

            setInstructores(array);

            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
            messageApi.success("Instructor creado correctamente");
          }
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al crear un instructor!");
      },
    }
  );

  const onFinish = (values) => {
    let instructor;

    if (values) {
      let addressInfo = {};
      if (values.street) {
        addressInfo.street = values.street;
      }
      if (values.cp) {
        addressInfo.cp = values.cp;
      }
      if (values.city) {
        addressInfo.city = values.city;
      }

      let phoneNumbers = [];
      if (values.phoneNumber) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber });
      }
      if (values.phoneNumber2) {
        phoneNumbers.push({ phoneNumber: values.phoneNumber2 });
      }

      instructor = {
        fullName: values.fullName ? values.fullName : "",
        dni: values.dni ? values.dni : "",
        birthDay: values.birthDay
          ? dayjs(values.birthDay).format("DD-MM-YYYY")
          : "",
        email: values.email ? values.email : "",
        nationality: values.nationality ? values.nationality : "AR",
        gender: values.gender ? values.gender : "femenino",
        addressInfo: addressInfo,
        phoneNumbers: phoneNumbers,
      };

      newInstructorResolver({ variables: { instructorInput: instructor } });
    }
  };

  if (loading) return <BlobLoader />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearInstructor"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
    >
      <div className="form-content">
        <Form.Item
          label="Nombre completo *"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="DNI *"
          name="dni"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Fecha de nacimiento *"
          name="birthDay"
          initialValue={dayjs()}
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>
        <Form.Item
          label="Teléfono *"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input
            type="tel"
            // pattern=""
          />
        </Form.Item>
        <Form.Item
          label="Teléfono alternativo"
          name="phoneNumber2"
          rules={[
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input
            type="tel"
            // pattern=""
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input
            type="email"
            // pattern=""
          />
        </Form.Item>
        <Form.Item initialValue="AR" label="Nacionalidad" name="nationality">
          <Select
            options={getCountries()}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="city" label="Ciudad" initialValue={MAIN_CITY_ID}>
          <Select
            options={getCities(config?.locations)}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="cp" label="Código postal">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="street" label="Domicilio">
          <Input type="text" />
        </Form.Item>
        <Form.Item label="Género" name="gender" initialValue="femenino">
          <Select
            options={[
              { value: "femenino", label: "Femenino" },
              { value: "masculino", label: "Masculino" },
              { value: "otro", label: "Otro" },
            ]}
          />
        </Form.Item>
      </div>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => {
              setDrawerContent({
                visible: false,
                content: "",
                item: {},
              });
            }}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
