import { gql } from "@apollo/client";

export const CREATE_COURSE = gql`
  mutation NewCourseResolver($courseInput: courseInput) {
    newCourseResolver(courseInput: $courseInput) {
      _id
      name
      description
      category
      createdAt
      status
      duration
      startDate
      finishDate
      cost {
        amountOfFees
        inscription
        total
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourseResolver($courseId: String, $courseInput: courseInput) {
    updateCourseResolver(courseId: $courseId, courseInput: $courseInput) {
      name
      description
      category
      duration
      cost {
        inscription
        amountOfFees 
        total
      }
    }
  }
`;

export const ASIGN_INSTRUCTOR = gql`
  mutation AsignInstructorToCourseResolver(
    $instructorId: String
    $turnId: String
  ) {
    asignInstructorToCourseResolver(
      instructorId: $instructorId
      turnId: $turnId
    ) {
      status
      message
    }
  }
`;

export const ASIGN_STUDENTS = gql`
  mutation AsignStudentsToCourseResolver(
    $turnId: String
    $studentsList: [String]
  ) {
    asignStudentsToCourseResolver(
      turnId: $turnId
      studentsList: $studentsList
    ) {
      status
      message
    }
  }
`;

export const CREATE_COURSE_TURN = gql`
  mutation manageCourseTurnsResolver(
    $turnInput: [turnInput]
    $deleteTurns: [String]
    $courseId: String
  ) {
    manageCourseTurnsResolver(
      turnInput: $turnInput
      deleteTurns: $deleteTurns
      courseId: $courseId
    ) {
      name
      description
      createdAt
      category
      _id
      duration
      cost {
        amountOfFees
        inscription
        total
      }
      turnsConformation {
        enrollmentCount
        _id
        name
        classroomId
        schedule {
          day
          startTime
          endTime
        }
      }
    }
  }
`;
