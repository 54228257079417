import { Button, Form, Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "../../../graphql/mutation/Usuarios";

export const UpdateUsuario = ({ edit = true }) => {
  const { profiles, messageApi, drawerContent, setDrawerContent, setUsers } =
    useContext(GlobalContext);
  const [form] = Form.useForm();

  const [updateUserResolver, { data, loading, error }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: (data) => {
        if (data?.updateUserResolver?._id) {
          messageApi.success("Usuario actualizado correctamente");

          setUsers((prevState) => {
            let array = [...prevState];

            let indice = array.findIndex(
              (element) => element._id === data.updateUserResolver._id
            );

            if (indice > -1) {
              array[indice] = data.updateUserResolver;
            }

            return array;
          });

          setDrawerContent({ visible: false, type: "", item: {} });
        } else {
          messageApi.error("Ocurrió un error al agregar un usuario");
        }
      },
      onError: (error) => {
        messageApi.error("Ocurrió un error al agregar un usuario");
      },
    }
  );

  const onFinish = (v) => {
    v.name = v.name?.trim() || "";
    v.username = v.username?.trim() || "";
    v.email = v.email?.trim() || "";

    if (v.name.length < 3)
      return messageApi.info("El nombre debe incluir al menos 3 caracteres");
    if (!v.profile) {
      return messageApi.info("Es necesario que seleccione un rol");
    }

    let obj = {
      name: v.name,
      permissionId: v.profile,
      email: v.email,
    };

    updateUserResolver({
      variables: { userId: drawerContent.item._id, userInput: obj },
    });
  };

  useEffect(() => {
    if (drawerContent.item?._id) {
      let data = drawerContent.item;

      form.setFieldsValue({
        name: data.name,
        email: data.email,
        profile: data.permissions._id,
        username: data.username,
      });
    }
  }, [drawerContent]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="createRol"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-layout"
      disabled={!edit}
    >
      <div className="form-content">
        <Form.Item
          name="name"
          label="Nombre"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input />
        </Form.Item>
        {!edit && (
          <Form.Item name="username" label="Nombre de usuario">
            <Input />
          </Form.Item>
        )}
        <Form.Item name="email" label="Mail">
          <Input />
        </Form.Item>
        <Form.Item
          name="profile"
          label="Rol"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Select
            options={profiles?.map((profile) => {
              return { value: profile._id, label: profile.name };
            })}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Seleccione un rol"
          />
        </Form.Item>
      </div>
      {edit && (
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() =>
                setDrawerContent({ visible: false, item: {}, type: "" })
              }
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      )}
    </Form>
  );
};
