import { Button, Table } from "antd";
import { notasColumns } from "./utils";

export const Notas = ({ turnConformation, data }) => {
  return (
    <div style={{ position: "relative" }}>
      <span className="horizontal fade-in" style={{ position: "absolute" }}>
        <Button style={{ marginLeft: "auto", top: -54, right: 0 }}>
          Cargar notas
        </Button>
      </span>
      <Table columns={notasColumns()} dataSource={[]} rowKey={"_id"} />
    </div>
  );
};
