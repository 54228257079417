import { useLazyQuery } from "@apollo/client";
import { GET_INSTRUCTORS } from "../../graphql/query/Instructores";
import { useEffect } from "react";

export const useInstructores = (
  setInstructores,
  messageApi,
  setLoadingGlobal,
  user
) => {
  const [getInstructorsResolver, { loading, error, data }] =
    useLazyQuery(GET_INSTRUCTORS, {fetchPolicy: "network-only"});

  useEffect(() => {
    if (user?._id
      //  && user?.type === "SYSTEM"
       ) {
      getInstructorsResolver();
    }
  }, [user]);

  useEffect(() => {
    setLoadingGlobal((prevState) => {
      let object = { ...prevState };

      object.instructores = loading;
      return object;
    });
  }, [loading]);

  useEffect(() => {
    if (error) {
      messageApi.error("Error al obtener instructores");
    }
  }, [error]);

  useEffect(() => {
    if (Array.isArray(data?.getInstructorsResolver)) {
      setInstructores(data.getInstructorsResolver);
    }
  }, [data]);

  return;
};
