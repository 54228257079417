import { BlobLoader } from "../../ui/loader/BlobLoader";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import "./configuracion.css";
import { Cajas } from "./opciones/Cajas";
import { Conceptos } from "./opciones/Conceptos";
import { Ciudades } from "./opciones/Ciudades";
import { Divisas } from "./opciones/Divisas";
import { MotivosBaja } from "./opciones/MotivosBaja";
import { Aulas } from "./opciones/Aulas";
import { FormasPago } from "./opciones/FormasPago";
import { Areas } from "./opciones/Areas";

export const Configuracion = () => {
  const { messageApi } = useContext(GlobalContext);

  const configTypes = [
    "Areas",
    "Aulas",
    // "Cajas",
    "Ciudades",
    "Conceptos",
    "Divisas",
    "Formas de pago",
    "Motivos de baja a cursos",
  ];

  const [type, setType] = useState(configTypes[0]);

  const loadingConfig = false;

  const handleType = (type) => {
    setType(type);
  };

  const isSelected = (text) => {
    if (text === type) {
      return "menu-option option-selected";
    } else {
      return "menu-option";
    }
  };

  const renderOption = () => {
    if (type) {
      switch (type) {
        case "Areas":
          return <Areas />;
        case "Aulas":
          return <Aulas />;
        // case "Cajas":
        //   return <Cajas />;
        case "Conceptos":
          return <Conceptos />;
        case "Ciudades":
          return <Ciudades />;
        case "Divisas":
          return <Divisas />;
        case "Formas de pago":
          return <FormasPago />;
        case "Motivos de baja a cursos":
          return <MotivosBaja />;
        default:
          return <span>No soportado</span>;
      }
    }
  };

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Configuración</p>
      </div>
      <div className="modulo-content">
        {loadingConfig ? (
          <BlobLoader />
        ) : (
          <div className="config-layout-wrapper">
            <div className="menu-config">
              {configTypes.map((type) => {
                return (
                  <span
                    className={isSelected(type)}
                    onClick={() => handleType(type)}
                    key={type}
                  >
                    {type}
                  </span>
                );
              })}
            </div>
            <div className="config-content">{renderOption()}</div>
          </div>
        )}
      </div>
    </div>
  );
};
