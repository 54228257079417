import CryptoJS from "crypto-js";

export const handlePermissionsSub = (
  data,
  user,
  setUser,
  messageApi,
  handleLogout
) => {
  if (data?.userPermissionsSubs) {
    const { logout, permission, user: userSub } = data.userPermissionsSubs;

    if (logout) {
      if (userSub?.userId === user._id) {
        handleLogout();
        return messageApi.info(
          "La contraseña ha sido modificada, contacte a un administrador"
        );
      }
    } else if (userSub?.userId) {
      if (userSub.userId === user._id) {
        const token = CryptoJS.AES.encrypt(
          JSON.stringify(userSub.data),
          process.env.REACT_APP_TOKEN_SECRET
        ).toString();

        localStorage.setItem("token", token);

        setUser(userSub.data);
        return messageApi.info(
          "Su usuario ha sido modificado"
        );
      }
    }

    if (permission?.permissionId) {
      if (permission.permissionId === user.rol._id) {
        setUser((prevState) => {
          let estado = {
            ...prevState,
            rol: {
              ...prevState.rol,
              permissions: permission.data.permissions,
            },
          };

          const token = CryptoJS.AES.encrypt(
            JSON.stringify(estado),
            process.env.REACT_APP_TOKEN_SECRET
          ).toString();

          localStorage.setItem("token", token);
          return estado;
        });

        return messageApi.info(
          "Sus permisos han sido modificados"
        );
      }
    }
  }
};
