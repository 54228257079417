import { useMutation } from "@apollo/client";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useContext, useEffect } from "react";
import { UPDATE_COURSE } from "../../../graphql/mutation/Cursos";
import { GlobalContext } from "../../context/GlobalContext";
import { BlobLoader } from "../loader/BlobLoader";
import dayjs from "dayjs";
import { makeFloat } from "../../../utils/thousandFormatter";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const UpdateCurso = ({ edit = true }) => {
  const {
    drawerContent,
    setDrawerContent,
    messageApi,
    cursos,
    setCursos,
    config,
  } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [updateCourseResolver, { data, loading, error }] =
    useMutation(UPDATE_COURSE);

  const onFinish = (values) => {
    let curso = {};

    if (
      !values.name ||
      !values.category ||
      !values.duration ||
      !values.startDate ||
      !values.finishDate ||
      !values.amountOfFees ||
      !values.inscription ||
      !values.total
    ) {
      return messageApi.info(
        "Todos los campos son obligatorios, ingrese un valor para cada uno"
      );
    }

    curso.name = values.name;
    curso.description = values.description;
    curso.category = values.category;
    curso.duration = values.duration;
    curso.startDate = dayjs(values.startDate).format("DD-MM-YYYY");
    curso.finishDate = dayjs(values.finishDate).format("DD-MM-YYYY");

    let cost = {
      amountOfFees: Number(values.amountOfFees),
      inscription: makeFloat(values?.inscription),
      total: makeFloat(values?.total),
    };
    curso.cost = cost;

    updateCourseResolver({
      variables: { courseInput: curso, courseId: drawerContent?.item?._id },
    })
      .then((res) => {
        let updatedCourse = res?.data?.updateCourseResolver;
        if (updatedCourse?.name) {
          messageApi.success("Curso actualizado correctamente");
          let array = structuredClone(cursos);

          let index = array.findIndex(
            (curso) => curso._id === drawerContent.item._id
          );

          if (index >= 0) {
            array[index] = { ...array[index], ...updatedCourse };

            setCursos(array);

            setDrawerContent({
              visible: false,
              type: "",
              item: {},
            });
          }
        } else {
          messageApi.error("Ocurrió un error al actualizar el curso");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al actualizar el curso");
      });
  };

  useEffect(() => {
    if (drawerContent?.item) {
      let data = drawerContent.item;

      form.setFieldsValue({
        name: data.name ? data.name : "",
        description: data.description ? data.description : "",
        category: data.category,
        duration: data.duration ? data.duration : "0",
        startDate: dayjs(data.startDate, "DD-MM-YYYY"),
        finishDate: dayjs(data.finishDate, "DD-MM-YYYY"),
        amountOfFees: data.cost?.amountOfFees,
        inscription: data.cost?.inscription ? data.cost.inscription : "",
        total: data.cost?.total,
      });
    }
  }, [drawerContent]);

  if (loading) return <BlobLoader />;

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearCurso"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      disabled={!edit}
      className="form-layout"
    >
      <div className={edit ? "form-content" : "form-content-details"}>
        <Form.Item
          label="Nombre *"
          name="name"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item
          name="duration"
          label="Duración *"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="category" label="Categoría">
          <Select
            options={[
              { value: "ESTETICA", label: "ESTETICA" },
              { value: "PELUQUERIA", label: "PELUQUERIA" },
            ]}
          />
        </Form.Item>
        <span className="horizontal">
          <Form.Item
            label="Inicio"
            name="startDate"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
          <Form.Item
            label="Fin"
            name="finishDate"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </span>
        <Form.Item
          name="inscription"
          label="Monto inscripción"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="total"
          label="Monto total"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amountOfFees"
          label="Cantidad de cuotas"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
            {
              pattern: /^\d+$/,
              message: "Ingresa solo números enteros",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        {/* <Form.Item
          name="feeValue"
          label="Monto por cuota"
          rules={[
            {
              pattern: /^[0-9]+(,[0-9]{1,2})?$/,
              message:
                'Ingresa un valor de dinero válido (Número entero o con ",")',
            },
          ]}
        >
          <Input />
        </Form.Item> */}
      </div>
      {edit && (
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() => {
                setDrawerContent({
                  visible: false,
                  content: "",
                  item: {},
                });
              }}
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      )}
    </Form>
  );
};
